<template>
    <template v-if="searchAllowed">
        <div class="detail-page-back mb-3 d-md-none">
            <SearchResultWidget />
        </div>

        <NdxPageHeader
            hide-filter
            v-model:view-mode="viewMode"
            view-mode-storage-key="searchViewMode"
            :nav-name="navName"
            :breadcrumbs="breadcrumbs"
        />

        <TagList
            v-if="localTags.length > 0"
            class="mb-3"
            :tags="localTags"
            removable
            :show-operator="localTags.length > 1"
            :operator="tagCombineLogic === 'AND'"
            @update:operator="updateTagCombineLogic"
            @remove-tag="removeTag"
        />

        <template v-if="noMatchesFound">
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noSearchHits')"
                :class="{'mt-3': localTags.length > 0}"
            />
        </template>
        <template v-else>
            <template v-if="showProductCategoryResults">
                <SearchResult
                    :view-mode="viewMode"
                    store-action="search/searchProductCategories"
                    :filter-criteria="filterCriteria"
                >
                    <NdxCategoryCard
                        v-for="category in productCategoryResult"
                        :key="category.id"
                        :card-img="category.image"
                        :title="category.name"
                        :description="category.description"
                        :variant="viewMode"
                        :background-color="category.backgroundColor"
                        :foreground-color="category.iconColor"
                        @card-click="() => gotoProductCategoryPage(category)"
                    />
                </SearchResult>
            </template>
            <template v-if="showProductResults">
                <SearchResult
                    :view-mode="viewMode"
                    store-action="search/searchProducts"
                    :filter-criteria="filterCriteria"
                >
                    <NdxProductCard
                        v-for="product in productResult"
                        :key="product.id"
                        :card-img="product.image"
                        :card-img-background-color="product.imageBgColor"
                        :title="product.name"
                        :tags="getTags(product.id)"
                        :description="product.listingDescription"
                        :price="product.minBasePrice"
                        :price-tag="product.priceTag"
                        :list-price="product.listPrice"
                        :currency="currency"
                        :quantity="product.displayQuantity || 1"
                        :quantityUnit="product.quantityUnit"
                        :label="product.label"
                        :actions="getCardActions(product)"
                        :variant="viewMode"
                        :bundleParent="product.bundleParent"
                        @card-click="() => gotoProductDetailPage(product)"
                    />
                </SearchResult>
            </template>
            <template v-if="showAssetLibraryResults">
                <SearchResult
                    :view-mode="viewMode"
                    store-action="search/searchAssetLibraries"
                >
                    <NdxCategoryCard
                        v-for="assetLibrary in assetLibraryResult"
                        :key="assetLibrary.id"
                        :title="assetLibrary.name"
                        :description="assetLibrary.description"
                        :background-color="assetLibrary.backgroundColor"
                        :foreground-color="assetLibrary.iconColor"
                        :card-img="assetLibrary.image"
                        :variant="viewMode"
                        @click="onAssetCategoryClick(assetLibrary)"
                    />
                    <NdxCategoryCard
                        v-for="assetGroup in assetGroupResult"
                        :key="assetGroup.id"
                        :title="assetGroup.name"
                        :description="assetGroup.description"
                        :background-color="assetGroup.backgroundColor"
                        :foreground-color="assetGroup.iconColor"
                        :card-img="assetGroup.image"
                        :variant="viewMode"
                        @click="onAssetCategoryClick(assetGroup)"
                    />
                </SearchResult>
            </template>
            <template v-if="showAssetEntryResults">
                <SearchResult
                    :view-mode="viewMode"
                    store-action="search/searchAssetEntries"
                    :filter-criteria="filterCriteria"
                >
                    <NdxAssetEntryCard
                        v-for="assetEntry in assetEntryResult"
                        :key="assetEntry.id"
                        :entry="assetEntry"
                        :variant="viewMode"
                        @click="onAssetEntryClick(assetEntry)"
                    />
                </SearchResult>
            </template>
        </template>
    </template>
    <template v-else>
        <NdxNotification
            variant="info"
            :duration="0"
            :modelValue="true"
            :message="$t('message.noSearchAllowed')"
        />
    </template>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxCategoryCard from "../library/NdxCategoryCard";
    import NdxProductCard from "../library/NdxProductCard";
    import NdxAssetEntryCard from "../library/NdxAssetEntryCard";
    import NdxNotification from "../library/NdxNotification";
    import TagsForProduct from "../../mixins/TagsForProduct";
    import TagList from "../library/TagList.vue";
    import SearchResult from "./SearchResult.vue";
    import SearchResultWidget from "../widgets/system/SearchResultWidget.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "SearchResults",
        components: {
            NdxPageHeader, SearchResultWidget, SearchResult, TagList, NdxNotification,
            NdxAssetEntryCard, NdxProductCard, NdxCategoryCard
        },
        mixins: [TagsForProduct],
        data() {
            return {
                viewMode: 'card'
            };
        },
        computed: {
            ...mapGetters('search', [
                'tagCombineLogic',
                'tags',
                'allowProductCategorySearch',
                'allowProductSearch',
                'allowAssetLibrarySearch',
                'allowAssetEntrySearch',
                'productCategoryResult',
                'productResult',
                'assetLibraryResult',
                'assetGroupResult',
                'assetEntryResult',
                'searchAllowed',
            ]),
            ...mapState({
                productCategorySearchRunning: state => state.search.productCategorySearchRunning,
                productCategoryCount: state => state.search.productCategoryCount,
                productCount: state => state.search.productCount,
                assetEntryCount: state => state.search.assetEntryCount,
                productSearchRunning: state => state.search.productSearchRunning,
                assetLibrarySearchRunning: state => state.search.assetLibrarySearchRunning,
                assetEntrySearchRunning: state => state.search.assetEntrySearchRunning,
                resultDisplayFilter: state => state.search.resultDisplayFilter,
                searchStr: state => state.search.searchStr,
                currentlySearching: state => state.search.currentlySearching,
                tagIds: state => state.search.tagIds,
                tagCombineLogic: state => state.search.tagCombineLogic,
                searchables: state => state.search.searchables,
            }),
            ...mapGetters('shop', [
                'currency'
            ]),
            localTags() {
                let list = [];
                for (let tagId in this.tags) {
                    list.push(this.tags[tagId]);
                }
                return list;
            },
            showProductCategoryResults() {
                return this.allowProductCategorySearch &&
                    ['productCategory', null].includes(this.resultDisplayFilter) &&
                    (this.productCategorySearchRunning || this.productCategoryResult.length ||
                        this.resultDisplayFilter === 'productCategory'
                    );
            },
            showProductResults() {
                if (this.allowProductSearch && this.tagIds.length) {
                    return true;
                }
                return this.allowProductSearch && ['product', null].includes(this.resultDisplayFilter) &&
                    (this.productSearchRunning || this.productResult.length || this.resultDisplayFilter === 'product');
            },
            showAssetLibraryResults() {
                return this.allowAssetLibrarySearch && ['assetLibrary', null].includes(this.resultDisplayFilter) &&
                    (this.assetLibrarySearchRunning || this.assetLibraryResult.length || this.assetGroupResult.length ||
                        this.resultDisplayFilter === 'assetLibrary'
                    );
            },
            showAssetEntryResults() {
                return this.allowAssetEntrySearch && ['assetEntry', null].includes(this.resultDisplayFilter) &&
                    (this.assetEntrySearchRunning || this.assetEntryResult.length ||
                        this.resultDisplayFilter === 'assetEntry'
                    );
            },
            noMatchesFound() {
                return !this.currentlySearching &&
                    !this.productCategorySearchRunning && this.productCategoryCount === 0 &&
                    !this.productSearchRunning && this.productCount === 0 &&
                    !this.assetLibrarySearchRunning && this.assetLibraryResult.length === 0 &&
                    this.assetGroupResult.length === 0 &&
                    !this.assetEntrySearchRunning && this.assetEntryCount === 0;
            },
            filterCriteria() {
                return {
                    searchText: this.searchStr || '',
                    tagIds: this.tagIds,
                    tagIdOperation: this.tagCombineLogic,
                };
            },
            navName () {
                if (this.showProductCategoryResults) {
                    return this.$t('label.productCategory');
                } else if (this.showProductResults) {
                    return this.$tc('label.products', 2);
                } else if (this.showAssetLibraryResults) {
                    return this.$t('label.assetLibrary');
                } else if (this.showAssetEntryResults) {
                    return this.$t('label.assetEntry');
                }
                return '';
            },
            breadcrumbs() {
                return [{
                    label: this.wgc.label,
                    click: () => this.$store.dispatch('search/setResultFilter', null)
                }, {
                    label: this.navName
                }];
            },
            wgc() {
                return this.$store.getters['shop/getWgcsByType']('search')[0];
            }
        },
        watch: {
            viewMode(mode) {
                if (mode === 'list') {
                    this.updateTags(this.productResult);
                }
            },
            productResult() {
                if (this.viewMode === 'list') {
                    this.updateTags(this.productResult);
                }
            }
        },
        beforeUnmount() {
            this.$store.dispatch('search/clearTags');
        },
        methods: {
            removeTag: function (tag) {
                this.$store.dispatch('search/removeTagId', tag.id);
            },
            gotoProductCategoryPage(category) {
                if (category.wgc) {
                    this.$router.push({
                        name: 'ProductsInCategory',
                        params: {
                            id: category.wgc.id,
                            categoryId: category.id,
                            pid: ''
                        }
                    });
                } else {
                    console.error('no wgc for productCategory ID: ' + category.id);
                }
            },
            gotoProductDetailPage(product) {
                this.$router.push({name: 'Product', params: {pid: product.id}});
            },
            onAssetCategoryClick(category) {
                if (category.wgc) {
                    this.$router.push({
                        name: 'AssetsInCategory',
                        params: {
                            id: category.wgc.id,
                            categoryId: category.id,
                            aid: ''
                        }
                    });
                } else {
                    console.error('no wgc for assetCategory ID: ' + category.id);
                }
            },
            onAssetEntryClick(assetEntry) {
                this.$router.push({
                    name: 'Asset',
                    params: {
                        aid: assetEntry.id
                    }
                });
            },
            updateTagCombineLogic(value) {
                this.$store.dispatch('search/setTagCombineLogic', value ? 'AND' : 'OR');
            },
            getCardActions(product) {
                return this.$store.getters['products/cardActions'](
                    product,
                    this.favorizeProduct,
                    this.addToBasket,
                    this.$t,
                    this.$d,
                    this.$router
                );
            },
            favorizeProduct(product, newFavoriteState) {
                this.$store.dispatch('products/toggleFavorite', {
                    pciId: product.id,
                    favorize: newFavoriteState
                }).then(() => this.$store.dispatch('search/localToggleFavorite', product.id));
            },
            addToBasket(product) {
                this.$store.dispatch('basket/addToBasket', {
                    productId: product.id,
                    quantity: 1
                }).then((result) => {
                    if (result.watchItems.find(item => item.id === result.orderItemId)) {
                        this.addToWatchlistSucceeded = true;
                    } else {
                        this.addToCartSucceeded = true;
                    }
                });
            }
        }
    };
</script>
