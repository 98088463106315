<template>
    <div
        v-if="itemCount > 0"
        class="bundleTree p-3"
        :class="{
            'pe-0': inTree
        }"
    >
        <BundleParent
            :in-tree="inTree"
            :type="type"
            :start-open="startOpen"
            :product="bundle.bundleProduct"
            :bundle-id="bundle.bundleId"
            :created-at="'createdAt' in bundle ? bundle.createdAt : null"
            :count="itemCount"
            :action-required="actionRequiredList.length > 0"
            @refresh-list="$emit('refresh-list')"
        >
            <template #children>
                <BundleChild
                    v-for="child in bundle.children"
                    :key="child.bundleId"
                    :type="type"
                    :child="child"
                    :order-items="orderItems"
                    @refresh-list="$emit('refresh-list')"
                    @open-project-run="(item) => $emit('open-project-run', item)"
                    @goto-detailpage="(item) => $emit('goto-detailpage', item)"
                    @action-required="(required) => actionRequiredHandler(child.bundleId, required)"
                />
            </template>
        </BundleParent>
    </div>
</template>

<script>
    import BundleParent from "./BundleParent.vue";
    import BundleChild from "./BundleChild.vue";

    export default {
        name: "BundleTree",
        components: {BundleChild, BundleParent},
        emits: ['refresh-list', 'action-required', 'open-project-run', 'goto-detailpage'],
        props: {
            bundle: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                validator: function (value) {
                    return ['watchlist', 'basket', 'configurator'].indexOf(value) !== -1;
                }
            },
            inTree: {
                type: Boolean
            },
            startOpen: {
                type: Boolean
            },
            orderItems: {
                type: Array,
                default: function () {
                    return [];
                }
            }
        },
        data: function () {
            return {
                actionRequiredList: []
            };
        },
        computed: {
            itemCount() {
                const isItemHidden = this.isItemHidden;
                function _count(children) {
                    let cnt = 0;
                    children.forEach((child) => {
                        if (child.children) {
                            cnt += _count(child.children);
                        } else if (!isItemHidden(child)) {
                            cnt++;
                        }
                    });
                    return cnt;
                }

                return this.bundle.children ? _count(this.bundle.children) : 0;
            }
        },
        methods: {
            isItemHidden(child) {
                if (!child.orderItemId) {
                    return false;
                }
                const orderItem = this.orderItems.find((item) => {
                    if ('id' in item) {
                        return item.id === child.orderItemId;
                    } else if ('orderItemId' in item) {
                        return item.orderItemId === child.orderItemId;
                    }
                    return false;
                });
                const quantity = orderItem?.quantity;
                const isBundleDetailPage = ['BundleItemDetail', 'ProductsInCategory', 'Product'].includes(
                    this.$route.name
                );

                return typeof quantity === 'number' && +quantity === 0 && !isBundleDetailPage;
            },
            actionRequiredHandler(bundleId, required) {
                if (required && !this.actionRequiredList.includes(bundleId)) {
                    this.actionRequiredList.push(bundleId);
                } else if (!required && this.actionRequiredList.includes(bundleId)) {
                    const idx = this.actionRequiredList.findIndex((id) => {
                        return id === bundleId;
                    });
                    if (idx > -1) {
                        this.actionRequiredList.splice(idx, 1);
                    }
                }
                this.$emit('action-required', this.actionRequiredList.length > 0);
            }
        }
    };
</script>
