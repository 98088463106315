/**
 * @param textToLimit String
 * @param wordLimit Number
 * @returns {String}
 */
const shortenTextByWord = function (textToLimit, wordLimit) {
    let finalText = '';
    let text2 = textToLimit.trim().replace(/\s+/g, ' ');
    let text3 = text2.split(' ');
    let numberOfWords = text3.length;
    let i = 0;
    if (numberOfWords > wordLimit) {
        for (i = 0; i < wordLimit; i++) {
            finalText = finalText + ' ' + text3[i];
        }
        return (finalText + ' …').trim();
    } else {
        return textToLimit;
    }
};

const textFromHtml = function (html) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

const stripHtmlTags = (original, allowedTags) => {
    if (typeof original === 'string') {
        return original.replace(/(<\/?([^>]+)>)/gi, function (match, part1, part2) {
            const tagEndPos = [
                part2.indexOf('/'),
                part2.indexOf(' ')
            ].reduce(
                (prevVal, currentVal) => currentVal === -1 ? prevVal : Math.min(prevVal, currentVal), part2.length
            );

            if (allowedTags && Array.isArray(allowedTags) &&
                allowedTags.includes(
                    part2.substr(
                        0,
                        tagEndPos
                    )
                )
            ) {
                return match;
            }

            return '';
        });
    }

    return original;
};

/**
 * Generates a string based on the given value with or without a random factor
 * @returns {string}
 */
const generateHash = function (obj, withRandomFactor = false) {
    let str;

    if (Array.isArray(obj)) {
        str = obj.map(
            entry => entry && !Array.isArray(entry) && typeof entry !== "object" ? entry : generateHash(entry)
        ).join('_');
    } else if (typeof obj === 'object' && obj) {
        str = Object.values(obj).map(
            entry => entry && !Array.isArray(entry) && typeof entry !== "object" ? entry : generateHash(entry)
        ).join('_');
    } else {
        // handling numbers, null and undefined
        str = '' + obj;
    }

    if (withRandomFactor) {
        str += Math.random();
    }

    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(16);
};

const humanNameFormatter = function (item) {
    let parts = [];
    if ('firstname' in item && item.firstname && item.firstname.length) {
        parts.push(item.firstname);
    }
    if ('lastname' in item && item.lastname && item.lastname.length) {
        parts.push(item.lastname);
    }
    return parts.join(' ');
};

export { shortenTextByWord, textFromHtml, stripHtmlTags, generateHash, humanNameFormatter };
