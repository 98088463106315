export function resolveFqcnForBe(type) {
    const map = {
        Asset: 'Ndx\\ContentModule\\AssetServerBundle\\Entity\\Asset',
        BitmapRenderConfig: 'Ndx\\LMAppModule\\LMAppModuleBundle\\Domain\\Output\\Render\\Configurations\\BitmapRenderConfig',
        BlockElement: 'Ndx\\LayoutModule\\LayoutBundle\\Envelope\\Layout\\Element\\BlockElement',
        Campaign: 'Ndx\\EngineBundle\\Entity\\Campaign',
        CheckoutEntry: 'Ndx\\ShopModule\\ShopBundle\\Entity\\CheckoutEntry',
        Configuration: 'Ndx\\Bundle\\CoreBundle\\Entity\\Configuration',
        ContentObject: 'Ndx\\ContentModule\\AssetServerBundle\\Entity\\ContentObject',
        ContentPool: 'Ndx\\ContentModule\\ContentPoolBundle\\Entity\\ContentPool',
        DocumentSet: 'Ndx\\LayoutModule\\EmailDocumentsBundle\\Entity\\DocumentSet',
        DocumentSetAggregate: 'Ndx\\LayoutModule\\EmailDocumentsBundle\\Entity\\DocumentSetAggregate',
        Email: 'Ndx\\LayoutModule\\EmailDocumentsBundle\\Entity\\EmailDocument',
        EmailDocument: 'Ndx\\LayoutModule\\EmailDocumentsBundle\\Entity\\EmailDocument',
        EmailTransferConfig: 'Ndx\\LMAppModule\\LMAppModuleBundle\\Domain\\Output\\Transfer\\Configurations\\EmailTransferConfig',
        ExternalGraphicElement: 'Ndx\\LayoutModule\\LayoutBundle\\Envelope\\Layout\\Element\\ExternalGraphicElement',
        HtmlFragment: 'Ndx\\ContentModule\\AssetServerBundle\\Entity\\HtmlFragment',
        Layout: 'Ndx\\LayoutModule\\LayoutBundle\\Entity\\Layout',
        NullRenderConfig: 'Ndx\\LMAppModule\\LMAppModuleBundle\\Domain\\Output\\Render\\Configurations\\NullRenderConfig',
        PageElement: 'Ndx\\LayoutModule\\LayoutBundle\\Envelope\\Layout\\Element\\PageElement',
        PageLayout: 'Ndx\\LayoutModule\\LayoutBundle\\Entity\\PageLayout',
        PdfRenderConfig: 'Ndx\\LMAppModule\\LMAppModuleBundle\\Domain\\Output\\Render\\Configurations\\PdfRenderConfig',
        Print: 'Ndx\\LayoutModule\\LayoutBundle\\Entity\\Layout',
        Print24TransferConfig: 'Ndx\\LMAppModule\\LMAppModuleBundle\\Domain\\Output\\Transfer\\Configurations\\Print24TransferConfig',
        ProductCatalogItem: 'Ndx\\ShopModule\\StockBundle\\Entity\\ProductCatalogItem',
        ProductCatalogItemFile: 'Ndx\\ShopModule\\StockBundle\\Entity\\ProductCatalogItemFile',
        ProductCatalogItemOption: 'Ndx\\ShopModule\\StockBundle\\Entity\\ProductCatalogItemOption',
        SftpTransferConfig: 'Ndx\\LMAppModule\\LMAppModuleBundle\\Domain\\Output\\Transfer\\Configurations\\SftpTransferConfig',
        Shop: 'Ndx\\ShopModule\\ShopBundle\\Entity\\Shop',
        ShopClient: 'Ndx\\ShopModule\\ShopBundle\\Entity\\ShopClient',
        ShopCountry: 'Ndx\\ShopModule\\ShopBundle\\Entity\\ShopCountry',
        Signee: 'Ndx\\ContentModule\\ContentBundle\\Entity\\Signee',
        SigneeElement: 'Ndx\\LayoutModule\\LayoutBundle\\Envelope\\Layout\\Element\\SigneeElement',
        SymAclProxy: 'Ndx\\PermissionModule\\PermissionBundle\\Entity\\SymAclProxy',
        SymAclFolderExtension: 'Ndx\\PermissionModule\\PermissionBundle\\Entity\\SymAclFolderExtension',
        Text: 'Ndx\\ContentModule\\ContentBundle\\Entity\\Text',
        UploadElement: 'Ndx\\ContentModule\\ContentBundle\\Entity\\UploadElement',
        User: 'Ndx\\EngineBundle\\Entity\\User',
        Vendor: 'Ndx\\ShopModule\\DeliveryBundle\\Entity\\Vendor',
        Webpage: 'Ndx\\LayoutModule\\WebpageDocumentsBundle\\Entity\\WebpageDocument',
        WebpageDocument: 'Ndx\\LayoutModule\\WebpageDocumentsBundle\\Entity\\WebpageDocument'
    };

    for (let key in map) {
        if (key === type) {
            return map[key];
        }
        if (key.toLowerCase() === type.toLowerCase()) {
            console.warn(
                'FQCN key "' + key + '" was mapped to type "' + type + '". Please spell type properly.'
            );
            return map[key];
        }
    }

    throw new Error('Cannot find a FQCN for the given type: ' + type);
}
