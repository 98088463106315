<template>
    <div
        class="svg_wrapper d-flex justify-content-center"
    >
        <div
            v-html="svgCode"
            class="h-100 w-100 d-flex align-items-center justify-content-center"
        ></div>
    </div>
</template>

<script>
    import { ndxGet } from "../../utils/ndxRpc";

    export default {
        name: "NdxSvg",
        props: {
            /**
             * The image src.
             */
            src: {
                type: String,
                required: false,
                default: ''
            },
            svgStyle: {
                type: String
            }
        },
        data() {
            return {
                svgCode: ''
            };
        },
        computed: {
            isSvg() {
                return !!this.src?.endsWith('.svg');
            },
            imgPath() {
                return '/file/download/' + this.src;
            }
        },
        created() {
            if (this.isSvg) {
                ndxGet(this.imgPath, true)
                    .then(fileContent => {
                        this.svgCode = fileContent.replace("<svg ", '<svg ' + (this.svgStyle || '') + ' ');
                    });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/custom_vars";

    .svg_wrapper {
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: var(--bs-gray-light);
    }
</style>
