<template>
    <div class="productFeature-wrapper">
        <template
            v-for="feature in productFeatures"
            :key="feature.id"
        >
            <NdxInput
                v-if="feature.type === 'input'"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="feature.value"
                @update:modelValue="(newVal) => updateValue(feature, newVal)"
            />
            <NdxTextarea
                v-else-if="feature.type === 'text'"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="feature.value"
                @update:modelValue="(newVal) => updateValue(feature, newVal)"
            />
            <NdxSelect
                v-else-if="feature.type === 'select'"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :options="feature.availableValues.map((item) => {
                    return {value: item.key, text: item.value}
                })"
                :text-wrap="true"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="feature.value"
                @update:modelValue="(newVal) => updateValue(feature, newVal)"
            />
            <NdxMultiSelect
                v-else-if="feature.type === 'selectMulti'"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :options="feature.availableValues.map((item) => {
                    return {value: item.key, text: item.value}
                })"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="feature.value"
                @update:modelValue="(newVal) => updateValue(feature, newVal)"
            />
            <NdxCheckbox
                v-else-if="feature.type === 'checkbox'"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="['1', 1].indexOf(feature.value) > -1"
                @update:modelValue="(newVal) => updateValue(feature, newVal ? 1 : 0)"
            />
            <NdxDatePicker
                v-else-if="feature.type === 'date'"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :clear-button="feature.mandatory === false"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="feature.value"
                @update:modelValue="(newVal) => updateValue(feature, newVal)"
            />
            <NdxFileUpload
                v-else-if="feature.type === 'file'"
                variant="minimal"
                :label="feature.name"
                :description="feature.description"
                :readonly="feature.readonly"
                :invalid="invalidFeatures.indexOf(feature.key) > -1"
                :model-value="feature.value"
                @update:modelValue="(newVal) => updateValue(feature, newVal)"
            />

            <div v-else class="ndx-form-element">
                <div class="label">{{ feature.name }}</div>
                todo: {{ feature.type }}
            </div>
        </template>
    </div>
</template>

<script>
    import NdxInput from "../../library/formElements/NdxInput";
    import NdxSelect from "../../library/formElements/NdxSelect";
    import NdxMultiSelect from "../../library/formElements/NdxMultiSelect";
    import NdxDatePicker from "../../library/formElements/NdxDatePicker";
    import NdxFileUpload from "../../library/formElements/NdxFileUpload";
    import NdxTextarea from "../../library/formElements/NdxTextarea";
    import NdxCheckbox from "../../library/formElements/NdxCheckbox";

    export default {
        name: 'ProductFeatures',
        components: {NdxCheckbox, NdxTextarea, NdxFileUpload, NdxDatePicker, NdxMultiSelect, NdxSelect, NdxInput},
        props: {
            productFeatures: {
                type: Array
            }
        },
        emits: ['update-feature', 'is-valid'],
        data() {
            return {
                invalidFeatures: []
            };
        },
        watch: {
            productFeatures: {
                deep: true,
                handler: 'validateFeatures',
                immediate: true
            }
        },
        methods: {
            updateValue(feature, value) {
                if (feature.type === 'file') {
                    if (value === null && feature.value && 'fileId' in feature.value) {
                        value = {
                            deleteFile: true
                        };
                    }
                }
                this.$emit('update-feature', {
                    feature: feature,
                    value: value
                });
            },
            validateFeatures() {
                this.invalidFeatures = this.$store.getters['products/getInvalidProductFeatures'](this.productFeatures);

                this.$emit('is-valid', this.invalidFeatures.length === 0);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_bootstrap";
    @import "~bootstrap/scss/mixins/breakpoints";

    .productFeature-wrapper {
        .ndx-form-element {
            margin-top: 16px;
        }
    }

    @include media-breakpoint-up(xl) {
        .productFeature-wrapper {
            .ndx-form-element {
                margin-top: 0;
            }

            .ndx-form-element + .ndx-form-element {
                margin-top: 16px;
            }
        }
    }
</style>
