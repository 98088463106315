<script>
    export default {
        name: 'DataItemMixin',
        data() {
            return {
                genderOptions: [
                    {value: 'male', text: this.$t('label.gender_male')},
                    {value: 'female', text: this.$t('label.gender_female')},
                    {value: 'diverse', text: this.$t('label.gender_diverse')},
                    {value: 'no_gender', text: this.$t('label.gender_no_gender')},
                ]
            };
        },
        computed: {
            value() {
                if (['user', 'client'].indexOf(this.type) > -1) {
                    return this.getValue(this.item.uiElement);
                } else if (this.isAttribute) {
                    return this.getAttrValue();
                }
                return '';
            },
            valueType() {
                return this.item.attributeDef?.valueFormatType ?? this.item.type;
            },
            isAttribute() {
                return ['userAttribute', 'clientAttribute', 'checkoutAttribute'].includes(this.type);
            }
        },
        methods: {
            resolve(path, obj) {
                return path.split('.').reduce(function (prev, curr) {
                    return prev ? prev[curr] : null;
                }, obj);
            },
            getPath(uiElement) {
                const map = {
                    'username': 'username',
                    'avatar': 'avatar',
                    'orgId': 'orgId',
                    'name': 'name',
                    'description': 'description',
                    'website': 'website',
                    'locale': 'preferredLocale',

                    'email': 'contact.emailAddress',
                    'phone': 'contact.phone',
                    'phoneBusiness': 'contact.phoneBusiness',
                    'mobile': 'contact.mobile',
                    'fax': 'contact.fax',
                    'faxBusiness': 'contact.faxBusiness',

                    'gender': 'gender',
                    'firstname': 'firstname',
                    'lastname': 'lastname',
                    'position': 'position',
                    'academicTitle': 'academicTitle'
                };

                return uiElement in map ? map[uiElement] : '';
            },
            getValue(uiElement) {
                return this.resolve(this.getPath(uiElement), this.modelValue);
            },
            getAttrValue() {
                if(this.valueType === 'image') {
                    if ('image' in this.item) {
                        return this.item.image;
                    }
                    return null;
                }
                if (this.valueType === 'selectMulti') {
                    const list = this.item.value || this.item.values?.value || null;
                    if (list !== null && list.length) {
                        if (Array.isArray(list)) {
                            return list;
                        }
                        return JSON.parse(list);
                    }
                    if (![null, undefined].includes(this.item.attributeDef?.defaultValue) &&
                        this.item.attributeDef?.defaultValue.length
                    ) {
                        if (Array.isArray(this.item.attributeDef?.defaultValue)) {
                            return this.item.attributeDef.defaultValue;
                        }
                        return JSON.parse(this.item.attributeDef.defaultValue);
                    }
                    return [];
                }

                if (this.valueType === 'checkbox') {
                    if (this.item.value !== null) {
                        return ['1', 1].indexOf(this.item.value) > -1;
                    }
                    return ['1', 1].indexOf(this.item.attributeDef.defaultValue) > -1;
                }

                if (this.valueType === 'file') {
                    if (this.item.value !== null && this.item.value !== '') {
                        return typeof this.item.value === 'string' ?
                            JSON.parse(this.item.value) :
                            this.item.value;
                    }

                    if (this.item.attributeDef &&
                        this.item.attributeDef.defaultValue !== null && this.item.attributeDef.defaultValue !== ''
                    ) {
                        return typeof this.item.attributeDef.defaultValue === 'string' ?
                            JSON.parse(this.item.attributeDef.defaultValue) :
                            this.item.attributeDef.defaultValue;
                    }
                    return null;
                }

                if (this.item.value !== null) {
                    return this.item.value;
                }
                return this.item.attributeDef.defaultValue;
            },
            valueTextChoose(value) {
                if (this.item.uiElement === 'gender') {
                    for (let chooseItem of this.genderOptions) {
                        if (chooseItem.value === value) {
                            return chooseItem.text;
                        }
                    }
                }
                return value;
            },
            keyToUserText(key) {
                if (this.item.type === 'select' && this.item.valuesTranslated) {
                    return this.item.valuesTranslated;
                }

                if (this.item.type === 'selectMulti' && this.item.valuesTranslated.value) {
                    return this.item.valuesTranslated.value[this.item.values.value.indexOf(key)];
                }

                if (this.item.attributeDef.displayOptions && Array.isArray(this.item.attributeDef.displayOptions)) {
                    const idx = this.item.attributeDef.displayOptions.findIndex(item => item.key == key);
                    if (idx > -1) {
                        return this.item.attributeDef.displayOptions[idx].value;
                    }
                }

                /*
                console.warn('Fallback used!');
                console.warn('key: ' + key);
                console.warn('item:');
                console.warn(this.item);
                 */

                return key;
            }
        }
    };
</script>
