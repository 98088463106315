<script>
    /**
     * Plugin vue-gtag
     * @see https://matteo-gabriele.gitbook.io/vue-gtag/
     *
     * GooleAnalytics pageView
     * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages?hl=de
     */

    import { bootstrap, setOptions, pageview } from "vue-gtag";
    import BaseTracker from "./BaseTracker.vue";

    export default {
        name: 'GoogleAnalytics',
        mixins: [BaseTracker],
        methods: {
            initialize() {
                setOptions({
                    onError: (error) => {
                        console.error('onError');
                        console.error(error);
                    },
                    onReady: () => {
                        // track the current route,
                        // otherwise a pageRefresh (F5) won't trigger the current page
                        this.trackRoute();
                    },
                    config: {
                        id: this.appTracking.googleTag
                    }
                });
                bootstrap().then(() => {
                    // all done!
                });
            },
            trackRoute() {
                this.getRouteData()
                    .then((routeData) => {
                        //console.log(routeData);
                        pageview({
                            page_title: routeData.title,
                            page_path: routeData.path
                        });
                    });
            }
        }
    };
</script>
