<script>
    import useVuelidate from '@vuelidate/core';

    function hasValidationRules(fieldPath, vuelidateInst) {
        return !!getValidationConfig(fieldPath, vuelidateInst);
    }

    function getValidationConfig(fieldPath, vuelidateInst) {
        let config = vuelidateInst;
        let fieldPathParts = fieldPath.split('.');

        fieldPathParts.forEach(function (pathPart) {
            if (config && typeof config === 'object' && pathPart in config) {
                config = config[pathPart];
            } else {
                config = null;
                return false;
            }
        });

        return config;
    }

    function listRules(fieldPath, vuelidateInst) {
        return Object.keys(getValidationConfig(fieldPath, vuelidateInst)).filter(
            rule => rule.substr(0, 1) !== '$'
        );
    }

    /**
     * adapt this method if you need more information in message generation
     */
    function createErrorMsg(fieldPath, fieldValue, ruleName, ruleConfig, ctx, msgParamOverwrite = {}) {
        // const currentValue = ruleConfig.$model;
        const fieldName = fieldPath.split('.').pop();
        const secondFieldName = ruleConfig.eq ||
            ruleConfig.$sub && ruleConfig.$sub[0].eq ||
            ruleConfig.otherName ||
            null;
        let msgKey = ruleName;
        let msgParams = {
            field: fieldName,
            secondField: secondFieldName,
            minLength: ruleConfig.min,
            maxLength: ruleConfig.max,
            minValue: ruleConfig.min,
            maxValue: ruleConfig.max
        };

        if (ruleName === 'xml') {
            const xmlDoc = fieldValue.length ?
                (new DOMParser()).parseFromString(fieldValue, "text/xml") :
                '';

            return (new XMLSerializer()).serializeToString(xmlDoc.documentElement);
        } else {
            if (ruleName === 'measurement') {
                msgParams.validList = ruleConfig.validUnits.join(', ');
                if (!isNaN(ruleConfig.min)) {
                    msgKey += 'Min';
                }
                if (!isNaN(ruleConfig.max)) {
                    msgKey += 'Max';
                }
            }

            msgParams = Object.assign(msgParams, msgParamOverwrite);
            msgParams.field = ctx.$t('label.' + msgParams.field);
            msgParams.secondField = msgParams.secondField ? ctx.$t('label.' + msgParams.secondField) : '';

            return ctx.$t('form.' + msgKey, msgParams);
        }
    }

    function negatedRuleName(ruleName) {
        return 'not' + ruleName[0].toUpperCase() + ruleName.slice(1);
    }

    /**
     * Extend this list if a validation rule is not respected in the error message generation
     *
     * @type {string[]}
     */
    const knownValidationRulesPrioritized = [
        'required',
        'requiredIf',
        'requiredUnless',
        'sameAs',
        'alpha',
        'alphanum',
        'numeric',
        'integer',
        'decimal',
        'isFloatIntl',
        'isPhone',
        'email',
        'url',
        'ipAddress',
        'macAddress',
        'hasAlpha',
        'hasUppercase',
        'hasLowercase',
        'hasNumeric',
        'hasSpecialChar',
        'hasNoWhiteSpace',
        'minLength',
        'maxLength',
        'minValue',
        'maxValue',
        'between',
        'isInList'
    ];

    export default {
        name: "Validation",
        created() {
            this.v$ = useVuelidate();
        },
        mounted() {
            this.vuelidateData();
        },
        methods: {
            vuelidateData() {
                let instance = this.getVuelidateInstance();
                if (instance) {
                    instance.$touch();
                }
            },
            vuelidateDataError(prop) {
                let instance = this.getVuelidateInstance();
                if (instance) {
                    if (prop && prop in instance) {
                        return instance[prop].$error;
                    }
                    return instance.$error;
                }
                return false;
            },
            getVuelidateData(prop) {
                let instance = this.getVuelidateInstance();
                if (instance && prop && prop in instance) {
                    return instance[prop];
                }
                return null;
            },
            /**
             * @param {string} fieldPath dot seperated accessor
             *
             * @returns {string} empty string if no error is detected
             */
            getValidationErrorMsg: function (fieldPath) {
                const vuelidationInst = this.getVuelidateInstance();
                let errorMsg = '';

                if (vuelidationInst && hasValidationRules(fieldPath, vuelidationInst)) {
                    const fieldValidationConfig = getValidationConfig(fieldPath, vuelidationInst);

                    if (fieldValidationConfig.$error) {
                        // there is at least on validation error for asked field
                        const rules = listRules(fieldPath, vuelidationInst);
                        for (let ruleName of knownValidationRulesPrioritized) {
                            if (rules.includes(ruleName) && fieldValidationConfig[ruleName].$invalid) {
                                // rule is not full-filled
                                errorMsg = createErrorMsg(
                                    fieldPath,
                                    fieldValidationConfig.$model,
                                    ruleName,
                                    fieldValidationConfig[ruleName].$params,
                                    this,
                                    fieldValidationConfig.msgParams?.$params
                                );
                                break;
                            }
                            let notRuleName = negatedRuleName(ruleName);
                            if (rules.includes(notRuleName) && fieldValidationConfig[notRuleName].$invalid) {
                                // rule is not full-filled
                                errorMsg = createErrorMsg(
                                    fieldPath,
                                    fieldValidationConfig.$model,
                                    notRuleName,
                                    fieldValidationConfig[notRuleName].$params,
                                    this,
                                    fieldValidationConfig.msgParams?.$params
                                );
                                break;
                            }
                        }
                    }
                }

                return errorMsg;
            },
            /**
             * is the object parameter is valid or not
             *
             * @param fieldPath
             *
             * @returns {boolean}
             */
            hasError: function (fieldPath) {
                const vuelidationInst = this.getVuelidateInstance();
                if (vuelidationInst && hasValidationRules(fieldPath, vuelidationInst)) {
                    const fieldValidationConfig = getValidationConfig(fieldPath, vuelidationInst);

                    return fieldValidationConfig.$error;
                }

                return false;
            },

            getVuelidateInstance() {
                if (this.v$ && this.v$.value) {
                    return this.v$.value;
                } else if (this.v$) {
                    return this.v$;
                }
                return null;
            }
        }
    };
</script>
