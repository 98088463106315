<template>
    <NdxPageHeader
        :breadcrumbs="breadcrumbs"
        :active-route-callback="goToInstances"
        :nav-name="navName"
        :active-filter="activeFilter"
        v-model:show-filter="showFilter"
        hide-view-mode
    />

    <NdxListFilter
        v-if="filterData !== null"
        v-model:show-filter="showFilter"
        :selected-time-range="filterData.createdAfter"
        :time-range-options="createdAfterOptions"
        :search-text="filterData.searchText"
        @filter-triggered="updateFilterData"
        @clear-filter="clearFilter"
    />

    <WorkflowInstancesActive
        v-if="type === 'active'"
        :filter-data="filterData"
    />
    <WorkflowInstancesCompleted
        v-else
        :filter-data="filterData"
    />
</template>

<script>
    import WorkflowInstancesActive from "./WorkflowInstancesActive.vue";
    import WorkflowInstancesCompleted from "./WorkflowInstancesCompleted.vue";
    import NdxListFilter from "../library/NdxListFilter.vue";
    import { ndxDateFormatYmd, ndxDateSub } from "@utilities/ndxDate";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    const LISTTYPE_ACTIVE = 'active';
    const LISTTYPE_COMPLETED = 'completed';

    export default {
        name: 'WorkflowInstances',
        components: {
            NdxPageHeader, NdxListFilter, WorkflowInstancesCompleted, WorkflowInstancesActive
        },
        data() {
            return {
                type: LISTTYPE_ACTIVE,
                filterData: null,
                showFilter: false
            };
        },
        computed: {
            createdAfterOptions() {
                if (this.type !== LISTTYPE_COMPLETED) {
                    return undefined; // disable time range filtering
                }
                return [
                    {value: '50Y', text: this.$t('label.list_all')},
                    {value: '7D', text: this.$t('label.list_7days')},
                    {value: '1M', text: this.$t('label.list_1month')},
                    {value: '4M', text: this.$t('label.list_4month')},
                    {value: '6M', text: this.$t('label.list_6month')},
                    {value: '1Y', text: this.$t('label.list_1year')},
                    {value: '2Y', text: this.$t('label.list_2year')},
                    {value: '3Y', text: this.$t('label.list_3year')},
                ];
            },
            navName() {
                return this.type === LISTTYPE_ACTIVE
                    ? this.$t('label.workflow.workflowInstancesActive')
                    : this.$t('label.workflow.workflowInstancesCompleted');
            },
            breadcrumbs() {
                return [{
                    label: this.navName,
                    click: this.activeFilter ? () => this.clearFilter() : null
                }];
            },
            activeFilter() {
                return this.filterData.searchText.length > 0 || this.filterData.createdAfter !== '1M';
            }
        },
        watch: {
            '$route.params': {
                immediate: true,
                handler: function (params) {
                    const routeParam = params.taskType;
                    const isValidRoutParam = [LISTTYPE_ACTIVE, LISTTYPE_COMPLETED].includes(routeParam);
                    this.type = isValidRoutParam ? routeParam : LISTTYPE_ACTIVE;
                    this.clearFilter();
                }
            }
        },
        created() {
            this.clearFilter();
        },
        methods: {
            goToInstances() {
                this.$router.push(
                    this.$store.getters['shop/getWgcsByType']('workflowsInstances')[0].route
                );
            },
            clearFilter() {
                this.showFilter = false;
                this.filterData = {
                    searchText: '',
                    createdAfter: '1M',
                    createdAfterCamundaFormat: this.createdAfterCamundaFormat('1M')
                };
            },
            updateFilterData(filterValues) {
                this.filterData = {
                    searchText: filterValues.searchText,
                    createdAfter: filterValues.timeRange,
                    createdAfterCamundaFormat: this.createdAfterCamundaFormat(filterValues.timeRange)
                };
            },
            createdAfterCamundaFormat(createdAfter) {
                const now = new Date();
                return ndxDateFormatYmd(ndxDateSub(createdAfter, now), '-', true);
            }
        }
    };
</script>
