<template>
    <NdxFlyIn>
        <template #default>
            <div class="entry" @click="(evt) => toggleDetail(evt)">
                <div class="entry-top is-action">
                    <div class="listKey">{{ $t('label.deliveryAddress') }}</div>
                    <div
                        v-if="orderInfos.deliveryAddress"
                        class="listValue"
                        v-html="stripHtmlTags(formatAddress(orderInfos.deliveryAddress), ['br'])"
                    ></div>
                    <NdxIcon icon="drop-right"/>
                </div>
                <div class="detail">
                    <AddressSelection
                        address-type="delivery"
                        :model-value="orderInfos.deliveryAddress?.id"
                        :formatter="formatAddress"
                        @update:model-value="(addressId) => setAddress('delivery', addressId)"
                    />
                </div>
            </div>
            <div class="entry" @click="(evt) => toggleDetail(evt)">
                <div class="entry-top is-action">
                    <div class="listKey">{{ $t('label.invoiceAddress') }}</div>
                    <template
                        v-if="!orderInfos.invoiceAddress ||
                            (orderInfos.invoiceAddress?.id === orderInfos.deliveryAddress?.id)"
                    >
                        <div class="listValue">{{ $t('message.sameAsDeliveryAddress') }}</div>
                    </template>
                    <template v-else>
                        <div
                            class="listValue"
                            v-html="stripHtmlTags(formatAddress(orderInfos.invoiceAddress), ['br'])"
                        ></div>
                    </template>
                    <NdxIcon icon="drop-right"/>
                </div>
                <div class="detail">
                    <AddressSelection
                        address-type="invoice"
                        :model-value="orderInfos.invoiceAddress?.id"
                        :formatter="formatAddress"
                        @update:model-value="(addressId) => setAddress('invoice', addressId)"
                    />
                </div>
            </div>
            <div class="entry">
                <div class="entry-top">
                    <div class="listKey">{{ $t('label.paymentMethod') }}</div>
                    <div class="listValue">{{ $t('label.paymentOffline') }}</div>
                    <div class="placeholder"></div>
                </div>
            </div>
            <div class="entry" @click="(evt) => toggleDetail(evt)">
                <div class="entry-top is-action">
                    <div class="listKey">{{ $t('label.sum') }}</div>
                    <div class="listValue highlight">{{ formatPrice(relevantSum) }}</div>
                    <NdxIcon icon="drop-right"/>
                </div>
                <div class="detail">
                    <div class="p-2 roundedBorder">
                        <SummaryWidget
                            :store-name="storeName"
                        />
                    </div>
                </div>
            </div>
            <NdxNotification
                v-if="!orderable"
                variant="error"
                class="m-3"
                :model-value="true"
                :duration="0"
                :message="$t('message.basketItem_not_orderable')"
            />
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="$emit('cancel')"
            >
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton
                class="btnFlex"
                @click="$emit('order-basket')"
                :disabled="!orderable || !this.showOrderReliablyBtn || !hasDeliveryAddress || isBudgetExceeded"
            >
                <div class="d-flex justify-content-between">
                    {{ buttonLabel }}
                    <NdxIcon icon="arrow-left" flip-h/>
                </div>
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxIcon from "../library/NdxIcon";
    import { stripHtmlTags } from '@utilities/ndxText';
    import { AddressFormatter, PriceFormatter } from "../../plugins/formatter";
    import { mapGetters, mapState } from "vuex";
    import NdxNotification from "../library/NdxNotification";
    import SummaryWidget from "../checkout/steps/parts/SummaryWidget";
    import AddressSelection from "../checkout/steps/parts/AddressSelection.vue";

    export default {
        name: 'QuickCheckoutFlyIn',
        components: {
            AddressSelection,
            SummaryWidget, NdxNotification, NdxIcon, NdxButtonLink, NdxButton, NdxFlyIn},
        props: {
            orderInfos: {
                type: Object
            },
            btnLabel: {
                type: String
            },
            storeName: {
                type: String,
                default: 'basket'
            }
        },
        emits: ['cancel', 'set-address', 'order-basket'],
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            ...mapGetters('basket', [
                'getBasketTextQuickCheckout'
            ]),
            ...mapState({
                showGross: state => state.shop.basket.showGrossPrice,
                isNetClient: state => state.user?.shopClient?.isNet,
                showOrderReliablyBtn: state => state.shop.productDetails.showOrderReliablyBtn
            }),
            budgets() {
                return this.basket && this.basket.summary
                    ? this.basket.summary.budgets
                    : [];
            },
            relevantSum() {
                return this.$store.getters[this.storeName + '/getRelevantSum'](this.showGross, this.isNetClient);
            },
            basket() {
                return this.$store.getters[this.storeName + '/getBasket'];
            },
            orderable() {
                return this.$store.getters[this.storeName + '/isOrderable'];
            },
            isBudgetExceeded() {
                return this.$store.getters[this.storeName + '/isBudgetLimitExceeded'];
            },
            hasDeliveryAddress() {
                return this.orderInfos.deliveryAddress?.id;
            },
            buttonLabel() {
                if (this.btnLabel && this.btnLabel.length) {
                    return this.btnLabel;
                }
                return this.getBasketTextQuickCheckout
                    ? this.getBasketTextQuickCheckout
                    : this.$t('btn.quickCheckout');
            }
        },
        methods: {
            stripHtmlTags,
            formatAddress(addressArr) {
                return stripHtmlTags(
                    AddressFormatter(addressArr, this, '<br />'),
                    ['br']
                );
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            toggleDetail(evt) {
                const toggleElem = evt.currentTarget;
                toggleElem.classList.toggle('showDetail');
            },
            setAddress(addressType, addressId) {
                this.$emit('set-address', {
                    type: addressType,
                    id: addressId
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .entry {
        padding: 16px;
        border-bottom: 1px solid var(--bs-gray-middle);

        svg {
            flex-grow: 0;
        }

        .entry-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            &.is-action {
                cursor: pointer;
            }
        }

        .detail {
            display: none;
            padding-top: 16px;

            /* to force wrapping to new line */
            width: 100%;
        }

        &.showDetail {
            svg {
                transform: rotate(90deg);
            }

            .detail {
                display: block;
            }
        }

        .listKey {
            @extend %font-caption;

            align-self: start;
            color: var(--bs-disabled);
        }

        .listValue {
            @extend %font-h4;

            color: var(--bs-black);

            text-align: end;
            flex-grow: 1;
        }

        .highlight {
            color: var(--bs-danger) !important;
        }
    }
</style>
