<template>
    <div class="ndxFormWrapper" v-if="initiated">
        <NdxSelect
            v-if="isNew && addressBookOptions.length > 0"
            :label="$t('label.addressBook')"
            :options="addressBookOptions"
            :model-value="addressBook"
            :readonly="addressBookOptionsReadonly || addressBookOptions.length < 2"
            @update:model-value="onAddressBookChange"
        />
        <NdxSelect
            v-if="isNew && addressTypeOptions.length > 0"
            :label="$t('label.addressType')"
            :options="addressTypeOptions"
            :model-value="addressType"
            :readonly="addressTypeOptionsReadonly || addressTypeOptions.length < 2"
            @update:model-value="onAddressTypeChange"
        />
        <template
            v-for="addressItem in addressItems"
            :key="addressItem.key"
        >
            <NdxInput
                v-if="addressItem.type === 'input' && _hasItem(addressItem.key)"
                :label="getLabel(addressItem.key)"
                :readonly="isReadonly(addressItem.key)"
                v-model="addressData[addressItem.key]"
                :invalid="invalidItems.includes(addressItem.key)"
                @update:model-value="validate"
            />
            <NdxRadio
                v-else-if="addressItem.type === 'radio' && _hasItem(addressItem.key)"
                :label="getLabel(addressItem.key)"
                :readonly="isReadonly(addressItem.key)"
                v-model="addressData[addressItem.key]"
                :options="getRadioOptions(addressItem.key)"
                :invalid="invalidItems.includes(addressItem.key)"
                @update:model-value="validate"
            />
            <NdxSelect
                v-else-if="addressItem.type === 'select' && _hasItem(addressItem.key)"
                :label="getLabel(addressItem.key)"
                :readonly="isReadonly(addressItem.key)"
                v-model="addressData[addressItem.key]"
                :options="getSelectOptions(addressItem.key)"
                :invalid="invalidItems.includes(addressItem.key)"
                @update:model-value="validate"
            />
        </template>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxInput from "../library/formElements/NdxInput";
    import NdxRadio from "../library/formElements/NdxRadio";
    import NdxSelect from "../library/formElements/NdxSelect";

    export default {
        name: "AddressForm",
        components: {NdxSelect, NdxRadio, NdxInput},
        emits: ['update:address', 'update:addressType', 'update:addressBook', 'is-valid'],
        props: {
            address: {
                type: Object
            },
            addressType: {
                Object
            },
            addressBook: {
                type: String,
                required: true,
                validator(value) {
                    return ['client', 'user'].includes(value);
                }
            },
            addressTypeOptionsReadonly: {
                type: Boolean
            },
            addressBookOptionsReadonly: {
                type: Boolean
            }
        },
        data() {
            return {
                addressItems: [
                    {key: 'contactType', type: 'select'},
                    {key: 'company', type: 'input'},
                    {key: 'companySalutation', type: 'input'},
                    {key: 'companySupplement', type: 'input'},
                    {key: 'gender', type: 'radio'},
                    {key: 'firstname', type: 'input'},
                    {key: 'lastname', type: 'input'},
                    {key: 'lastnamePrefixFirst', type: 'input'},
                    {key: 'lastnameFirst', type: 'input'},
                    {key: 'lastnameIndicator', type: 'input'},
                    {key: 'lastnamePrefixSecond', type: 'input'},
                    {key: 'lastnameSecond', type: 'input'},
                    {key: 'nameSupplement', type: 'input'},
                    {key: 'qualificationIntermediateSecond', type: 'input'},
                    {key: 'qualificationSubsequent', type: 'input'},
                    {key: 'position', type: 'input'},
                    {key: 'academicTitle', type: 'input'},
                    {key: 'titleOfNobility', type: 'input'},
                    {key: 'street', type: 'input'},
                    {key: 'streetExt', type: 'input'},
                    {key: 'houseNo', type: 'input'},
                    {key: 'houseNoExt', type: 'input'},
                    {key: 'premise', type: 'input'},
                    {key: 'premiseExt', type: 'input'},
                    {key: 'locality', type: 'input'},
                    {key: 'zipcode', type: 'input'},
                    {key: 'city', type: 'input'},
                    {key: 'suburb', type: 'input'},
                    {key: 'state', type: 'input'},
                    {key: 'stateExt', type: 'input'},
                    {key: 'countryId', type: 'select'},
                    {key: 'postbox', type: 'input'},
                    {key: 'postboxZipcode', type: 'input'},
                    {key: 'postboxCity', type: 'input'}
                ],
                invalidItems: []
            };
        },
        computed: {
            ...mapGetters('myAccount', [
                'getItem',
                'hasOneItem',
                'isMandatoryAddressProp',
                'invalidAddressItems'
            ]),
            ...mapState({
                initiated: state => state.myAccount.initiated,
                countries: state => state.country.countries,
                isoLanguageCodes: state => state.country.isoLanguageCodes,
                isMainAddressCreateAllowed: state => state.shop.isMainAddressCreateAllowed,
                isDeliveryAddressCreateAllowed: state => state.shop.isDeliveryAddressCreateAllowed,
                isInvoiceAddressCreateAllowed: state => state.shop.isInvoiceAddressCreateAllowed,
                isUserMainAddressCreateAllowed: state => state.shop.isUserMainAddressCreateAllowed,
                isUserDeliveryAddressCreateAllowed: state => state.shop.isUserDeliveryAddressCreateAllowed,
                isUserInvoiceAddressCreateAllowed: state => state.shop.isUserInvoiceAddressCreateAllowed
            }),
            countryList() {
                if (this.countries && this.countries.length > 0) {
                    return this.countries.map((entry) => {
                        return {value: entry.iso2, text: entry.name};
                    });
                }
                return [];
            },
            addressData: {
                get() {
                    return this.address;
                },
                set() {
                    this.$emit('update:address');
                }
            },
            isNew() {
                return !('id' in this.address && +this.address.id > 0);
            },
            addressTypeOptions() {
                let options = [];

                if (this.addressBook === 'client' && this.isMainAddressCreateAllowed ||
                    this.addressBook === 'user' && this.isUserMainAddressCreateAllowed
                ) {
                    options.push({value: 'main', text: this.$t('label.mainAddress')});
                }
                if (this.addressBook === 'client' && this.isDeliveryAddressCreateAllowed ||
                    this.addressBook === 'user' && this.isUserDeliveryAddressCreateAllowed
                ) {
                    options.push({value: 'delivery', text: this.$t('label.deliveryAddress')});
                }
                if (this.addressBook === 'client' && this.isInvoiceAddressCreateAllowed ||
                    this.addressBook === 'user' && this.isUserInvoiceAddressCreateAllowed
                ) {
                    options.push({value: 'invoice', text: this.$t('label.invoiceAddress')});
                }

                return options;
            },
            isClientAddressCreateAllowed() {
                if (this.addressTypeOptionsReadonly) {
                    switch (this.addressType) {
                        case 'main':
                            return this.isMainAddressCreateAllowed;
                        case 'delivery':
                            return this.isDeliveryAddressCreateAllowed;
                        case 'invoice':
                            return this.isInvoiceAddressCreateAllowed;
                    }
                }

                return this.isMainAddressCreateAllowed || this.isDeliveryAddressCreateAllowed
                    || this.isInvoiceAddressCreateAllowed;
            },
            isUserAddressCreateAllowed() {
                if (this.addressTypeOptionsReadonly) {
                    switch (this.addressType) {
                        case 'main':
                            return this.isUserMainAddressCreateAllowed;
                        case 'delivery':
                            return this.isUserDeliveryAddressCreateAllowed;
                        case 'invoice':
                            return this.isUserInvoiceAddressCreateAllowed;
                    }
                }
                return this.isUserMainAddressCreateAllowed || this.isUserDeliveryAddressCreateAllowed
                    || this.isUserInvoiceAddressCreateAllowed;
            },
            addressBookOptions() {
                let addressBookOptions = [];
                if (this.isClientAddressCreateAllowed) {
                    addressBookOptions.push(
                        {value: 'client', text: this.$t('label.client')}
                    );
                }
                if (this.isUserAddressCreateAllowed) {
                    addressBookOptions.push(
                        {value: 'user', text: this.$t('label.user')}
                    );
                }
                return addressBookOptions;
            }
        },
        watch: {
            initiated: function (newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.validate();
                }
            }
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
            this.$store.dispatch('country/getCountryList');
        },
        mounted() {
            this.validate();
        },
        methods: {
            onAddressTypeChange(type) {
                this.$emit('update:addressType', type);
                this.$nextTick(this.cleanAddressFields);
            },
            onAddressBookChange(addressBook) {
                this.$emit('update:addressBook', addressBook);
                this.$nextTick(this.cleanAddressFields);
            },
            cleanAddressFields() {
                for (const addressKey in this.addressData) {
                    if (!this._hasItem(addressKey) || this.isReadonly(addressKey)) {
                        const addressKeyFieldType = this.addressItems.find(item => item.key === addressKey);
                        switch (addressKeyFieldType) {
                            case 'input':
                                this.addressData[addressKey] = '';
                                break;
                            case 'radio':
                                this.addressData[addressKey] = null;
                                break;
                            case 'select':
                                this.addressData[addressKey] = 'DE';
                                break;
                        }
                    }
                }
                this.validate();
            },
            getLabel(prop) {
                let refEntity = this.addressType ? this.addressType : 'main';
                if ('addressTypes' in this.address &&
                    this.address.addressTypes &&
                    this.address.addressTypes.length > 0
                ) {
                    refEntity = this.address.addressTypes[0].addressType;
                }

                const item = this.getItem(this.addressBook, refEntity + 'Address', prop);
                let label = item && item.userDefinedName && item.userDefinedName.length > 0
                    ? item.userDefinedName
                    : this.$t('contact.' + prop);

                if (this.isMandatory(prop)) {
                    label += ' *';
                }

                return label;
            },
            isMandatory(prop) {
                if (this.addressType) {
                    return this.isMandatoryAddressProp(prop, [this.addressType], this.addressBook);
                }
                if ('addressTypes' in this.address) {
                    return this.isMandatoryAddressProp(
                        prop,
                        this.address.addressTypes.map(item => item.addressType),
                        this.addressBook
                    );
                }
                return false;
            },
            isReadonly(prop) {
                if(this.isMandatory(prop)) {
                    return false;
                }
                if (this.addressType) {
                    const item = this.getItem(this.addressBook, this.addressType + 'Address', prop);
                    if (item) {
                        return item.editState < 2;
                    }
                }
                if ('addressTypes' in this.address) {
                    for (let data of this.address.addressTypes) {
                        const item =  this.getItem(this.addressBook, data.addressType + 'Address', prop);
                        if (item && item.editState < 2) {
                            return true;
                        }
                    }
                }
                return false;
            },
            _hasItem(prop) {
                if ('addressTypes' in this.address) {
                    for (let data of this.address.addressTypes) {
                        const hasOneItem =  this.hasOneItem(this.addressBook, data.addressType + 'Address', [prop]);
                        if (hasOneItem) {
                            return true;
                        }
                    }
                }
                if (this.addressType) {
                    const hasOneItem =  this.hasOneItem(this.addressBook, this.addressType + 'Address', [prop]);
                    if (hasOneItem) {
                        return true;
                    }
                }
                return false;
            },
            getRadioOptions(uiElement) {
                if (uiElement === 'gender') {
                    return [
                        {value: 'male', text: this.$t('label.gender_male')},
                        {value: 'female', text: this.$t('label.gender_female')},
                        {value: 'diverse', text: this.$t('label.gender_diverse')},
                        {value: 'no_gender', text: this.$t('label.gender_no_gender')},
                    ];
                }
                return [];
            },
            getSelectOptions(uiElement) {
                if (uiElement === 'countryId') {
                    return this.countryList;
                } else if (uiElement === 'contactType') {
                    return [
                        {value: '', text: this.$t('contact.unknown')},
                        {value: 'company', text: this.$t('contact.company')},
                        {value: 'person', text: this.$t('contact.privatePerson')}
                    ];
                }
                return [];
            },
            validate() {
                let addressTypes = [];
                if (this.addressType) {
                    addressTypes = [this.addressType];
                } else if ('addressTypes' in this.address) {
                    addressTypes = this.address.addressTypes.map(item => item.addressType);
                }
                if (addressTypes.length > 0) {
                    this.invalidItems = this.invalidAddressItems(
                        this.addressData,
                        addressTypes,
                        this.addressBook
                    );
                } else {
                    this.invalidItems = [];
                }

                this.$emit('is-valid', this.invalidItems.length === 0);
            }
        }
    };
</script>
