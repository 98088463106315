<template>
    <NdxFlyIn v-if="showFilter" class="limit500">
        <template #title>
            {{ $t('label.filterHeadline') }}
        </template>
        <template #default>
            <div class="d-flex gap-3 flex-column">
                <NdxSearchInput
                    class="w-100"
                    variant="secondary"
                    :model-value="searchTextInput"
                    label=""
                    @on-key-enter="(value) => searchTextInput = value"
                />
                <NdxSelect
                    v-if="timeRangeOptions"
                    variant="secondary"
                    v-model="timeRange"
                    :options="timeRangeOptions"
                    label=""
                    class="dropdown-menu-start"
                    text-wrap
                />
                <NdxMultiSelect
                    v-if="stateListOptions"
                    variant="secondary"
                    v-model="stateList"
                    :options="stateListOptions"
                    label=""
                    :readonly="stateListOptions.length < 2"
                    text-wrap
                />
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="clearFilter"
            >
                {{ $t('btn.reset') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="$emit('update:showFilter', false)">
                {{ $t('btn.close') }}
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButton from "./NdxButton.vue";
    import NdxMultiSelect from "./formElements/NdxMultiSelect.vue";
    import NdxSearchInput from "./formElements/NdxSearchInput.vue";
    import NdxSelect from "./formElements/NdxSelect.vue";
    import NdxFlyIn from "./NdxFlyIn.vue";
    import NdxButtonLink from "./NdxButtonLink.vue";

    export default {
        name: "NdxListFilter",
        components: {NdxButtonLink, NdxFlyIn, NdxButton, NdxSelect, NdxMultiSelect, NdxSearchInput},
        emits: ['filter-triggered', 'clear-filter', 'update:showFilter'],
        props: {
            showFilter: {
                type: Boolean
            },
            selectedTimeRange: {
                type: [String, Number]
            },
            timeRangeOptions: {
                type: Array
            },
            statesSelected: {
                type: Array
            },
            stateListOptions: {
                type: Array
            },
            searchText: {
                type: String
            }
        },
        computed: {
            timeRange: {
                get() {
                    return this.selectedTimeRange;
                },
                set(value) {
                    this.updateFilter(
                        value,
                        this.stateList,
                        this.searchTextInput
                    );
                }
            },
            stateList: {
                get() {
                    return this.statesSelected;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        value,
                        this.searchTextInput
                    );
                }
            },
            searchTextInput: {
                get() {
                    return this.searchText;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        this.stateList,
                        value
                    );
                }
            }
        },
        methods: {
            updateFilter(timeRange, stateList, searchText) {
                this.$emit('filter-triggered', {
                    timeRange: timeRange,
                    stateList: stateList,
                    searchText: searchText
                });
            },
            clearFilter() {
                this.$emit('clear-filter');
            }
        }
    };
</script>
