<template>
    <Maintenance v-if="isMaintenanceActive"/>
    <router-view v-else></router-view>
</template>

<script>
    import Maintenance from "./pages/maintenance/Maintenance.vue";

    export default {
        name: 'PortalApp',
        components: {Maintenance},
        computed: {
            isMaintenanceActive () {
                return this.$store.getters['session/isMaintenanceActive'];
            }
        },
        watch: {
            isMaintenanceActive: function (t) {
                console.log('isMaintenanceActive', t);
            }
        }
    };
</script>

<style lang="scss">
    @import 'style/custom_vars.scss';

    @import 'style/app_bootstrap.scss';
    @import 'style/app.scss';
    @import 'style/marketingplanning';
    @import 'style/ndx_form.scss';
    @import 'style/ndx_card.scss';
    @import 'style/ndx_checkout.scss';
    @import 'style/ndx_workflows.scss';
    @import '@style/scrollbar.scss';
</style>
