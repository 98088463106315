<template>
    <NdxListing
        ref="taskListing"
        store-action="workflowTask/getTasklist"
        :filter-criteria="filterCriteria"
        :order-by="{sortBy: 'createdAt', sortAsc: false}"
        @items-changed="updateItems"
    >
        <template #emptyList>
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noSearchHits')"
            />
        </template>

        <template #default>
            <div class="workflowlist-item-container">
                <TaskListItem
                    v-for="task in tasks"
                    :key="task.id"

                    :task="task"
                    @update-task="updateTask"
                />
            </div>
        </template>
    </NdxListing>
</template>

<script>
    import TaskListItem from "./TaskListItem.vue";
    import NdxNotification from "../library/NdxNotification.vue";
    import { mapState } from "vuex";
    import NdxListing from "../library/NdxListing.vue";

    export default {
        name: "TaskListActive",
        components: {NdxListing, NdxNotification, TaskListItem},
        props: {
            filterData: {
                type: Object
            }
        },
        data: function () {
            return {
                tasks: [],
            };
        },
        computed: {
            ...mapState({
                openTaskCount: state => state.workflowTask.openTaskCount
            }),
            assignedToCurrentUser() {
                return this.filterData.stateList.includes('assignedToCurrentUser');
            },
            notAssigned() {
                return this.filterData.stateList.includes('notAssigned');
            },
            filterType() {
                if (this.assignedToCurrentUser && !this.notAssigned) {
                    return 'taskAssigned';
                }
                if (!this.assignedToCurrentUser && this.notAssigned) {
                    return 'taskApproverNotAssigned';
                }
                if (this.assignedToCurrentUser && this.notAssigned) {
                    return 'taskApprover';
                }
                return null;
            },
            filterCriteria() {
                const filterDescription = {
                    type: this.filterType,
                    params: {
                        userId: this.$store.getters['user/currentUserId'],
                        createdAfter: this.filterData.createdAfterCamundaFormat
                    }
                };

                return {
                    filterDescription: filterDescription,
                    searchText: this.filterData.searchText,
                };
            }
        },
        watch: {
            openTaskCount: {
                handler() {
                    this.$refs.taskListing.fetchItems();
                }
            },
        },
        methods: {
            updateItems(items) {
                this.tasks = items;
            },
            updateTask(updatedTask) {
                const idx = this.tasks.findIndex(item => item.id === updatedTask.id);
                if (idx > -1) {
                    this.tasks[idx] = updatedTask;
                }
            }
        }
    };
</script>
