<template>
    <div class="ndx-form-element ndx-search">
        <div class="d-flex align-items-center">
            <NdxIcon icon="search" size="m"/>
            <input
                class="flex-grow-1"
                type="text"
                :placeholder="$t('label.doSearch')"
                :value="modelValue"
                :readonly="disabled"
                :disabled="disabled"
                @input="(evt) => $emit('update:model-value', evt.target.value)"
                @keyup.enter="(evt) => $emit('on-key-enter', evt.target.value)"
            />
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxSearchInput',
        components: {NdxIcon},
        emits: ['on-key-enter', 'update:model-value'],
        props: {
            modelValue: {
                type: String
            },
            disabled: {
                type: Boolean
            }
        }
    };
</script>
