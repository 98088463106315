<template>
    <div class="tagList">

        <div v-if="showOperator">
            <div
                class="operationSwitch ndx-badge tag size-m"
                @click="$emit('update:operator', !operator)"
            >
                <div class="item" :class="{active:operator}">{{ $t('label.and') }}</div>
                <div class="item" :class="{active:!operator}">{{ $t('label.or') }}</div>
                <div class="switchBackground" :class="{inactive:!operator}"></div>
            </div>
        </div>

        <div
            v-for="tag in tags"
            :key="tag.id"

            class="ndx-badge tag size-m"
            :class="{
                disabled: disabled,
                removable: removable
            }"
            :title="tag.description"
            :style="colors(tag)"

            @click.stop="$emit('open-tag', tag)"
        >
            {{ tag.name }}
            <NdxIcon
                v-if="removable"
                size="s"
                icon="close"
                class="ms-1 action-icon"
                @click.stop="$emit('remove-tag', tag)"
            />
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon.vue";

    export default {
        name: 'TagList',
        components: {NdxIcon},
        props: {
            tags: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            removable: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            showOperator: {
                type: Boolean
            },
            operator: {
                type: Boolean
            }
        },
        emits: ['open-tag', 'remove-tag', 'update:operator'],
        methods: {
            colors: function (tag) {
                return 'color:' + tag.fontColor + ';background-color:' + tag.backgroundColor;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .tagList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        .operationSwitch {
            display: flex;
            align-items: center;
            position: relative;
            background-color: var(--bs-gray-100);
            width: 100px;
            height: 25px;
            padding: 0;

            .item {
                padding: 0 4px;
                width: 50%;
                text-align: center;
                color: var(--bs-disabled);

                &.active {
                    color: var(--bs-primary-foreground);
                    font-weight: bold;
                    z-index: 1;
                }
            }

            .switchBackground {
                position: absolute;
                background-color: var(--bs-primary);
                top: 0;
                bottom: 0;
                left: 0;
                width: 50%;
                transition: .3s;
                border-radius: 4px;

                &.inactive {
                    left: 50% !important;
                }
            }
        }
    }
</style>
