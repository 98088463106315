<template>
    <div class="frameContainer">
        <Top
            editable
            :force-multi-address="isMultiAddressDefinition"
            @deactivate-multi-address="deactivateMultiAddress"
            @change-address="changeAddress"
            @change-comment="changeComment"
            @edit-address="editAddress"
        />

        <div class="groupContainer">
            <AddressesGroup
                v-for="group in basketGroupsFiltered"
                :key="group.vendor.id"
                :group="group"
                :is-multi-address="isMultiAddressDefinition"
                @update-valid="updateValid"
            />
        </div>
    </div>

    <NdxFlyIn v-if="showAddressCommentEditing">
        <template #title>
            {{ $t('label.setAddressComment') }}
        </template>
        <template #default>
            <NdxTextarea
                class="mt-2"
                v-for="addressData in addressCommentsTmp"
                :key="addressData.id"
                v-model="addressData.comment"
                :label="formatAddress(addressData.address)"
            />
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="() => {showAddressCommentEditing = false;}">
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton
                :disabled="Object.values(addressComments).length === 0"
                class="btnFlex"
                @click="() => doChangeComment()"
            >{{ $t('btn.save') }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="showAddressChange">
        <template #title>
            {{ $t('label.' + currentAddressType) }}
        </template>
        <template #default>
            <NdxSearchInput v-model="addressListSearch" class="my-2 w-100"/>
            <AddressSelection
                :address-type="currentAddressTypeTech"
                :search-term="addressListSearch"
                v-model="currentAddressId"
            />
        </template>
        <template #buttons>
            <NdxButtonLink
                v-if="isAddressCreateAllowed"
                @click="createAddress"
            >{{ $t('btn.newAddress') }}
            </NdxButtonLink>
            <NdxButtonLink
                v-if="currentAddressType === 'deliveryAddress' && multiAddressAllowed"
                @click="startMultiAddressDef"
            >{{ $t('btn.startMultiAddress') }}
            </NdxButtonLink>
            <NdxButtonLink @click="closeAddressChange">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="doChangeAddress">
                {{
                    currentAddressType === 'deliveryAddress'
                        ? $t('btn.sendToAddress')
                        : $t('btn.save')
                }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="showAddressCreate">
        <template #default>
            <AddressForm
                v-model:address="editedAddress"
                :address-type="currentAddressTypeTech"
                address-type-options-readonly
                :address-book="editAddressParent"
                @is-valid="isValid => editAddressValid = isValid"
                @update:addressBook = "onAddressbookChanged"
            />
        </template>
        <template #buttons>
            <NdxButtonLink @click="closeAddressCreate">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton
                :disabled="!editAddressValid"
                @click="doCreateAddress"
            >{{ $t('btn.save') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="showAddressEdit">
        <template #default>
            <AddressForm
                v-model:address="editedAddress"
                address-type-options-readonly
                :address-book="editAddressParent"
                @is-valid="isValid => editAddressValid = isValid"
                @update:addressBook = "onAddressbookChanged"
            />
        </template>
        <template #buttons>
            <NdxButtonLink @click="closeAddressEdit">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton
                :disabled="!editAddressValid"
                @click="doSaveAddress"
            >{{ $t('btn.save') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import { AddressFormatter } from "../../../plugins/formatter";
    import { mapGetters, mapState } from "vuex";
    import NdxFlyIn from "../../library/NdxFlyIn";
    import NdxButton from "../../library/NdxButton";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import AddressForm from "../AddressForm";
    import NdxTextarea from "../../library/formElements/NdxTextarea";
    import AddressesGroup from "./AddressesGroup";
    import Top from "./parts/Top";
    import NdxSearchInput from "../../library/formElements/NdxSearchInput";
    import AddressSelection from "./parts/AddressSelection.vue";

    const MODE_MULTI_ADDRESS = 'multiAddressDefinition';
    const MODE_DEFAULT_ADDRESS = 'defaultAddress';

    export default {
        name: 'Addresses',
        components: {
            AddressSelection,
            NdxSearchInput,
            Top,
            AddressesGroup,
            NdxTextarea, AddressForm, NdxButtonLink, NdxButton, NdxFlyIn
        },
        data() {
            return {
                showAddressCommentEditing: false,
                addressCommentsTmp: {},
                currentAddressId: 0,
                currentAddressType: null,
                showAddressChange: false,
                showAddressCreate: false,
                editedAddress: {},
                editAddressParent: null,
                editAddressValid: false,
                mode: MODE_DEFAULT_ADDRESS,
                invalidOrderItems: [],
                addressListSearch: '',

                showAddressEdit: false
            };
        },
        computed: {
            ...mapState({
                checkoutData: state => state.basket.checkoutData,
                isDeliveryAddressCreateAllowed: state => state.shop.isDeliveryAddressCreateAllowed,
                isInvoiceAddressCreateAllowed: state => state.shop.isInvoiceAddressCreateAllowed,
                isUserDeliveryAddressCreateAllowed: state => state.shop.isUserDeliveryAddressCreateAllowed,
                isUserInvoiceAddressCreateAllowed: state => state.shop.isUserInvoiceAddressCreateAllowed
            }),
            ...mapGetters('checkout', [
                'multiAddressAllowed'
            ]),
            ...mapGetters('user', [
                'getNewAddress',
                'clientAddresses',
                'userAddresses',
            ]),
            ...mapGetters('basket', {
                basket: 'getBasket',
                isMultiAddressDelivery: 'isMultiAddressDelivery',
                getAddress: 'getAddress',
                areAddressesValid: 'areAddressesValid'
            }),
            isClientAddressCreateAllowed() {
                return this.isDeliveryAddressCreateAllowed
                    || this.isInvoiceAddressCreateAllowed;
            },
            isUserAddressCreateAllowed() {
                return this.isUserDeliveryAddressCreateAllowed
                    || this.isUserInvoiceAddressCreateAllowed;
            },
            isAddressCreateAllowed() {
                if (this.currentAddressType === 'deliveryAddress') {
                    return this.isDeliveryAddressCreateAllowed || this.isUserDeliveryAddressCreateAllowed;
                } else if(this.currentAddressType === 'invoiceAddress') {
                    return this.isInvoiceAddressCreateAllowed || this.isUserInvoiceAddressCreateAllowed;
                }
                return false;
            },
            isMultiAddressDefinition() {
                return this.mode === MODE_MULTI_ADDRESS || this.isMultiAddressDelivery;
            },
            addressComments() {
                const addressComments = {};
                if (this.isMultiAddressDefinition) {
                    for (const orderItemId in this.checkoutData?.orderItems) {
                        const orderItem = this.checkoutData?.orderItems[orderItemId];
                        for (const idx in orderItem.addresses) {
                            const addressInfo = orderItem.addresses[idx];
                            addressComments[addressInfo.addressId] = {
                                address: this.getAddress(addressInfo.addressId),
                                comment: addressInfo.addressComment
                            };
                        }
                    }
                } else if (this.checkoutData.deliveryAddress) {
                    addressComments[this.checkoutData.deliveryAddress.id] = {
                        address: this.checkoutData.deliveryAddress,
                        comment: this.checkoutData.orderDeliveryAddressComment
                    };
                }

                return addressComments;
            },
            isValid() {
                let hasDeliveryAddress = true;
                if (!this.isMultiAddressDelivery) {
                    hasDeliveryAddress = this.checkoutData.deliveryAddress !== null &&
                        this.checkoutData.deliveryAddress.countryId;
                }
                const hasInvoiceAddress = this.checkoutData.invoiceAddress !== null;

                return hasDeliveryAddress &&
                    hasInvoiceAddress &&
                    this.invalidOrderItems.length === 0 &&
                    this.areAddressesValid;
            },
            currentAddressTypeTech() {
                return this.currentAddressType
                    ?  this.currentAddressType.substr(0, this.currentAddressType.indexOf('Address'))
                    : null;
            },
            basketGroupsFiltered() {
                return this.basket.groups.filter(group => {
                    return group.items.filter(item => item.quantity > 0).length > 0;
                });
            }
        },
        watch: {
            isValid: {
                immediate: true,
                handler: function (newVal) {
                    this.$store.dispatch('checkout/setIsValid', newVal);
                }
            }
        },
        created() {
            this.$store.dispatch('basket/getMultiAddressDeliveryAddresses');
            this.$store.dispatch('checkout/loadingFinished');
        },
        mounted() {
            this.$store.dispatch('checkout/setIsValid', this.isValid);
        },
        methods: {
            isClientAddress(address) {
                return !!this.$store.getters['user/clientAddresses']
                    .find((addr) => {
                        return address.id === addr.id;
                    });
            },
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            },
            changeComment() {
                this.addressCommentsTmp = JSON.parse(JSON.stringify(this.addressComments));
                this.showAddressCommentEditing = true;
            },
            doChangeComment() {
                let comments = {};
                for (const idx in this.addressCommentsTmp) {
                    comments[this.addressCommentsTmp[idx].address.id] = this.addressCommentsTmp[idx].comment;
                }
                this.$store.dispatch('basket/updateAddressComments', comments);
                this.showAddressCommentEditing = false;
            },
            changeAddress(addressType) {
                if (addressType in this.checkoutData) {
                    this.currentAddressType = addressType;
                    this.currentAddressId = this.checkoutData[addressType]?.id;
                    this.showAddressChange = true;
                } else if (!this.checkoutData?.invoiceAddress?.id) {
                    this.currentAddressType = addressType;
                    this.showAddressChange = true;
                }
            },
            doChangeAddress() {
                this.$store.dispatch('basket/setAddress', {
                    addressType: this.currentAddressTypeTech,
                    addressId: this.currentAddressId
                });

                this.closeAddressChange();
            },
            closeAddressChange() {
                this.currentAddressType = null;
                this.currentAddressId = 0;
                this.showAddressChange = false;
                this.addressListSearch = '';
            },
            editAddress(type) {
                if (type in this.checkoutData) {
                    this.editAddressValid = false;
                    this.editedAddress = JSON.parse(JSON.stringify(this.checkoutData[type]));
                    if (!this.editAddressParent) {
                        this.editAddressParent = this.isClientAddress(this.editedAddress) ? 'client' : 'user';
                    }
                    this.showAddressEdit = true;
                }
            },
            closeAddressEdit() {
                this.showAddressEdit = false;
                this.editedAddress = null;
                this.editAddressParent = null;
            },
            doSaveAddress() {
                if (this.editAddressValid === false) {
                    return;
                }
                const storeAction = this.editAddressParent === 'client' ?
                    'user/updateClientAddress' :
                    'user/updateUserAddress';

                this.$store.dispatch(storeAction, {
                    addressData: this.editedAddress,
                    addressType: ''
                }).then(() => {
                    this.$store.dispatch('basket/getAllData')
                        .finally(() => {
                            this.closeAddressEdit();
                        });
                }).catch((error) => {
                    console.error(error);
                });
            },
            createAddress() {
                this.editAddressParent = this.isUserAddressCreateAllowed ?
                    'user' :
                    (this.isClientAddressCreateAllowed ? 'client' : null);

                if (!this.editAddressParent) {
                    console.error('add address button should not appear if no address type is allowed');
                    return;
                }
                this.editAddressValid = false;
                this.editedAddress = JSON.parse(JSON.stringify(this.getNewAddress));
                this.showAddressChange = false;
                this.addressListSearch = '';
                this.showAddressCreate = true;
            },
            doCreateAddress() {
                if (this.editAddressValid === false) {
                    return;
                }
                const addressType = this.currentAddressTypeTech;
                const storeAction = this.editAddressParent === 'client' ?
                    'user/updateClientAddress' :
                    'user/updateUserAddress';

                this.$store.dispatch(storeAction, {
                    addressData: this.editedAddress,
                    addressType: addressType
                }).then((address) => {
                    this.currentAddressId = address.id;
                }).catch((error) => {
                    console.error(error);
                });
                this.closeAddressCreate();
            },
            closeAddressCreate() {
                this.showAddressCreate = false;
                this.showAddressChange = true;
            },
            startMultiAddressDef() {
                this.mode = MODE_MULTI_ADDRESS;
                this.showAddressCreate = false;
                this.showAddressChange = false;
                this.addressListSearch = '';
            },
            deactivateMultiAddress() {
                this.mode = MODE_DEFAULT_ADDRESS;
                this.$store.dispatch('basket/deactivateMultiAddress');
            },
            updateValid(data) {
                const idx = this.invalidOrderItems.indexOf(data.orderItemId);
                if (data.valid && idx > -1) {
                    this.invalidOrderItems.splice(idx, 1);
                } else if (data.valid === false && idx < 0) {
                    this.invalidOrderItems.push(data.orderItemId);
                }
            },
            onAddressbookChanged(addressBook) {
                this.editAddressParent = addressBook;

                if (this.editAddressParent === 'client') {
                    let currentAddressTypeAllowed =
                        this.editAddressType === 'delivery' && this.isDeliveryAddressCreateAllowed ||
                        this.editAddressType === 'invoice' && this.isInvoiceAddressCreateAllowed ||
                        this.editAddressType === 'main' && this.isMainAddressCreateAllowed;

                    if (!currentAddressTypeAllowed) {
                        if (this.isDeliveryAddressCreateAllowed) {
                            this.editAddressType = 'delivery';
                        } else if (this.isInvoiceAddressCreateAllowed) {
                            this.editAddressType = 'invoice';
                        } else if (this.isMainAddressCreateAllowed) {
                            this.editAddressType = 'main';
                        }
                    }
                } else if (this.editAddressParent === 'user') {
                    let currentAddressTypeAllowed =
                        this.editAddressType === 'delivery' && this.isUserDeliveryAddressCreateAllowed ||
                        this.editAddressType === 'invoice' && this.isUserInvoiceAddressCreateAllowed ||
                        this.editAddressType === 'main' && this.isUserMainAddressCreateAllowed;

                    if (!currentAddressTypeAllowed) {
                        if (this.isUserDeliveryAddressCreateAllowed) {
                            this.editAddressType = 'delivery';
                        } else if (this.isUserInvoiceAddressCreateAllowed) {
                            this.editAddressType = 'invoice';
                        } else if (this.isUserMainAddressCreateAllowed) {
                            this.editAddressType = 'main';
                        }
                    }
                }

            }
        }
    };
</script>
