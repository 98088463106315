<template>
    <GroupItem
        v-if="!hideItem"
        :item="item"
        :in-tree="inTree"
        :bundle-path="bundlePath"
        :workflow-data="workflowData"
        @start-approval="startApproval"
        @instance-details="instanceDetails"
        @goto-campaignrun="gotoCampaignRun"
        @goto-detailpage="gotoDetailPage"
        @remove-from-basket="removeFromBasket"
        @push-to-watchlist="pushToWatchlist"
        @action-required="(prop) => $emit('action-required', prop)"
    >
        <template #user-actions>
            <component
                class="quantityInput flex-shrink-0"
                :is="'Quantity' + item.quantityStrategy.type"
                :current-quantity="parseFloat(item.quantity)"
                :min-quantity-by-pricelist="item.minOrderableQuantity"
                :data="item.quantityStrategy.data"
                :is-quantity-zero-allowed="item.quantityStrategy.isOptional"
                :only-change-operation-is-remove="onlyQuantityChangeOperationIsRemove"
                :readonly="!item.checks.QUANTITY_CHANGEABLE || printView"
                @quantity-changed="quantityChanged"
            />

            <div class="d-flex flex-wrap gap-3 justify-content-between">
                <div class="d-flex flex-wrap gap-3 hideOnPrintView">
                    <NdxButtonLink
                        v-for="btn in leftActionBtns"
                        :key="btn"
                        @click="() => onButtonClick(btn.action)"
                    >
                        {{ btn.text }}
                    </NdxButtonLink>
                </div>

                <div class="d-flex flex-wrap gap-3 hideOnPrintView">
                    <NdxButtonLink
                        v-for="btn in rightActionBtns"
                        :key="btn"
                        @click="() => onButtonClick(btn.action)"
                    >
                        {{ btn.text }}
                    </NdxButtonLink>
                </div>
            </div>
        </template>
        <template #after-content>
            <div class="notificationWrapper">
                <NdxNotification
                    :duration="5"
                    variant="info"
                    :message="quantityChangedMsg"
                    v-model="quantityChangedNote"
                />
            </div>
        </template>
    </GroupItem>

    <ProjectRun
        v-if="campaignRunId"
        :campaign-run-id="campaignRunId"
        :product-name="item.productName"
        :campaign-run-approved="item.isApproved"
        :live-price-calculation="livePriceCalculation"
        @close="refreshBasket"
        @done="refreshBasket"
    />

    <NdxFlyIn v-if="deleteCandidate">
        <template #default>
            <template v-if="!bundlePath">
                {{
                    $t('message.deleteBasketItem', {
                        name: item.productName,
                        quantity: $n(parseFloat(item.quantity)) + ' ' + deleteCandidate.quantityUnit.shortName
                    })
                }}
            </template>
            <template v-else>
                {{
                    $t('message.deleteBundle', {
                        name: bundlePath[0].bundleProduct.name
                    })
                }}
            </template>
        </template>
        <template #buttons>
            <NdxButton class="btnFlex" @click="doRemoveItemFromBasket">{{ $t('btn.yes') }}</NdxButton>
            <NdxButton class="btnFlex" @click="() => deleteCandidate = null">{{ $t('btn.no') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="removeCandidate">
        <template #default>
            {{
                $t('message.deleteBasketItem', {
                    name: item.productName,
                    quantity: $n(parseFloat(item.quantity)) + ' ' + removeCandidate.quantityUnit.shortName
                })
            }}
            <template v-if="onlyQuantityChangeOperationIsRemove">
                <br/>
                <br/>
                {{ $t('message.decisionIsFinal') }}
            </template>
        </template>
        <template #buttons>
            <NdxButton class="btnFlex" @click="doSetQuantity">{{ $t('btn.yes') }}</NdxButton>
            <NdxButton class="btnFlex" @click="() => removeCandidate = null">{{ $t('btn.no') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import { mapState } from "vuex";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import QuantityDiscrete from "./QuantityDiscrete";
    import QuantityMinMaxStep from "./QuantityMinMaxStep";
    import ProjectRun from "../../projectRun/ProjectRun";
    import GroupItem from "../../checkout/steps/parts/GroupItem";
    import NdxFlyIn from "../../library/NdxFlyIn";
    import NdxButton from "../../library/NdxButton";
    import NdxNotification from "../../library/NdxNotification.vue";
    import OrderItemActions from "../../../mixins/OrderItemActions.vue";

    export default {
        name: "BasketGroupItem",
        components: {
            NdxNotification,
            NdxButton, NdxFlyIn, GroupItem, ProjectRun, NdxButtonLink, QuantityDiscrete, QuantityMinMaxStep
        },
        emits: ['refresh-basket', 'action-required'],
        mixins: [OrderItemActions],
        props: {
            item: {
                type: Object,
                required: true
            },
            inTree: {
                type: Boolean
            },
            type: String
        },
        data() {
            return {
                campaignRunId: null,
                deleteCandidate: null,
                removeCandidate: null,
                timestamp: 0,

                quantityChangedNote: false,
                quantityChangedMsg: ''
            };
        },
        computed: {
            ...mapState({
                updateModus: state => state.productDetail.updateModus,
                bundleTrees: state => state.basket.basket.bundleTrees,
                livePriceCalculation: state => state.shop.basket?.liveCalculation,
                printView: state => state.basket.printView
            }),
            bundlePath() {
                return this.$store.getters['bundles/getPathFromOrderItemId'](this.item?.orderItemId);
            },
            workflowInstanceEvents() {
                if (this.instanceId) {
                    return this.$store.getters['workflowEvents/getByInstanceId'](this.instanceId);
                }
                return [];
            },
            hideItem() {
                const isBundleDetailPage = ['BundleItemDetail', 'ProductsInCategory', 'Product'].includes(
                    this.$route.name
                );

                // in watchlist there is no quantity information
                return this.item && 'quantity' in this.item && +this.item.quantity == 0 && !isBundleDetailPage;
            },
            startApprovalPossible() {
                const campaignRunReady = !('campaignRun' in this.item && this.item.campaignRun
                    && !this.item.campaignRun.isReady);
                return this.approvalRequired && this.isEditable && campaignRunReady;
            },
            onlyQuantityChangeOperationIsRemove() {
                return this.item.checks.OPTIONAL_BUNDLEITEM &&
                    this.item.checks.QUANTITY_CHANGEABLE === false &&
                    parseFloat(this.item.quantity) != 0;
            }
        },
        watch: {
            workflowInstanceEvents(newValue) {
                let newEvents = newValue.filter(item => item.timestamp > this.timestamp);
                if (newEvents.length) {
                    const reloadBasket = newEvents.findIndex((item) => {
                        return item.event === 'complete';
                    }) > -1;

                    if (reloadBasket) {
                        this.$emit('refresh-basket');
                    } else {
                        this.getWorkflowInstanceVariables();
                    }

                    this.setTimestamp();
                }
            }
        },
        created() {
            this.setTimestamp();
        },
        methods: {
            getBundleIdFromOrderItemId(item) {
                let bundleNode = this.bundleTrees.find((tree) => {
                    return tree.orderItemId === item.orderItemId;
                });
                if (bundleNode) {
                    return bundleNode.bundleId;
                } else {
                    return null;
                }
            },
            setTimestamp() {
                this.timestamp = Math.floor(Date.now() / 1000);
            },
            getValueFromVariables(name, defaultValue) {
                if (!this.instanceVariables) {
                    return defaultValue;
                }
                for (let prop in this.instanceVariables) {
                    if (prop === name) {
                        return this.instanceVariables[name].value;
                    }
                }
                return defaultValue;
            },
            instanceDetails() {
                this.$router.push({name: 'WorkflowsInstanceDetail', params: {instanceId: this.instanceId}});
            },
            startApproval() {
                this.$store.dispatch('approveOrderItem/startApproval', {
                    businessOid: this.workflowBusinessOid
                }).then((result) => {
                    this.instanceId = result.id;
                    this.instanceVariables = result.variables;
                }).catch((error) => {
                    console.error(error);
                });
            },
            getWorkflowInstanceVariables() {
                this.$store.dispatch('workflowInstance/getVariables', {
                    businessOid: this.workflowBusinessOid
                }).then((result) => {
                    this.instanceId = result.id;
                    this.instanceVariables = result.variables;
                }).catch((error) => {
                    console.error(error);
                });
            },
            gotoCampaignRun() {
                this.campaignRunId = this.item.campaignRun.id;
            },
            pushToWatchlist() {
                if (!this.bundlePath) {
                    this.$store.dispatch('basket/moveBundleToWatchlist', {
                        bundleId: this.getBundleIdFromOrderItemId(this.item)
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    this.$store.dispatch('basket/moveBundleToWatchlist', {
                        bundleId: this.bundlePath[0].bundleId
                    }).then(() => {
                        this.$store.dispatch('bundles/setPath', null);
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
            removeFromBasket() {
                this.deleteCandidate = this.item;
            },
            doRemoveItemFromBasket() {
                if (!this.bundlePath) {
                    this.$store.dispatch('basket/removeBundle', {
                        bundleId: this.getBundleIdFromOrderItemId(this.deleteCandidate),
                        listType: 'basket'
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        this.deleteCandidate = null;
                    });
                } else {
                    this.$store.dispatch('basket/removeBundle', {
                        bundleId: this.bundlePath[0].bundleId,
                        listType: 'basket'
                    }).then(() => {
                        this.$store.dispatch('bundles/setPath', null);
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        this.deleteCandidate = null;
                        if (this.$route.name === 'BundleItemDetail') {
                            this.$router.push(this.$store.getters['shop/getRouteByType']('basket'));
                        }
                    });
                }
            },
            quantityChanged(newQuantity) {
                const service = this.type === 'configurator' && !this.updateModus ?
                    'bundleConfigurator' :
                    'basket';

                if (service === 'basket' && +newQuantity === 0) {
                    const relevantOrderItemIds = this.$store.getters['bundles/getOrderItemIdsFromTree'](
                        this.bundlePath[0]
                    );
                    const orderItems = this.$store.getters['basket/getOrderItems'];
                    const treeItems = orderItems.filter(item => relevantOrderItemIds.includes(item.orderItemId));
                    const isLastBundleProduct = treeItems.filter(item => item.quantity > 0).length <= 1;

                    if (isLastBundleProduct) {
                        this.deleteCandidate = this.item;
                    } else if (this.$route.name === 'BundleItemDetail' && !this.onlyQuantityChangeOperationIsRemove) {
                            this.doQuantityChange(service, newQuantity);
                    } else {
                        this.removeCandidate = this.item;
                    }
                } else {
                    this.doQuantityChange(service, newQuantity);
                }
            },
            doSetQuantity() {
                this.doQuantityChange('basket', 0);
                this.removeCandidate = null;
            },
            doQuantityChange(service, newQuantity) {
                if (!this.instanceId) {
                    this.$store.dispatch(service + '/updateQuantity', {
                        basketItemId: this.item.orderItemId,
                        quantity: newQuantity
                    }).then((response) => {
                        let quantity = null;
                        if (this.type === 'configurator' && response) {
                            response.groups.forEach((group) => {
                                group.items.forEach((item) => {
                                    if (item.orderItemId === this.item.orderItemId) {
                                        quantity = item.quantity;
                                    }
                                });
                            });
                        } else {
                            quantity = this.$store.getters['basket/getOrderItem'](this.item.orderItemId).quantity;
                        }
                        this.informAboutQuantityAdaption(
                            +newQuantity,
                            quantity
                        );
                    });
                    return;
                }

                this.$store.dispatch('approveOrderItem/quantityChanged', {
                    businessOid: this.workflowBusinessOid,
                    newQuantity: newQuantity
                }).then((result) => {
                    this.instanceId = result.id;
                    this.instanceVariables = result.variables;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.$store.dispatch(service + '/updateQuantity', {
                        basketItemId: this.item.orderItemId,
                        quantity: newQuantity
                    }).then(() => {
                        this.informAboutQuantityAdaption(
                            +newQuantity,
                            this.$store.getters['basket/getOrderItem'](this.item.orderItemId).quantity
                        );
                    });
                });
            },
            informAboutQuantityAdaption(wantedQuantity, isQuantity) {
                if (wantedQuantity !== isQuantity) {
                    this.quantityChangedMsg = this.$t(
                        'message.informAboutQuantityAdaption',
                        {
                            wantedQuantity: this.$n(wantedQuantity),
                            isQuantity: this.$n(isQuantity),
                            unit: this.item.quantityUnit.shortName
                        }
                    );
                    this.quantityChangedNote = true;
                }
            },
            refreshBasket() {
                this.campaignRunId = null;
                this.$emit('refresh-basket');
            },
            gotoDetailPage() {
                // not allowed in print view
                if (this.printView) {
                    return;
                }

                // goto detail page of single orderItem
                let params = {
                    oid: this.item.orderItemId
                };
                this.$router.push({name: 'OrderItemDetail', params: params});
            },
            openBundleDetailPage() {
                // goto detail page of product bundle
                let params = {
                    bid: this.bundlePath[0].bundleId
                };
                this.$router.push({name: 'BundleItemDetail', params: params});
            },
            onButtonClick(action)
            {
                switch (action) {
                    case 'openBundleDetailPage':
                        this.openBundleDetailPage();
                        break;
                    case 'instanceDetails':
                        this.instanceDetails();
                        break;
                    case 'startApproval':
                        this.startApproval();
                        break;
                    case 'gotoDetailPage':
                        this.gotoDetailPage();
                        break;
                    case 'gotoCampaignRun':
                        this.gotoCampaignRun();
                        break;
                    case 'pushToWatchlist':
                        this.pushToWatchlist();
                        break;
                    case 'removeFromBasket':
                        this.removeFromBasket();
                        break;
                    default:
                        console.error('Unknown button action: ', action);
                }
            }
        }
    };
</script>
