<template>
    <div class="d-flex align-items-center">
        <NdxInput
            ref="quantityInput"
            :key="$refs.quantityInput?.$el?.querySelector('input').value"
            :variant="variant"
            :model-value="Math.round(currentQuantity)"
            inputmode="numeric"
            @on-key-enter="setNewQuantity"
            @blur="setNewQuantity"
            :label="label"
            :readonly="!quantityChangeable"
        />
        <NdxIcon
            v-if="quantityChangeable"
            icon="add"
            class="roundBtn"
            is-action
            @click="increaseQuantity"
            size="l"
        />
        <NdxIcon
            v-if="quantityChangeable || onlyChangeOperationIsRemove"
            icon="remove"
            class="roundBtn"
            is-action
            @click="decreaseQuantity"
            size="l"
        />
    </div>
</template>

<script>
import NdxIcon from "../../library/NdxIcon";
import NdxInput from "../../library/formElements/NdxInput";

export default {
        name: "QuantityMinMaxStep",
        components: {NdxInput, NdxIcon},
        props: {
            data: {
                type: Array,
                required: true
            },
            currentQuantity: {
                type: Number,
                required: true
            },
            variant: {
                type: String,
                default: 'secondary'
            },
            label: {
                type: String
            },
            readonly: {
                type: Boolean
            },
            minQuantityByPricelist: {
                type: Number,
                default: 1
            },
            isQuantityZeroAllowed: {
                type: Boolean,
                required: true
            },
            onlyChangeOperationIsRemove: {
                type: Boolean
            }
        },
        computed: {
            minQuantity: function () {
                let min = Number.MAX_VALUE;

                this.data.forEach(function (group) {
                    if (+group.min < min) {
                        min = +group.min;
                    }
                });

                if (+min === 0) {
                    const group = this.getCurrentGroup(+min);
                    min = +group.step;
                }

                return min;
            },
            maxQuantity: function () {
                let max;

                for (const group of this.data) {
                    if (+group.max === 0) {
                        // special case for no limit
                        max = undefined;
                        break;
                    }

                    if (max === undefined || +group.max > max) {
                        max = +group.max;
                    }
                }

                return max;
            },
            decreaseStep: function () {
                let step = 0;
                const currentGroup = this.getCurrentGroup(this.currentQuantity);
                const nextGroup = this.data
                    .filter(group => group.max <= currentGroup.min)
                    .reduce(
                        (prevValue, group) => prevValue && prevValue.max > group.max ? prevValue : group,
                        null
                    );

                if (currentGroup.min <= this.currentQuantity - currentGroup.step) {
                    step = currentGroup.step;
                } else if (nextGroup) {
                    step = this.currentQuantity - nextGroup.max;
                }

                return +step;
            },
            increaseStep: function () {
                let step = 0;
                if (this.currentQuantity == 0) {
                    return Math.max(this.minQuantity, this.minQuantityByPricelist);
                }
                const currentGroup = this.getCurrentGroup(this.currentQuantity);
                const nextGroup = this.data
                    .filter(group => group.min >= currentGroup.max)
                    .reduce(
                        (prevValue, group) => prevValue && prevValue.min < group.min ? prevValue : group,
                        null
                    );

                if (currentGroup.max == 0 || currentGroup.max >= this.currentQuantity + currentGroup.step) {
                    step = currentGroup.step;
                } else if (nextGroup) {
                    step = nextGroup.min - this.currentQuantity;

                    if (step === 0) {
                        step = nextGroup.step;
                    }
                }

                if (this.currentQuantity + step < this.minQuantityByPricelist) {
                    step = this.minQuantityByPricelist - this.currentQuantity;
                }

                return +step;
            },
            quantityChangeable: function () {
                return !this.readonly && (this.minQuantity !== this.maxQuantity || this.isQuantityZeroAllowed);
            }
        },
        methods: {
            getCurrentGroup: function (quantity) {
                const group = this.data.find(function (group) {
                    return +group.min <= +quantity && (+group.max === 0 || +group.max >= +quantity);
                });

                if (!group) {
                    throw new Error('no group found');
                }

                return group;
            },
            increaseQuantity() {
                if (this.increaseStep && (
                    this.maxQuantity === undefined || this.currentQuantity + this.increaseStep <= this.maxQuantity
                )) {
                    this.$emit('quantity-changed', this.currentQuantity + this.increaseStep);
                }
            },
            decreaseQuantity() {
                if (this.onlyChangeOperationIsRemove) {
                    this.$emit('quantity-changed', 0);
                } else if (this.decreaseStep && this.currentQuantity - this.decreaseStep >= this.minQuantity &&
                    this.currentQuantity - this.decreaseStep >= this.minQuantityByPricelist
                ) {
                    this.$emit('quantity-changed', this.currentQuantity - this.decreaseStep);
                } else if (this.isQuantityZeroAllowed) {
                    this.$emit('quantity-changed', 0);
                }
            },
            setNewQuantity(evt) {
                const quantityNumeric = parseFloat(
                    evt?.target?.value || this.$refs.quantityInput.$el.querySelector('input').value
                );

                if (quantityNumeric != this.currentQuantity && !isNaN(quantityNumeric)) {
                    this.$emit('quantity-changed', quantityNumeric);
                } else if (this.isQuantityZeroAllowed && quantityNumeric == 0) {
                    this.$emit('quantity-changed', 0);
                }
            }
        }
    };
</script>
