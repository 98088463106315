<template>
    <ul class="instance-timeline">
        <li
            v-if="instanceEnded !== null"

            class="event status_info"
            :data-date="$d(dateConverter(instanceEnded), 'long')"
        >
            <div class="headline">{{ $t('label.workflow.timeline.instance.ended') }}</div>
        </li>

        <li
            v-for="task of tasks"
            :key="task.id"

            class="event"
            :class="{['status_' + getItemStatusClass(task)]: true}"
            :data-date="$d(dateConverter(task.createdAt), 'long')"
        >
            <div class="headline">{{ task.name }}</div>
            <div class="content">
                <div>
                    {{ $t('label.workflow.taskCandidates') }}:
                    <b>{{ getAssignees(task.id).join(', ') }}</b>
                    <template v-if="getAssigneesRest(task.id).length > 0">
                        <template v-if="allAssigness.includes(task.id)">
                            <b>{{ getAssigneesRest(task.id).join(', ') }}</b>
                            <span class="ass_btn" @click="hideAllAssigness(task.id)">{{ $t('label.less') }}</span>
                        </template>
                        <template v-else>
                            <b>, ...</b>
                            <span   class="ass_btn" @click="showAllAssigness(task.id)">{{ $t('label.more') }}</span>
                        </template>
                    </template>
                </div>
                <div>
                    {{ $t('label.assignee') }}:
                    <b v-if="task.assignee !== null">{{ getUserName(task.assignee) }}</b>
                </div>
                <div>
                    {{ $t('label.endedAt') }}:
                    <b v-if="task.endedAt !== null">{{ $d(dateConverter(task.endedAt), 'long') }}</b>
                </div>
                <div
                    v-if="getTaskVerdict(task) !== null"
                    class="verdict_message"
                    :class="{[getTaskVerdict(task) ]: true}"
                >
                    {{ getVerdictMessage(getTaskVerdict(task)) }}
                </div>
                <template
                    v-for="(comment, idx) of task.comments"
                    :key="idx"
                >
                    <div
                        v-if="comment.message.length > 0"
                        class="mt-2"
                    >
                        <b>{{ $t('label.comment') }}:</b>
                        <br/>
                        <span v-html="formatComment(comment.message)"></span>
                    </div>
                    <div
                        v-if="comment.files?.length > 0"
                        class="mt-2 comment_files"
                    >
                        <b>{{ $t('label.files') }}:</b>
                        <br/>
                        <div class="file-grid">
                            <template
                                v-for="file in comment.files"
                                :key="file.id"
                            >
                                <div class="action-data" @click="downloadFile(file)">
                                    <div class="d-flex align-items-center gap-2">
                                        <component :is="ndxIcon" icon="export" size="s"/>
                                        <span>{{ file.originalFilename }}</span>
                                    </div>
                                </div>
                                <div class="size-data">{{ fileSize(file.size) }}</div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </li>

        <li
            v-if="instanceCreated !== null"

            class="event status_info"
            :data-date="$d(dateConverter(instanceCreated), 'long')"
        >
            <div class="headline">{{ $t('label.workflow.timeline.instance.created') }}</div>
            <div class="content">
                <div>
                    {{ $t('label.requestedBy') }}:
                    <b>{{ timelineData.businessObject.createdBy.username }}</b>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import NdxWorkflowMixin from "./NdxWorkflowMixin.vue";

    export default {
        name: "NdxWorkflowProgression",
        mixins: [NdxWorkflowMixin],
        data() {
            return {
                maxAssigneePreview: 5,
                allAssigness: [],

                tasks: [],
                instanceCreated: null,
                instanceEnded: null
            };
        },
        watch: {
            timelineData: {
                immediate: true,
                deep: true,
                handler: 'initData'
            }
        },
        methods: {
            initData() {
                const startItem = this.timelineData.timeline.filter((item) => {
                    return item.type === 'created' && item.oid.type === 'instance';
                });
                this.instanceCreated = startItem.length > 0 ? startItem[0].date : null;
                const endItem = this.timelineData.timeline.filter((item) => {
                    return item.type === 'ended' && item.oid.type === 'instance';
                });
                this.instanceEnded = endItem.length > 0 ? endItem[0].date : null;

                this.tasks = this.timelineData.tasks.sort(
                    (a, b) => a.createdAt < b.createdAt ? 1 : -1
                );
            },
            getTaskVerdict(task) {
                return task.variables?.verdict_task?.value ?? null;
            },
            getItemStatusClass(task) {
                switch (this.getTaskVerdict(task)) {
                    case 'approve':
                        return 'success';
                    case 'revision':
                        return 'warning';
                    case 'reject':
                        return 'error';
                    default:
                        return '';
                }
            },
            getAssignees(taskId) {
                return this.timelineData.timeline.filter((item) => {
                    return item.type === 'candidate_add' &&
                        item.oid.id === taskId;
                }).map((item) => {
                    return this.getUserName(item.data.userId);
                }).slice(0, this.maxAssigneePreview);
            },
            getAssigneesRest(taskId) {
                return this.timelineData.timeline.filter((item) => {
                    return item.type === 'candidate_add' &&
                        item.oid.id === taskId;
                }).map((item) => {
                    return this.getUserName(item.data.userId);
                }).slice(this.maxAssigneePreview);
            },
            showAllAssigness(taskId) {
                this.allAssigness.push(taskId);
            },
            hideAllAssigness(taskId) {
                const idx = this.allAssigness.indexOf(taskId);
                if (idx > -1) {
                    this.allAssigness.splice(idx, 1);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .instance-timeline {
        --gray-middle: var(--bs-gray-middle, #e6e6e6);
        --gray-dimmer: var(--bs-gray-dimmer, #ccc);
        --info: var(--bs-info, #03a9f4);
        --success: var(--bs-success, #259b24);
        --danger: var(--bs-danger, red);
        --warning: var(--bs-warning, #ffc107);


        margin: 0 0 0 150px;
        position: relative;
        list-style: none;
        text-align: left;
        max-width: calc(100% - 300px);

        .event {
            border-bottom: 1px dashed var(--gray-middle);
            padding: 25px 0 25px 50px;
            position: relative;
            border-left: 3px solid var(--gray-dimmer);

            .headline {
                @extend %font-body1 !optional;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .content {
                @extend %font-body2 !optional;

                & > * {
                    margin-top: 4px;
                }

                .verdict_message {
                    padding: 10px 18px 10px 16px;
                    border-radius: 5px;
                    margin-top: 16px;
                    color: white;

                    &.approve {
                        background-color: var(--success);
                    }
                    &.reject {
                        background-color: var(--danger);
                    }
                    &.revision {
                        background-color: var(--warning);
                        color: black;
                    }

                    & + div {
                        margin-top: 16px !important;
                    }
                }

                .ass_btn {
                    font-weight: bold;
                    cursor: pointer;
                    padding-left: 8px;
                    color: var(--bs-primary);
                }
            }

            &:before,
            &:after {
                position: absolute;
                display: block;
                top: 0;
            }

            &:before {
                @extend %font-caption !optional;
                line-height: 1.8em;
                left: -145px;
                content: attr(data-date);
                text-align: right;
                min-width: 120px;
                top: 25px;
            }

            &:after {
                -webkit-box-shadow: 0 0 0 3px var(--gray-dimmer);
                box-shadow: 0 0 0 3px var(--gray-dimmer);
                left: -6.2px;
                background: #fff;
                border-radius: 50%;
                height: 9px;
                width: 9px;
                content: "";
                top: 32px;
            }

            &.status_info {
                border-left-color: var(--info);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--info);
                    box-shadow: 0 0 0 3px var(--info);
                }
            }

            &.status_success {
                border-left-color: var(--success);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--success);
                    box-shadow: 0 0 0 3px var(--success);
                }
            }

            &.status_error {
                border-left-color: var(--danger);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--danger);
                    box-shadow: 0 0 0 3px var(--danger);
                }
            }

            &.status_warning {
                border-left-color: var(--warning);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--warning);
                    box-shadow: 0 0 0 3px var(--warning);
                }
            }
        }

        .file-grid {
            display: grid;
            column-gap: 8px;
            grid-template-columns: auto auto;
            max-width: 100%;
            word-wrap: anywhere;
        }
    }
</style>
