<template>
    <NdxPageHeader
        :active-route-callback="breadcrumbs.length > 0 ? goToAssetsMainPage : null"
        :breadcrumbs="breadcrumbs"
        :nav-name="navName"
        v-model:view-mode="viewMode"
        view-mode-storage-key="assetsViewMode"
        hide-filter
    />

    <div v-if="showCategoryHeader" class="category-header">
        <div class="category-name">{{ currentCategory.name }}</div>
        <div
            class="category-description"
            v-html="stripHtmlTags(currentCategory.description, ['br'])"
        ></div>
    </div>

    <div
        v-if="showCategories"
        class="card-container"
        :class="{['viewmode-' + viewMode]: true}"
    >
        <NdxCategoryCard
            v-for="category in categories"
            :key="category.id"
            :card-img="category.image"
            :title="category.name"
            :description="category.description"
            :variant="viewMode"
            :background-color="category.backgroundColor"
            :foreground-color="category.iconColor"
            @card-click="() => gotoCategoryPage(category)"
        />
    </div>
    <NdxListing
        v-if="categoryId"
        store-action="assets/getEntries"
        :filter-criteria="filterCriteria"
        :has-additional-content="showCategories"
        @items-changed="updateItems"
    >
        <div
            class="card-container"
            :class="{['viewmode-' + viewMode]: true}"
        >
            <NdxAssetEntryCard
                v-for="entry of entries"
                :key="entry.id"
                :entry="entry"
                :variant="viewMode"
                @click="onEntryClick(entry)"
            />
        </div>
    </NdxListing>
</template>

<script>
    import { stripHtmlTags } from '@utilities/ndxText';
    import NdxAssetEntryCard from "../library/NdxAssetEntryCard";
    import NdxCategoryCard from "../library/NdxCategoryCard.vue";
    import NdxListing from "../library/NdxListing.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";
    import { mapGetters } from "vuex";

    export default {
        name: 'AssetList',
        components: {NdxPageHeader, NdxListing, NdxCategoryCard, NdxAssetEntryCard},
        data() {
            return {
                viewMode: 'card',

                currentCategory: {},
                categories: [],
                categoryId: null,

                entries: [],
            };
        },
        computed: {
            ...mapGetters('assetCategories', [
                'getParentList'
            ]),
            showCategoryHeader() {
                return this.currentRealCategory &&
                    this.currentRealCategory.description
                    && this.currentRealCategory.description.length > 0;
            },
            showCategories() {
                return this.categories.length > 0;
            },
            isTopCategory() {
                return this.categoryId === this.rootCategoryId;
            },
            filterCriteria() {
                return {
                    categoryId: this.categoryId,
                };
            },
            currentWgc() {
                return this.$store.getters['shop/getWgc'](+this.$route.params.id);
            },
            navName() {
                if (this.currentRealCategory) {
                    return this.currentRealCategory.name;
                }
                return this.currentWgc ? this.currentWgc.label : '';
            },
            breadcrumbs() {
                let list = [];

                const parentList = this.getParentList(this.categoryId, this.rootCategoryId);
                if (parentList.length > 2) {
                    list.push({
                        label: '…'
                    });
                }
                for (let item of parentList.slice(-2)) {
                    list.push({
                        label: item.name,
                        click: item.id === this.categoryId
                            ? null
                            : () => {
                                this.$router.push({
                                    name: this.currentWgc.route.name,
                                    params: {
                                        ...this.currentWgc.route.params,
                                        categoryId: item.id
                                    }
                                });
                            }
                    });
                }

                return list;
            },
            currentRealCategory() {
                if (this.currentCategory && 'id' in this.currentCategory &&
                    this.currentCategory.id !== this.rootCategoryId
                ) {
                    return this.currentCategory;
                }
                return null;
            },
            rootCategoryId() {
                return this.$store.getters['shop/assetLibraryRootFolderId'];
            }
        },
        watch: {
            $route(to) {
                if (to.path.includes('asset')) {
                    this.categoryId = +to.params.categoryId || this.rootCategoryId;
                    this.entries = [];
                    this.getCatgoriesList();
                }
            },
            categoryId() {
                this.$store.dispatch('assetCategories/getParentList', {
                    categoryId: this.categoryId,
                    rootCategoryId: this.rootCategoryId
                });
            }
        },
        mounted() {
            this.categoryId = +this.$route.params.categoryId || this.rootCategoryId;
            this.getCatgoriesList();
        },
        methods: {
            stripHtmlTags,
            updateItems(items) {
                this.entries = items;
            },
            onEntryClick(entry) {
                this.$router.push({
                    name: 'AssetsInCategory',
                    params: {
                        aid: entry.id,
                        categoryId: this.$route.params.categoryId,
                        id: this.$route.params.id
                    }
                });
            },
            getCatgoriesList() {
                if (this.isTopCategory) {
                    const wgc = this.$store.getters['shop/getWgc'](+this.$route.params.id);
                    this.currentCategory = {};
                    this.$store.dispatch('assetCategories/getSubtreeBulk', {
                        idList: wgc.config.styleguideIds
                    }).then(subTrees => {
                        const getIdx = (item) => {
                            return wgc.config.styleguideIds.findIndex((id) => id == item.id);
                        };
                        this.categories = subTrees.sort((a,b) => getIdx(a) - getIdx(b));
                    });
                } else {
                    this.$store.dispatch('assetCategories/getSubtree', {
                        rootId: this.categoryId,
                        depth: 1
                    }).then((subtree) => {
                        if (Array.isArray(subtree) && !subtree.length) {
                            this.currentCategory = {};
                            this.categories = [];
                        } else if (subtree === null) {
                            // case where user clicks on link in news and styleguide is inactive
                            this.$router.push({name: 'NotFound', params: {notFound: 'pageNotFound'}});
                        } else {
                            this.currentCategory = JSON.parse(JSON.stringify(subtree));
                            delete this.currentCategory.children;
                            this.categories = subtree.children;
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
            gotoCategoryPage(category) {
                this.$router.push({
                    name: 'AssetsInCategory',
                    params: {
                        id: this.$route.params.id,
                        categoryId: category.id,
                        aid: ''
                    }
                });
            },
            goToAssetsMainPage() {
                this.$router.push(this.currentWgc.route);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }

    .category-header {
        margin-bottom: 32px;
        padding-top: 15px;

        .category-name {
            @extend %font-h2;
        }

        .category-description {
            @extend %font-body2;

            margin-top: 8px;

            &:empty {
                display: none;
            }
        }
    }

    .card-container + .card-container {
        margin-top: 32px;
    }
</style>
