<template>
    <DetailPage v-if="product" :breadcrumb="product.name">
        <template #visual>
            <ProductImages :product="product"/>
        </template>

        <template #headline>
            {{ product.name }}
        </template>

        <template #default>
            <MainInfo/>
            <NdxButtonLink
                v-if="showMoreInfoBtn"
                class="p-0 my-3"
                @click="scrollToTabWrapper"
            >
                {{ $t('label.moreProductInfos') }}
            </NdxButtonLink>
            <ProductFiles
                v-if="downloads.length > 0"
                :list="downloads"
            />
            <Actions
                v-if="dataComplete"
                :bundle="bundle"
                :bundle-items="configuratorItems"
                @refresh-configurator="startConfigurator"
            />
        </template>

        <template #additions>
            <div class="tab-wrapper" :id="'tab-wrapper_' + product.id">
                <div class="tab-nav d-flex flex-wrap gap-3">
                    <NdxButton
                        v-if="isBundle"
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'bundle'}"
                        @click="activeTab = 'bundle'"
                    >
                        {{ $t('label.bundleContent') }}
                    </NdxButton>
                    <NdxButton
                        v-if="showDescription"
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'description'}"
                        @click="activeTab = 'description'"
                    >
                        {{ $t('label.product_description') }}
                    </NdxButton>
                    <NdxButton
                        v-if="showDetailDescription"
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'detailDescription'}"
                        @click="activeTab = 'detailDescription'"
                    >
                        {{ $t('label.product_detailDescription') }}
                    </NdxButton>
                    <NdxButton
                        v-if="showQuantityStrategy"
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'quantityStrategy'}"
                        @click="activeTab = 'quantityStrategy'"
                    >
                        {{ $t('label.product_quantityStrategy') }}
                    </NdxButton>
                    <NdxButton
                        v-if="showDeliveryOptions"
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'deliveryOptions'}"
                        @click="activeTab = 'deliveryOptions'"
                    >
                        {{ $t('label.product_deliveryOptions') }}
                    </NdxButton>
                    <NdxButton
                        v-if="showOrders"
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'orderList'}"
                        @click="activeTab = 'orderList'"
                    >
                        {{ $t('label.orders') }}
                    </NdxButton>
                </div>
                <div class="tab-content">
                    <div v-if="activeTab === 'bundle' && isBundle && bundle" class="treeView basket-frame">
                        <BundleTree
                            :inTree="true"
                            start-open
                            type="configurator"
                            :bundle="bundle"
                            :order-items="configuratorItems"
                        />
                    </div>
                    <div
                        v-if="showDescription && activeTab === 'description'"
                        v-html="product.description"
                    ></div>
                    <div
                        v-if="showDetailDescription && activeTab === 'detailDescription'"
                        v-html="product.detailDescription"
                    ></div>
                    <QuantityStrategy
                        v-if="showQuantityStrategy && activeTab === 'quantityStrategy'"
                    />
                    <DeliveryOptions
                        v-if="showDeliveryOptions && activeTab === 'deliveryOptions'"
                    />
                    <ProductOrders
                        v-if="showOrders && activeTab === 'orderList'"
                    />
                </div>
            </div>

            <NdxHorizontalSlider
                v-if="similarProducts.length > 0"
                :headline="userDefinedHeadline"
                class="mt-5"
                :style="parentWidthStyle"
            >
                <NdxProductCard
                    v-for="simProduct in similarProducts"
                    :key="simProduct.id"
                    :card-img="simProduct.image"
                    :card-img-background-color="simProduct.imageBgColor"
                    :title="simProduct.name"
                    :description="simProduct.listingDescription"
                    :price="simProduct.minBasePrice"
                    :price-tag="simProduct.priceTag"
                    :list-price="simProduct.listPrice"
                    :currency="currency"
                    :quantity="simProduct.displayQuantity || 1"
                    :quantityUnit="simProduct.quantityUnit"
                    :label="simProduct.label"
                    variant="card"
                    :bundleParent="product.bundleParent"
                    @card-click="() => gotoDetailPage(simProduct)"
                />
            </NdxHorizontalSlider>
        </template>
    </DetailPage>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { stripHtmlTags } from "@utilities/ndxText";
    import ProductOrders from "./ProductOrders";
    import DeliveryOptions from "./DeliveryOptions";
    import QuantityStrategy from "./QuantityStrategy";
    import NdxButton from "../../library/NdxButton";
    import Actions from "./Actions";
    import ProductFiles from "./ProductFiles";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import MainInfo from "./MainInfo";
    import ProductImages from "./ProductImages";
    import NdxHorizontalSlider from "../../library/NdxHorizontalSlider";
    import NdxProductCard from "../../library/NdxProductCard";
    import BundleTree from "../../checkout/steps/parts/BundleTree.vue";
    import DetailPage from "../../../pages/detailPage/DetailPage.vue";

    export default {
        name: 'ProductDetail',
        components: {
            DetailPage, BundleTree, NdxProductCard, NdxHorizontalSlider, ProductImages, MainInfo, NdxButtonLink,
            ProductFiles, Actions, NdxButton, QuantityStrategy, DeliveryOptions, ProductOrders
        },
        props: {
            parentWidth: {
                type: Number
            }
        },
        data() {
            return {
                activeImageIdx: 0,
                activeTab: null,
                similarProducts: []
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product,
                updateModus: state => state.productDetail.updateModus,
                showOrdersState: state => state.shop.productDetails.showOrders,
                showMoreInfo: state => state.shop.productDetails.showMoreInfo,
                similarProductsHeadline: state => state.products.similarProductsHeadline,
                bundleTree: state => state.bundleConfigurator.bundleTree,
                groups: state => state.bundleConfigurator.groups
            }),
            ...mapGetters('productDetail', [
                'dataComplete'
            ]),
            ...mapGetters('shop', [
                'productRootFolderId',
                'currency'
            ]),
            bundle() {
                return this.bundleTree;
            },
            configuratorItems() {
                let items = [];
                this.groups.forEach((group) => {
                    items = items.concat(group.items);
                });
                return items;
            },
            isBundle() {
                return this.product?.bundleParent;
            },
            showOrders() {
                return this.showOrdersState && !this.isBundle;
            },
            showDescription() {
                return !!(this.product && this.product.description && this.product.description.length > 0);
            },
            showDetailDescription() {
                return !!(this.product && this.product.detailDescription && this.product.detailDescription.length > 0);
            },
            showQuantityStrategy() {
                let hasPriceTag = this.product?.priceTag?.length;
                return !hasPriceTag && !this.isBundle;
            },
            showDeliveryOptions() {
                return !this.$store.getters['productDetail/hideDelivery'] && !this.isBundle;
            },
            showMoreInfoBtn() {
                return this.showMoreInfo && (this.showDescription || this.showDetailDescription
                    || this.showQuantityStrategy || this.showOrders);
            },
            userDefinedHeadline() {
                return this.similarProductsHeadline || this.$t('label.similarProducts');
            },
            parentWidthStyle() {
                let styles = [];

                if (this.parentWidth && this.parentWidth > 0) {
                    styles.push('width: ' + this.parentWidth + 'px;');
                }

                return styles.join(', ');
            },
            downloadableImages() {
                return (this.product?.images?.filter(img => img.isDownloadable) ?? []);
            },
            downloads() {
                return this.downloadableImages.concat(this.product?.productFiles ?? []);
            }
        },
        watch: {
            product: {
                immediate: true,
                handler: function () {
                    if (this.isBundle) {
                        this.startConfigurator();
                    }
                    this.getSimilarProducts();
                    this.setActiveTab();
                }
            }
        },
        mounted() {
            if (!this.updateModus) {
                this.$store.dispatch('bundleConfigurator/reset');
            }
        },
        unmounted() {
            this.$store.dispatch('productDetail/reset');
            this.$store.dispatch('bundleConfigurator/reset');
        },
        methods: {
            stripHtmlTags,
            scrollToTabWrapper() {
                document.getElementById('tab-wrapper_' + this.product.id).scrollIntoView();
            },
            setActiveTab() {
                // Define all tabs and corresponding conditions
                const tabs = [
                    {name: 'bundle', condition: this.isBundle},
                    {name: 'description', condition: this.showDescription},
                    {name: 'detailDescription', condition: this.showDetailDescription},
                    {name: 'quantityStrategy', condition: this.showQuantityStrategy},
                    {name: 'orderList', condition: this.showOrders},
                    {name: 'deliveryOptions', condition: this.showDeliveryOptions},
                ];

                // Find the first tab whose condition is satisfied
                const activeTab = tabs.find(tab => tab.condition)?.name;

                // If there is no activeTab or the current active tab is not visible, set activeTab to new value
                if (!this.activeTab || !tabs.find(tab => tab.name === this.activeTab)?.condition) {
                    this.activeTab = activeTab;
                }
            },
            getSimilarProducts() {
                this.similarProducts = [];
                if (this.product && this.product.id) {
                    this.$store.dispatch('products/getSimilarProducts', {
                        pciId: this.product.id
                    }).then((list) => {
                        this.similarProducts = list;
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
            gotoDetailPage(product) {
                this.$router.push({name: 'Product', params: {pid: product.id}});
            },
            startConfigurator() {
                if (!this.updateModus) {
                    // get configuration
                    this.$store.dispatch('bundleConfigurator/add', {
                        productId: this.product.id,
                        quantity: 1,
                        productFeatureValues: null,
                        campaignRunId: null,
                        getFullBasket: true
                    }).then((result) => {
                        this.$store.dispatch('bundles/setBundleTrees', result.bundleTrees);
                    });
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_bootstrap";
    @import "~bootstrap/scss/mixins/breakpoints";

    .tab-wrapper {
        .tab-content {
            margin-top: 20px;
        }
    }

    .treeView {
        border: 2px solid var(--bs-gray-middle);
        border-radius: 4px;
        padding: 8px;
        padding-right: 16px;

        :deep(.bundleTree .children > .product-groupItem) {
            padding: 16px 0 0 16px;
        }

        :deep(.product-groupItem + .product-groupItem),
        :deep(.bundleTree + .product-groupItem),
        :deep(.product-groupItem + .bundleTree),
        :deep(.bundleTree + .bundleTree) {
            border-top: 1px solid var(--bs-gray-middle);
        }
    }
</style>
