<template>
    <NdxPageHeader
        hide-view-mode
        hide-filter
        :nav-name="currentWgc.label"
        :breadcrumbs="breadcrumbs"
    />
    <!--div class="ndxMarketingPlanning d-none d-md-block"-->
    <div class="ndxMarketingPlanning">
        <component :is="'style'" v-html="customStyles"></component>
        <div class="timelineRoot">
            <div class="zoomLevels container-fluid">
                <div class="row">
                    <div class="col d-inline justify-content-start">
                        <NdxSelect
                            v-if="marketingPlannerScRootFolderId"
                            variant="secondary"
                            :model-value="root"
                            :options="[
                                {value: marketingPlannerRootFolderId, text: $t('mp.global')},
                                {value: marketingPlannerScRootFolderId, text: $t('mp.shopClient')},
                            ]"
                            :align-start="true"
                            @update:model-value="switchRoot"
                            :style="'max-width: 200px;display:inline-block;margin-right: 16px;'"
                        />
                        <div
                            class="zoomButtonLeft today"
                            @click="showToday"
                        >{{ $t('mp.today') }}</div>
                        <span class="extraSpaceRight"></span>
                        <div
                            class="zoomButtonLeft days"
                            :class="{active: zoomLevel==='days'}"
                            @click="zoomDays"
                        >{{ $t('mp.days') }}</div>
                        <div
                            class="zoomButtonLeft weeks"
                            :class="{active: zoomLevel==='weeks'}"
                            @click="zoomWeeks"
                        >{{ $t('mp.weeks') }}</div>
                        <div
                            class="zoomButtonLeft months"
                            :class="{active: zoomLevel==='months'}"
                            @click="zoomMonths"
                        >{{ $t('mp.months') }}</div>
                        <div
                            class="zoomButtonLeft fit"
                            :class="{active: zoomLevel==='fit'}"
                            @click="zoomFit"
                        >{{ $t('mp.fit') }}</div>
                    </div>
                    <div class="col-auto d-flex justify-content-end">
                        <div
                            v-if="editableByClient"
                            class="zoomButtonRight"
                            :title="$t('label.addMainCampaign')"
                            @click="() => addCampaign(root)"
                        >
                            <NdxIcon icon="add"></NdxIcon>
                        </div>
                        <div class="zoomButtonRight" v-if="!expanded" @click="expand">
                            <NdxIcon icon="expand-button"></NdxIcon>
                        </div>
                        <div class="zoomButtonRight" v-else @click="collapse">
                            <NdxIcon icon="collapse-button"></NdxIcon>
                        </div>
                    </div>
                </div>
            </div>

            <div id="MarketingPlanner" v-show="hasData"></div>
            <div class="noData" v-show="!hasData">{{ $t('mp.noCampaignData') }}</div>
        </div>
    </div>
    <!--div class="d-md-none noSupportYet">{{$t('mp.noSmartphoneSupportYet')}}</div-->

    <DetailFlyIn
        v-if="flyInData !== null"
        :fly-in-data="flyInData"
        :display-quantity="displayQuantity"
        @on-close="flyInData = null"
    />

    <NdxFlyIn v-if="campaignDecide !== null">
        <template #default>
            {{ $t('mp.confirm_campaign_delete', {name: campaignDecide.name}) }}
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="campaignDecide = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="doDeleteCampaign">{{ $t('label.delete') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="productDecide !== null">
        <template #default>
            {{ $t('mp.confirm_product_delete', {name: productDecide.name}) }}
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="productDecide = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="doDeleteProduct">{{ $t('label.delete') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <ProductPicker
        v-if="showProductPicker"
        @on-close="showProductPicker = false"
        @on-pick="onPickProducts"
    />
    <ProductEditor
        v-if="editedProduct"
        v-model:product="editedProduct"
        @close="onProductEditorClose"
    />
    <CampaignEditor
        v-if="editedCampaign"
        v-model:campaign="editedCampaign"
        @close="onCampaignEditorClose"
    />

    <div class="notificationWrapper">
        <NdxNotification
            variant="error"
            v-model="reOrderingError"
            :duration="3"
            :message="$t('mp.maxDepthReached')"
        />
    </div>
</template>

<script>
    import "@thirdParty/TreeGrid/Grid/GridED.js";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import { mapGetters } from "vuex";
    import { routeMap } from "../../store/modules/shop";
    import NdxIcon from "../library/NdxIcon";
    import NdxSelect from "../library/formElements/NdxSelect";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import { PriceFormatter } from "../../plugins/formatter";
    import { stripHtmlTags } from "@utilities/ndxText";
    import ProductEditor from "./ProductEditor";
    import CampaignEditor from "./CampaignEditor";
    import ProductPicker from "./ProductPicker";
    import NdxNotification from "../library/NdxNotification";
    import { hex2hsl } from "@utilities/ndxColor";
    import DetailFlyIn from "./DetailFlyIn";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'MarketingPlannerDetails',
        components: {
            NdxPageHeader,
            DetailFlyIn,
            NdxNotification,
            ProductPicker,
            CampaignEditor,
            ProductEditor, NdxSelect, NdxButtonLink, NdxButton, NdxFlyIn, NdxIcon
        },
        data() {
            return {
                orderHistory: [],

                hasData: true,
                grid: null,
                timelineData: null,
                expanded: false,
                zoomLevel: 'fit',
                expandedStates: {},
                tree: null,

                baseTS: 0,
                finishTS: 0,

                YEAR_IN_MS: 365 * 24 * 60 * 60 * 1000,
                minDate: Date.now(),
                maxDate: Date.now(),

                resizeThrottle: null,

                foregroundClasses: {},
                timeLineClasses: {},

                reOrderingError: null,

                flyInData: null,
                displayQuantity: 1,

                editedCampaign: null,
                campaignDecide: null,

                productDecide: null,
                showProductPicker: false,
                editedProduct: null,
                productPickerParentFolder: null,

                campaignActions: [
                    {icon: 'edit', text: this.$t('label.editCampaign'), action: 'editCampaign'},
                    {icon: 'add', text: this.$t('label.addCampaign'), action: 'addCampaign'},
                    {icon: 'trash', text: this.$t('label.delete'), action: 'deleteCampaign'},
                    {icon: 'add', text: this.$t('label.addProduct'), action: 'addProduct'},
                ],
                productActions: [
                    {icon: 'edit', text: this.$t('label.editProduct'), action: 'editProduct'},
                    {icon: 'trash', text: this.$t('label.delete'), action: 'deleteProduct'},
                ]
            };
        },
        computed: {
            ...mapGetters('shop', [
                'marketingPlannerRootFolderId', 'marketingPlannerScRootFolderId', 'currency'
            ]),
            ...mapGetters('marketingPlanning', [
                'editableByClient',
                'root'
            ]),
            customStyles() {
                let styles = '';

                for (const className in this.foregroundClasses) {
                    const hsl = hex2hsl(this.foregroundClasses[className]);
                    const delta = 40;
                    const h = hsl.h;
                    const s = (hsl.s > 50 ? (hsl.s - delta) : (hsl.s + delta));
                    const l = (hsl.l > 50 ? (hsl.l - delta) : (hsl.l + delta));
                    styles += '.' + className + '{ color : ' + this.foregroundClasses[className] + ' !important;}' +
                        '.HideCol0Actions .' + className + ':hover{ color : ' + "hsl(" + h + "," + s + "%," + l + "%)" +
                        ' !important;}';
                }

                for (const className in this.timeLineClasses) {
                    styles += '.' + className + '.CompleteText, .' + className + '.In {' +
                        'color: ' + this.timeLineClasses[className].color + ' !important;' +
                        'text-align: center;' +
                        'font-size: 9px;' +
                        '}';
                    styles += '.' + className +
                        '.In{ background-color: ' + this.timeLineClasses[className].bgColorActive + ' !important;}';
                    styles += '.' + className + '.CompleteGauge { background-color: ' +
                        this.timeLineClasses[className].bgColorInactive + ' !important;}';
                }

                return styles;
            },
            currentWgc() {
                return this.$store.getters['shop/getWgcsByType']('marketingplanner')[0];
            },
            breadcrumbs() {
                return [{
                    label: this.currentWgc.label
                }];
            }
        },
        watch: {
            root: {
                immediate: true,
                handler: 'initGrid'
            }
        },
        mounted() {
            window.addEventListener('resize', this.resetTreeGridSize);
            window.addEventListener('click', this.touchHandler);
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.resetTreeGridSize);
            window.removeEventListener('click', this.touchHandler);
            this.deleteGrid();
        },
        methods: {
            initGrid() {
                this.deleteGrid();
                if (this.root) {
                    this.load();
                }
            },
            deleteGrid() {
                if ('DisposeGrids' in window) {
                    window.DisposeGrids();
                }
                this.grid = null;
                this.expanded = false;
            },
            getProxyMeta(proxymeta) {
                return typeof proxymeta === 'string'
                    ? JSON.parse(proxymeta)
                    : proxymeta;
            },
            load: function (alwaysReInit) {
                this.$store.dispatch('marketingPlanning/getMarketingplanning', {
                    // set start date to now - 1 year,
                    start: (new Date(Date.now() - this.YEAR_IN_MS)).toUTCString(),
                    duration: '730 days',
                    mpRootFolder: this.root
                }).then((tree) => {
                    if (tree.length) {
                        this.loadOrderHistory(tree, alwaysReInit);
                    } else {
                        this.hasData = false;
                        this.timelineData = null;
                        this.orderHistory = [];
                        this.deleteGrid();
                    }
                }).catch((error) => {
                    console.error(error);
                });
            },
            loadOrderHistory(tree, alwaysReInit) {
                this.tree = tree;
                let list = this.getOrderHistoryRequestData(tree);

                this.$store.dispatch('orders/getOrderHistoryByProductAndDateRange', {
                    list: list
                }).then((result) => {
                    this.expandedStates = this.grid ? this.readExpandedStates(this.grid.Rows) : {};
                    this.orderHistory = result;
                    this.timelineData = this.buildTree(tree, this.orderHistory);
                    this.hasData = true;
                    window.TGLoadGridE(
                        '/js/apps/shared/thirdParty/TreeGrid/Grid/GridE.js',
                        this.startGrid.bind(this, alwaysReInit)
                    );
                }).catch((error) => {
                    console.error(error);
                });
            },
            getOrderHistoryRequestData(list) {
                let ret = [];
                for (let i = 0; i < list.length; i++) {
                    if ('proxymeta' in list[i]) {
                        ret.push({
                            'productId': list[i].proxymeta.targetId,
                            'startDate': ndxDateConvert(list[i].proxymeta.schedules[0].startDate),
                            'endDate': ndxDateConvert(list[i].proxymeta.schedules[0].endDate)
                        });
                    }
                    if ('children' in list[i]) {
                        let data = this.getOrderHistoryRequestData(list[i].children);
                        if (data.length) {
                            ret.push(...data);
                        }
                    }
                }
                return ret;
            },
            getConfig: function () {
                let lang = 'EN';
                let kw = 'CW';

                if (this.$i18n && this.$i18n.locale === 'de') {
                    lang = 'DE';
                    kw = 'KW';
                }

                return {
                    Cfg: {
                        Code: 'SGCEELGSLFAVEC',
                        MainCol: 'Task',
                        SuppressCfg: 4, // disable cookie
                        GanttLap: 0,
                        Style: 'White',
                        NoVScroll: 1,
                        FullId: 1,
                        SafeCSS: 0,
                        MidWidth: 300,
                        MinRowHeight: 44,
                        NoTreeLines: 0,
                        Dragging: this.editableByClient ? 1 : 0,
                        SectionResizing: 0,
                        Editing: 0,
                        Selecting: 0,
                        Language: lang
                    },
                    LeftCols: [
                        {Name: 'ordered', Type: 'Html', CanEdit: 0, CanSort: 0, ShowColName: 0, MinWidth: 38},
                        {Name: 'Panel', Type: 'Panel', Visible: 0},
                        {Name: 'id', CanEdit: 0, CanSort: 0, Visible: 0},
                        {Name: 'Task', Type: 'Html', CanSort: 0, MinWidth: 300},
                        {Name: 'Img', Type: 'Html', CanSort: 0, Visible: 0},
                        {Name: 'Start', Type: 'Date', Format: 'd.m.yyyy', Visible: 0},
                        {Name: 'Ende', Type: 'Date', Format: 'd.m.yyyy', Visible: 0},
                        {Name: 'Txt', Visible: 0},
                        {Name: 'COMP', Type: 'Float', Visible: 0},
                        {Name: 'Flags', Type: 'Date', Range: 1, Visible: 0},
                        {
                            Name: 'Actions', Type: 'Html', Visible: this.editableByClient ? 1 : 0,
                            CanSort: 0, ShowColName: 0, MinWidth: 136
                        }
                    ],
                    Cols: [
                        {Name: "DUR", Type: "Float", CanEmpty: 1, Visible: 0},
                        {Name: "CLASS", Type: "Text", Visible: 0},
                        {Name: "RUN", Type: "Text", Visible: 0, NoUpload: 1, NoChanged: 1}, // the calculated column
                    ],
                    RightCols: [
                        {
                            Name: 'Planner', // relevant for naming in following Def - part
                            Type: 'Gantt',
                            GanttTask: 'Box',
                            GanttTop: 10,
                            GanttStart: 'Start',
                            GanttEnd: 'Ende',
                            GanttComplete: 'COMP',
                            GanttLeft: 2,
                            GanttHtmlLeft: '*Img*',
                            GanttZoom: 'Wochen',
                            GanttLines: '0#7/1/2018#Cindy',
                            GanttFlags: "Flags"
                        }
                    ],
                    Def: [
                        {Name: "Run", CDef: "Run", PlannerGanttSummaryCDef: "Data"},
                        {Name: "Run", Calculated: "1", CalcOrder: "RUN,Start,Ende,DUR"},
                        {Name: "Run", RUNFormula: "ganttrunsum()", RUNUndo: 0},
                        {
                            Name: "Run",
                            PlannerGanttRunSummary: "0",
                            PlannerGanttSummaryCols: "Start,Ende,,TYPE,Txt,CLASS,TIP,,,STATE,,,,COMP,ImgFile,Img"
                        },
                        {Name: "Run", PlannerGanttRun: "RUN"},
                        {Name: "Run", PlannerGanttStart: "Start", PlannerGanttEnd: "Ende"},
                        {Name: "Run", StartFormula: "ganttrunstart()", StartCanEdit: "0"},
                        {Name: "Run", EndeFormula: "ganttrunend()", EndeCanEdit: "0"},
                        {Name: "Run", DURFormula: "ganttrunduration()", DURCanEdit: "0"},
                        {Name: "Run", PlannerGanttRunMoveRight: "Move"},
                        {Name: "Run", CanFilter: "0"},
                        {Name: "Run", StartNoChanged: 1, EndeNoChanged: 1, DURNoCHanged: 1, CLASSNoChanged: 1},
                        {Name: "Data", Visible: 0}
                    ],
                    Header: {
                        ordered: '',
                        Task: '',
                        Img: '',
                        Txt: 'Beschreibung',
                        Actions: ''
                    },
                    Zoom: [
                        {
                            Name: 'Monate', GanttUnits: 'w', GanttWidth: 13, GanttChartRound: 'd',
                            GanttHeader1: 'y#yyyy', GanttHeader2: 'M#%MMMM'
                        },
                        {
                            Name: 'Wochen', GanttUnits: 'd', GanttWidth: 7, GanttChartRound: 'd',
                            GanttHeader1: 'M#%MMMM yyyy', GanttHeader2: 'w1#' + kw + ' %ddddddd'
                        },
                        {
                            Name: 'Tage', GanttUnits: 'd', GanttChartRound: 'd',
                            GanttHeader1: 'w1#' + kw + ' %ddddddd  yyyy', GanttHeader2: 'd#%d'
                        }
                    ],
                    Toolbar: {Visible: 0}
                };
            },

            getCompletion: function (schedule) {
                let now = Date.now();

                if (schedule.startDate > now) {
                    return 0;
                }
                if (schedule.endDate <= now) {
                    return 99.99;
                }

                return (now - schedule.startDate) / (schedule.endDate - schedule.startDate) * 100;
            },

            convertToMS: function (schedule) {

                if (!schedule) {
                    return {
                        id: null,
                        name: '',
                        startDate: Date.now(),
                        endDate: Date.now(),
                        color: null,
                        bgColorActive: null,
                        bgColorInactive: null,
                    };
                }

                let startDate = ndxDateConvert(schedule.startDate);
                let endDate = ndxDateConvert(schedule.endDate);

                return {
                    id: schedule.id,
                    name: schedule.name,
                    startDate: startDate.getTime(),
                    endDate: endDate.getTime(),
                    color: schedule.color,
                    bgColorActive: schedule.bgColorActive,
                    bgColorInactive: schedule.bgColorInactive,
                };
            },

            getMinMaxDate: function (schedule) {
                this.minDate = Math.min(this.minDate, schedule.startDate);
                this.maxDate = Math.max(this.maxDate, schedule.endDate);
            },

            getTreeNode: function (treeGridRow) {
                if (!this.tree || treeGridRow.id === 'Header' || ["NoData", "Toolbar"].includes(treeGridRow.id)) {
                    return null;
                }

                let isProxy = treeGridRow.Txt === 'ProductLane' && treeGridRow.Def?.CDef === 'Run' ||
                    treeGridRow.PlannerGanttClass?.includes('Product');
                let ids = treeGridRow.id.split('$').map(id => +id.match(/\d+/)[0]);

                if (ids.at(-1) === ids.at(-2)) {
                    // we search for a tree node with children displayed in on row
                    ids.pop();
                    isProxy = false;
                }

                const findNode = function (tree) {
                    let res = null;
                    tree.forEach(node => {
                        if (res === null && node.id === ids[0]) {
                            if (((isProxy && node.proxymeta || !isProxy && !('proxymeta' in node))) &&
                                ids.length === 1
                            ) {
                                res = node;
                                return false; //break loop
                            } else if (node.children?.length) {
                                ids.shift();
                                let tmp = findNode(node.children);
                                if (tmp) {
                                    res = tmp;
                                    return false; //break loop
                                }
                            }
                        }
                    });

                    return res;
                };

                return findNode(this.tree);
            },

            getParentNode: function (treeNode) {
                const isProxy = 'proxymeta' in treeNode;
                const isTopLevel = isProxy ?
                    false :
                    this.tree.some(topLevelEntry => topLevelEntry.id === treeNode.id);

                const searchProductParent = function (subtrees) {
                    let res;

                    subtrees.forEach(subtree => {
                        if (subtree.children?.length &&
                            subtree.children.some(child => child.proxymeta?.id === treeNode.proxymeta.id)
                        ) {
                            res = subtree;
                            return false;
                        }

                        if (!res && subtree.children?.length) {
                            res = searchProductParent(subtree.children);
                        }
                    });

                    return res;
                };

                const searchFolderParent = function (subtrees) {
                    let res;

                    subtrees.forEach(subtree => {
                        if (subtree.children?.length &&
                            subtree.children.some(child => !('proxymeta' in child) && child.id === treeNode.id)
                        ) {
                            res = subtree;
                            return false;
                        }

                        if (!res && subtree.children?.length) {
                            res = searchFolderParent(subtree.children);
                        }
                    });

                    return res;
                };

                if (isTopLevel) {
                    return {
                        isMpRoot: true,
                        id: this.root
                    };
                } else if (isProxy) {
                    return searchProductParent(this.tree);
                }

                return searchFolderParent(this.tree);
            },

            buildTree: function (tree, orderHistory) {
                let ret = [];

                for (const index in tree) {
                    ret = ret.concat(this.createTreeGridEntry(tree[index], orderHistory, false));
                }

                return ret;
            },

            createTreeGridEntry: function (treeEntry, orderHistory, hasParent = true, isParentMultiTimelane = false) {
                const isProxyEntry = 'proxymeta' in treeEntry;
                const schedules = isProxyEntry ? treeEntry.proxymeta.schedules : treeEntry.schedules;
                const isMultiTimeline = isProxyEntry ?
                    isParentMultiTimelane :
                    treeEntry.mpconfig?.displayChildrenInOneRow ?? false;

                const now = Date.now();
                const folderBgColor = (treeEntry.backgroundColor && treeEntry.backgroundColor.length) ?
                    treeEntry.backgroundColor : null;

                const folderColor = (treeEntry.iconColor && treeEntry.iconColor.length) ? treeEntry.iconColor : null;
                if (folderColor) {
                    this.foregroundClasses['mp_color_' + folderColor.substr(1)] = folderColor;
                }

                const getNameForTree = function (name, hasParent, color, isProxy) {
                    return isProxy ?
                        '<span  class="TimelineProductLabel">' + name + '</span>' :
                        '<span class="' + (hasParent ? 'TimelineCategoryLabel' : 'TimelineCampaignLabel') +
                        (color ? ' mp_color_' + color.substr(1) : '') +
                        '">' + name + '</span>';
                };

                const _renderImg = function (filename) {
                    if (filename) {
                        let url = '/ndx/file/downloadResized/220/220/' + filename;
                        return '<div class="imageCircle" style="background-image: url(\'' + url + '\')"></div>';
                    } else {
                        return '';
                    }
                };

                const getClassNames = function (isProxy, hasParent, completion, styleClass) {
                    let classes = [];
                    if (isProxy) {
                        if (completion > 0) {
                            classes.push('ProductItem');
                        } else {
                            classes.push('ProductItemDimmed');
                        }
                    } else {
                        if (hasParent) {
                            classes.push("Categories");
                        } else {
                            classes.push("Campaign");
                        }
                    }

                    if (styleClass) {
                        classes.push(styleClass);
                    }

                    return ' ' + classes.join(" ") + ' ';
                };


                function hasBeenOrdered(item, orderHistory) {
                    const entry = orderHistory.find(e => e.productId == item.proxymeta?.targetId);
                    return entry && entry.orders.length;
                }

                function getFlagList(item, orderHistory) {
                    const entry = orderHistory.find(e => e.productId == item.proxymeta?.targetId);
                    const dates = entry ? entry.orders.map(e => e.orderDate) : [];
                    return dates.join(';');
                }

                function getFlagTextList(item, orderHistory) {
                    const entry = orderHistory.find(e => e.productId == item.proxymeta?.targetId);
                    const dates = entry ? entry.orders.map(e => 'Bestellt: ' + e.orderDate) : [];
                    return dates.join(';');
                }

                let schedule;

                if (schedules.length === 1) {

                    schedule = this.convertToMS(schedules[0]);
                    // -1 : past; 0: running; 1: future
                    const inScheduleInfo = schedule.startDate > now ? 1 : (schedule.endDate < now ? -1 : 0);
                    const styleClass = this.handleTimelineStyles(schedule);
                    const completion = this.getCompletion(schedule);

                    const pageUrl = isProxyEntry ? null : treeEntry.mpconfig?.campaignPage;
                    const imageFilename = isProxyEntry ?
                        treeEntry.image || '' :
                        treeEntry.image?.filename ?? '';

                    let entry = {
                        id: treeEntry.id,
                        Background: this.getRowStyle(folderBgColor, treeEntry.id, 'tree', hasParent, inScheduleInfo),
                        PlannerGanttBackground: this.getRowStyle(
                            folderBgColor, treeEntry.id, 'gantt', hasParent, inScheduleInfo
                        ),
                        PlannerGanttIcons: 1,
                        PlannerGanttClass: getClassNames(isProxyEntry, hasParent, completion, styleClass),
                        CLASS: getClassNames(isProxyEntry, hasParent, completion, styleClass),
                        PlannerGanttHeight: 11,
                        Start: schedule.startDate,
                        Ende: schedule.endDate,
                        COMP: completion,
                        Txt: this.$d(schedule.startDate, 'short') + ' - ' +
                            this.$d(schedule.endDate, 'short') + ' ' + schedule.name, // Text on timeline
                        name: treeEntry.name,
                        Task: getNameForTree(treeEntry.name, hasParent, folderColor, isProxyEntry), // text in tree
                        schedule: schedule,
                        proxymeta: this.getProxyMeta(treeEntry.proxymeta),
                        ImgFile: imageFilename,
                        Img: _renderImg(imageFilename || null),
                        anon: treeEntry.anon,
                        pageUrl: pageUrl,
                        fontColor: folderColor,
                        Expanded: this.expandedStates[treeEntry.id] || 0,
                    };

                    if (isProxyEntry) {
                        entry.Def = 'Data';
                        entry.Actions = this.actionsToHtmlString(
                            this.productActions,
                            treeEntry,
                            (folderColor ? ' mp_color_' + folderColor.substr(1) : null)
                        );
                        entry.ordered = hasBeenOrdered(treeEntry, orderHistory) ?
                            '<span class="orderMark yes"></span>' :
                            '';
                        entry.Flags = getFlagList(treeEntry, orderHistory);
                        entry.FlagTexts = getFlagTextList(treeEntry, orderHistory);
                    } else {
                        entry.Actions = this.actionsToHtmlString(
                            this.campaignActions,
                            treeEntry,
                            (folderColor ? ' mp_color_' + folderColor.substr(1) : null)
                        );
                        entry.Def = 'Run';
                    }

                    // vvvvvvvvvvvvvvvv create extra nodes for single entry vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
                    if (isProxyEntry && !isMultiTimeline) {
                        // pseudo node for proxy with one timeline not summarized in folder row
                        entry = {
                            Def: "Run",
                            id: 'H' + entry.id,
                            name: entry.name,
                            Task: entry.Task,
                            COMP: entry.COMP,
                            PlannerGanttClass: entry.PlannerGanttClass + ' HideBar ',
                            Background: entry.Background,
                            PlannerGanttBackground: entry.PlannerGanttBackground,
                            Items: [entry],
                            Actions: entry.Actions,
                            ordered: entry.ordered,
                            ImgFile: entry.ImgFile,
                            Img: entry.ImgFile,
                            Flags: entry.Flags,
                            FlagTexts: entry.FlagTexts,
                            Expanded: this.expandedStates['H' + entry.id] || 0,
                        };
                    } else if (!isProxyEntry && (!isMultiTimeline || isMultiTimeline && !treeEntry.children?.length)) {
                        const items = entry.Items ? JSON.parse(JSON.stringify(entry.Items)) : [];
                        delete entry.Items;
                        entry.Def = 'Data';
                        items.push(entry);
                        entry = {
                            Def: "Run",
                            id: 'H' + entry.id,
                            PlannerGanttClass: entry.PlannerGanttClass + ' HideBar ',
                            COMP: entry.COMP,
                            Background: entry.Background,
                            PlannerGanttBackground: entry.PlannerGanttBackground,
                            name: entry.name,
                            Task: entry.Task,
                            pageUrl: entry.pageUrl,
                            Items: items,
                            Actions: entry.Actions,
                            Expanded: this.expandedStates['H' + entry.id] || 0,
                        };
                    }
                    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

                    // vvvvvvvvvvvv create nodes for all children
                    if (treeEntry.children?.length) {
                        entry.Items = entry.Items || [];
                        let productTimelines = [];
                        for (const index in treeEntry.children) {
                            if (isMultiTimeline && 'proxymeta' in treeEntry.children[index]) {
                                productTimelines = productTimelines.concat(
                                    this.createTreeGridEntry(
                                        treeEntry.children[index], orderHistory, true, isMultiTimeline
                                    )
                                );
                            } else {
                                entry.Items = entry.Items.concat(
                                    this.createTreeGridEntry(
                                        treeEntry.children[index], orderHistory, true, isMultiTimeline
                                    )
                                );
                            }
                        }

                        if (!isProxyEntry && isMultiTimeline) {
                            let treeEntryCopy = JSON.parse(JSON.stringify(treeEntry));
                            treeEntryCopy.children = [];
                            treeEntryCopy.mpconfig.displayChildrenInOneRow = false;
                            const folderEntry = this.createTreeGridEntry(treeEntryCopy, orderHistory, true, false);
                            const folderEntryTimeline = folderEntry[0].Items;
                            folderEntryTimeline.push({
                                Def: 'Run',
                                id: 'P' + treeEntry.id,
                                Task: '',
                                Txt: 'ProductLane',
                                Flags: productTimelines.reduce((flags, timeline) => flags + ';' + timeline.Flags, ''),
                                FlagTexts: productTimelines.reduce(
                                    (FlagTexts, timeline) => FlagTexts + ';' + timeline.FlagTexts,
                                    ''
                                ),
                                Items: productTimelines,
                                Expanded: this.expandedStates['P' + treeEntry.id] || 0,
                            });

                            entry.Items = entry.Items.concat(folderEntryTimeline);
                        }
                    }
                    // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

                    return [entry];
                } else {
                    let timelines = [];
                    for (const scheduleIdx in schedules) {
                        schedule = schedules[scheduleIdx];

                        if (!isProxyEntry) {
                            throw new Error("Only proxies are allowed to have multiple schedules");
                        }

                        let node = JSON.parse(JSON.stringify(treeEntry));

                        node.proxymeta.schedules = [schedule];

                        timelines = timelines.concat(this.createTreeGridEntry(node, orderHistory, true, true));
                    }

                    if (isMultiTimeline) {
                        return timelines;
                    }

                    // extra node to group multiple product timelines
                    timelines.forEach((timeline, idx) => {
                        timeline.id += 'I' + idx;
                    });

                    const entry = {
                        Def: 'Run',
                        id: 'M' + treeEntry.id,
                        PlannerGanttClass: ' Product HideBar ',
                        name: treeEntry.name,
                        Task: getNameForTree(treeEntry.name, true, null, true),
                        Actions: this.actionsToHtmlString(
                            this.productActions,
                            treeEntry,
                            (folderColor ? ' mp_color_' + folderColor.substr(1) : null)
                        ),
                        ordered: hasBeenOrdered(treeEntry, orderHistory) ?
                            '<span class="orderMark yes"></span>' :
                            '',
                        Flags: getFlagList(treeEntry, orderHistory),
                        FlagTexts: getFlagTextList(treeEntry, orderHistory),
                        ImgFile: treeEntry.image ? treeEntry.image.filename : '',
                        Img: _renderImg(treeEntry.image ? treeEntry.image.filename : null),
                        Items: timelines,
                        Expanded: this.expandedStates['M' + treeEntry.id] || 0,
                    };

                    return [entry];
                }
            },

            actionsToHtmlString(actions, treeEntry, colorClass = null) {
                const isProxy = 'proxymeta' in treeEntry ? 1 : 0;
                const nodeId = isProxy ? this.getParentNode(treeEntry).id + '|' + treeEntry.id : treeEntry.id;

                return actions.map(
                    action => '<button class="mp_action ' + action.action + (colorClass ? colorClass : '') + '" ' +
                        'data-action="' + action.action + '" title="' + action.text + '" data-id="' + nodeId + '" ' +
                        'data-proxy="' + isProxy + '"></button>'
                ).join('');
            },

            OnRenderFinish: function () {
                this.grid.SetGanttLine(0, window.DateToString(Date.now()), null, null, null);
                this.zoomFit();
            },

            startGrid: function (alwaysReInit = false) {
                if (!alwaysReInit && this.grid) {
                    this.grid.Source.Data.Data.Body = [this.timelineData];
                    this.grid.ReloadBody();
                } else {
                    this.deleteGrid();

                    let config = this.getConfig();
                    config.Body = [this.timelineData];
                    const source = {Data: {Data: config}};

                    this.grid = window.TreeGrid(source, 'MarketingPlanner', {Component: this});

                    window.Grids.OnGetGanttHtml = this.OnGetGanttHtml;
                    window.Grids.OnRenderFinish = this.OnRenderFinish;
                    window.Grids.OnClick = this.OnClick;
                    window.Grids.OnGanttMenu = this.OnGanttMenu;
                    window.Grids.OnStartDragGantt = this.OnStartDragGantt;
                    window.Grids.OnEndDrag = this.OnEndDrag;
                    window.Grids.OnGetColor = this.overwriteFocusStyle;
                }
            },

            overwriteFocusStyle: function (grid, row, col/*, r, g, b*/) {
                if (col === 'Task' && col.Kind !== 'Header') {
                    const treeNode = this.getTreeNode(row);

                    if (!treeNode) {
                        return null;
                    }

                    const hasParent = this.getParentNode(treeNode).isMpRoot;
                    const userDefinedBgColor = treeNode.backgroundColor?.length ? treeNode.backgroundColor : null;

                    return hasParent && !userDefinedBgColor ? 'rgb(238,238,238)' : userDefinedBgColor;
                }

                // do not change what treegrid is doing
                return null;
            },

            getRowStyle: function (color, itemId, mode, parent, inSchedule) {
                let treeGridString = 'y10#1/5/2018~12/31/' + ((new Date()).getFullYear() + 5) + '#';
                let ganttDefault = !parent ? 'Cindy' : '';
                let treeDefault = !parent ? '238,238,238' : '';
                let style, styleStr;

                //delete all rules created before
                let stylesheets = document.getElementsByTagName('style');
                Array.from(stylesheets).forEach(function (stylesheet) {
                    if (stylesheet.innerHTML.indexOf('#TGMain0SideIcon1-0-' + itemId) >= 0) {
                        stylesheet.remove();
                    }
                });


                // fix hook on folder bar
                if (inSchedule === -1) {
                    style = document.createElement('style');
                    styleStr = '#TGMain0SideIcon1-0-' + itemId + '-Planner-0, #TGMain0SideIcon2-0-' + itemId +
                        '-Planner-0 {background-color: #7f8487;}';

                    style.innerHTML = styleStr;
                    document.getElementsByTagName('head')[0].appendChild(style);
                }

                if (color && color !== 'transparent') {
                    // let hexColor = Libraries.Converter.Color.convert(
                    //     Libraries.Colorpicker.detectColorType(color),
                    //     'hex',
                    //     Libraries.Colorpicker.seperateColorChannels(color)
                    // );
                    let hexColor = color.replace('#', '');

                    if (mode === 'gantt') {
                        let className = 'BG' + hexColor;

                        // create stylesheet on the fly
                        style = document.createElement('style');
                        styleStr = '.GWGanttBack' + className + ',' +
                            '#TGMain0SideIcon1-0-' + itemId + '-Planner-0:after,' +
                            '#TGMain0SideIcon2-0-' + itemId + '-Planner-0:after {' +
                            'background-color: #' + hexColor + ';}';

                        style.innerHTML = styleStr;
                        document.getElementsByTagName('head')[0].appendChild(style);

                        return treeGridString + className;
                    } else {
                        return '#' + hexColor;
                    }
                }

                if (mode === 'gantt') {
                    return treeGridString + ganttDefault;
                } else {
                    return treeDefault;
                }
            },

            handleTimelineStyles: function (schedule) {
                let styleClass = null;
                if (schedule.color || schedule.bgColorActive || schedule.bgColorInactive) {
                    styleClass = 'mp_timestyle_' +
                        (schedule.color?.substr(1) ?? '') + '_' +
                        (schedule.bgColorActive?.substr(1) ?? '') + '_' +
                        (schedule.bgColorInactive?.substr(1) ?? '');

                    this.timeLineClasses[styleClass] = {
                        color: schedule.color,
                        bgColorActive: schedule.bgColorActive,
                        bgColorInactive: schedule.bgColorInactive,
                    };
                }

                return styleClass;
            },

            OnGetGanttHtml: function (Grid, row) {
                function _format(date) {
                    return date.getDate() + '.' + (date.getMonth() + 1) + '.';
                }

                let txt = _format(new Date(row.Start)) + ' - ' + _format(new Date(row.Ende));
                return row.proxymeta ? txt : '';
            },

            executeClientAction: function (row, actionString) {
                this.expandedStates = this.grid ? this.readExpandedStates(this.grid.Rows) : {};

                switch (actionString) {
                    case 'addProduct':
                        this.addProduct(row);
                        break;
                    case 'editProduct':
                        this.editProduct(row);
                        break;
                    case 'deleteProduct':
                        this.deleteProduct(row);
                        break;
                    case 'addCampaign':
                        this.addCampaign(row);
                        break;
                    case 'editCampaign':
                        this.editCampaign(row);
                        break;
                    case 'deleteCampaign':
                        this.deleteCampaign(row);
                        break;
                }

                // without the following line text editing in FlyIn will fail
                window.setTimeout(() => this.grid.ActionBlur(), 0);
            },

            touchHandler: function (evt) {
                if (evt.target.classList.contains('mp_action') && evt.pointerType === 'touch') {
                    const nodeId = evt.target.dataset.id;
                    const isProxy = +evt.target.dataset.proxy > 0;
                    const rows = this.grid.Rows;
                    const possibleRows = Object.keys(rows).filter(key => {
                        if (isProxy) {
                            const idParts = nodeId.split('|');
                            return key.endsWith(idParts[0] + '$H' + idParts[1]);
                        }
                        return key.endsWith('$' + nodeId);
                    });
                    const row = rows[possibleRows[0]];
                    this.executeClientAction(row, evt.target?.dataset?.action);
                    console.log(arguments);
                }
            },

            OnClick: function (grid, row, col, layerX, layerY, evt) {
                const isArrow = evt.target &&
                    (evt.target.classList.contains('TWTree') || evt.target.classList.contains('TWTreeL')
                        || evt.target.classList.contains('TWTreeT'));
                const isHeader = row.Kind === 'Header';

                if (isArrow || isHeader) {
                    return;
                }

                if (row.anon) {
                    return;
                }

                if (col === 'Actions') {
                    this.executeClientAction(row, evt.target?.dataset?.action);

                    return;
                }

                if (['Task'].indexOf(col) > -1) {
                    if (row.PlannerGanttClass.indexOf('Campaign') > -1 ||
                        row.PlannerGanttClass.indexOf('Categories') > -1
                    ) {
                        let action = row.Expanded ? 'Collapse' : 'Expand';
                        grid[action](row);

                        return;
                    }
                }

                function _isInInterval(row) {
                    let now = Date.now();
                    return !(row.Start > now || row.Ende < now);
                }

                let treeNode = this.getTreeNode(row);
                let isProxy = row.Txt === 'ProductLane' && row.Def?.CDef === 'Run' ||
                    row.PlannerGanttClass.includes('Product');
                if (isProxy && treeNode?.mpconfig?.displayChildrenInOneRow) {
                    const srcEl = evt.target;
                    let node = null;
                    treeNode.children.forEach(child => {
                        child.proxymeta.schedules.forEach(schedule => {
                            const convertedSchedule = this.convertToMS(schedule);
                            let startDate = this.$d(convertedSchedule.startDate, 'short');
                            let endDate = this.$d(convertedSchedule.endDate, 'short');
                            const expectedText =
                                (startDate + " - " + endDate + " " + convertedSchedule.name).trim();
                            if (srcEl.innerText === expectedText) {
                                node = child;
                                return false;
                            }
                        });

                        if (node) {
                            return false;
                        }
                    });

                    if (node) {
                        treeNode = node;
                        isProxy = true;
                    } else {
                        console.error('Something went wrong during handling of collapsed folder child rows');
                        return false;
                    }
                }

                const type = isProxy ? 'product' : 'folder';
                const orderHistory = this.orderHistory
                    .find(e => e.productId == treeNode.proxymeta?.targetId)
                    ?.orders;

                let route = null;
                if (isProxy) {
                    if (_isInInterval(row)) {
                        route = {
                            name: 'Product',
                            params: {
                                pid: treeNode.proxymeta.targetId
                            }
                        };
                    }
                } else if (row.pageUrl) {
                    let splitted = row.pageUrl.split('/');
                    if (splitted.length === 2) {
                        const mapItem = splitted[0] in routeMap ? routeMap[splitted[0]] : routeMap['404'];
                        let routeParams = mapItem && mapItem.hasIdParams
                            ? {id: splitted[1]}
                            : {};
                        routeParams = Object.assign({}, mapItem.defaultParams, routeParams);

                        route = {
                            name: mapItem.name,
                            params: routeParams
                        };
                    }
                }

                let price = null;
                const product = this.getTreeNode(row);
                if (isProxy) {
                    this.displayQuantity = product.displayQuantity || 1;
                    price = PriceFormatter(treeNode.minBasePrice * this.displayQuantity, this.currency, this);
                }

                let imgFile = row.ImgFile;
                if (product && 'image' in product) {
                    if (typeof product.image === 'string' && product.image.length) {
                        imgFile = product.image;
                    } else if (typeof product.image === 'object' && product.image && 'filename' in product.image &&
                        product.image.filename && product.image.filename.length
                    ) {
                        imgFile = product.image.filename;
                    }
                }

                this.flyInData = {
                    name: treeNode.name,
                    quantityUnit: product.quantityUnit,
                    description: stripHtmlTags(isProxy ? treeNode.shortDescription : treeNode.description),
                    imgFile: imgFile,
                    price: price,
                    orderHistory: orderHistory,
                    schedules: (isProxy ? treeNode.proxymeta.schedules : treeNode.schedules).map(schedule => {
                        schedule.startDate = ndxDateConvert(schedule.startDate);
                        schedule.endDate = ndxDateConvert(schedule.endDate);

                        return schedule;
                    }),
                    route: route,
                    type: type
                };
            },

            OnGanttMenu: function () {
                return true;
            },

            OnStartDragGantt: function () {
                return true;
            },

            //type - 0 - cannot drop, 1 - above torow, 2 - to the end of children of torow, 3 - below torow
            OnEndDrag: function (grid, row, toGrid, toRow, type) {
                if (type === 0) {
                    return false;
                }

                const FOLDER = 'SymAclFolderExtension';
                const PROXY = 'SymAclProxy';

                const rowTreeNode = this.getTreeNode(row);
                const toRowTreeNode = this.getTreeNode(toRow);
                const toRowTreeNodeParent = this.getParentNode(toRowTreeNode);

                const movedEl = {
                    id: 'proxymeta' in rowTreeNode ? rowTreeNode.proxymeta.id : rowTreeNode.id,
                    type: 'proxymeta' in rowTreeNode ? PROXY : FOLDER
                };

                let moveType, newParentId, destinationEl;

                switch (type) {
                    case 1:
                        moveType = 'before';
                        newParentId = toRowTreeNodeParent.id || this.root;
                        destinationEl = {
                            id: 'proxymeta' in toRowTreeNode ? toRowTreeNode.proxymeta.id : toRowTreeNode.id,
                            type: 'proxymeta' in toRowTreeNode ? PROXY : FOLDER
                        };
                        break;
                    case 2:
                        moveType = 'after';
                        newParentId = toRowTreeNode.id;
                        destinationEl = null;
                        break;
                    case 3:
                        moveType = 'after';
                        newParentId = toRowTreeNodeParent.id || this.root;
                        destinationEl = {
                            id: 'proxymeta' in toRowTreeNode ? toRowTreeNode.proxymeta.id : toRowTreeNode.id,
                            type: 'proxymeta' in toRowTreeNode ? PROXY : FOLDER
                        };
                        break;
                }

                if (movedEl.type === PROXY && 'proxymeta' in toRowTreeNode && type === 2) {
                    // can not place a product under another product
                    return false;
                }

                if (destinationEl === null || movedEl.type === destinationEl.type) {
                    this.$store.dispatch('marketingPlanning/moveMember', {
                        newParentId,
                        movedEl,
                        destinationEl,
                        moveType
                    }).then(() => {
                        this.load(true);
                    }).catch(() => {
                        this.reOrderingError = true;
                    });

                    return true;
                }

                if (movedEl.type === PROXY && destinationEl.type === FOLDER && moveType === 'after') {
                    this.$store.dispatch('marketingPlanning/moveMember', {
                        newParentId,
                        movedEl,
                        destinationEl: null,
                        moveType
                    }).then(() => {
                        this.load(true);
                    }).catch(() => {
                        this.reOrderingError = true;
                    });

                    return true;
                }

                // not allowed dragging operation => reset chart
                return false;
            },

            showToday: function () {
                let slip = this.zoomLevel === 'weeks' ? 30 : (this.zoomLevel === 'months' ? 60 : 10);
                if (this.grid) {
                    this.grid.ScrollToDate(window.DateToString(Date.now() - slip * 24 * 60 * 60 * 1000), 'left');
                    this.grid.RefreshGantt(5);
                }
            },
            zoomDays: function () {
                this.zoomLevel = 'days';
                if (this.grid) {
                    this.grid.ChangeZoom('Tage');
                    this.grid.RefreshGantt(5);
                }
            },
            zoomWeeks: function () {
                this.zoomLevel = 'weeks';
                if (this.grid) {
                    this.grid.ChangeZoom('Wochen');
                    this.grid.RefreshGantt(5);
                }
            },
            zoomMonths: function () {
                this.zoomLevel = 'months';
                if (this.grid) {
                    this.grid.ChangeZoom('Monate');
                    this.grid.RefreshGantt(5);
                }
            },
            zoomFit: function () {
                this.zoomLevel = 'fit';
                if (this.grid) {
                    this.grid.ActionZoomFit();
                }
            },
            expand: function () {
                if (this.grid) {
                    for (let rowId in this.grid.Rows) {
                        this.grid.Expand(this.grid.Rows[rowId]);
                    }
                }
                this.expanded = true;
            },
            collapse: function () {
                if (this.grid) {
                    for (let rowId in this.grid.Rows) {
                        this.grid.Collapse(this.grid.Rows[rowId]);
                    }
                }
                this.expanded = false;
            },

            resetTreeGridSize() {
                if (this.resizeThrottle) {
                    window.clearTimeout(this.resizeThrottle);
                }

                this.resizeThrottle = window.setTimeout(function () {
                    if (this.grid) {
                        if (this.zoomLevel === 'fit') {
                            this.grid.ActionZoomFit();
                        } else {
                            this.grid.RefreshGantt(5);
                        }
                    }
                    console.log('resized treeGrid - method: ' + this.zoomLevel);
                }.bind(this), 300);
            },

            addCampaign(row) {
                let rootNodeId;
                if (typeof row === 'object') {
                    const treeEntry = this.getTreeNode(row);
                    rootNodeId = treeEntry.id;
                } else {
                    rootNodeId = row;
                }
                const now = new Date();
                this.editedCampaign = {
                    id: null,
                    parent: rootNodeId,
                    name: '',
                    description: '',
                    imageCacheId: null,
                    imageOrigName: null,
                    backgroundColor: null,
                    iconColor: null,
                    mpconfig: {
                        id: null,
                        campaignPage: null,
                        displayWithoutChild: true,
                        displayChildrenInOneRow: false,
                    },
                    schedules: [
                        {
                            startDate: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
                            endDate: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 7),
                            color: null,
                            name: '',
                            bgColorActive: null,
                            bgColorInactive: null,
                        }
                    ]
                };
            },
            editCampaign(row) {
                const treeNode = this.getTreeNode(row);
                this.editedCampaign = {
                    id: treeNode.id,
                    parent: row.parentNode.id || this.root,
                    name: treeNode.name,
                    description: treeNode.description,
                    imgFile: row.ImgFile,
                    imageCacheId: null,
                    imageOrigName: null,
                    backgroundColor: treeNode.backgroundColor,
                    iconColor: treeNode.iconColor,
                    mpconfig: {
                        id: treeNode.mpconfig?.id,
                        campaignPage: treeNode.mpconfig?.campaignPage,
                        displayWithoutChild: true,
                        displayChildrenInOneRow: treeNode.mpconfig?.displayChildrenInOneRow ?? false,
                        folderId: treeNode.mpConfig?.folderId,
                    },
                    schedules: treeNode.schedules.map(schedule => {
                        let tmp = this.convertToMS(schedule);
                        tmp.startDate = new Date(tmp.startDate);
                        tmp.endDate = new Date(tmp.endDate);

                        return tmp;
                    })
                };
            },
            onCampaignEditorClose(reload) {
                this.editedCampaign = null;
                if (reload) {
                    this.load();
                }
            },
            deleteCampaign(row) {
                this.campaignDecide = row;
            },
            doDeleteCampaign() {
                const treeNode = this.getTreeNode(this.campaignDecide);
                this.$store.dispatch('marketingPlanning/deleteMarketingplanning', {folderId: treeNode.id})
                    .finally(() => {
                        this.campaignDecide = null;
                        this.load();
                    });
            },
            addProduct(row) {
                const treeEntry = this.getTreeNode(row);
                this.productPickerParentFolder = treeEntry.id;
                this.showProductPicker = true;
            },
            editProduct(row) {
                const treeNode = this.getTreeNode(row);
                this.editedProduct = {
                    id: treeNode.proxymeta.id,
                    name: row.name,
                    oid: {id: treeNode.proxymeta.id, type: 'SymAclProxy'},
                    schedules: treeNode.proxymeta.schedules.map(schedule => {
                        let tmp = this.convertToMS(schedule);
                        tmp.startDate = new Date(tmp.startDate);
                        tmp.endDate = new Date(tmp.endDate);

                        return tmp;
                    })
                };
            },
            onProductEditorClose(reload) {
                this.editedProduct = null;
                if (reload) {
                    this.load();
                }
            },
            onPickProducts(products) {
                this.showProductPicker = false;

                this.$store.dispatch('marketingPlanning/addProducts', {
                    folderId: this.productPickerParentFolder,
                    productIds: products.map(product => product.id)
                }).then(() => {
                    this.load();
                }).catch((error) => {
                    console.log(error);
                });
            },
            deleteProduct(row) {
                this.productDecide = row;
            },
            doDeleteProduct() {
                const treeNode = this.getTreeNode(this.productDecide);
                this.$store.dispatch('marketingPlanning/deleteProxy', {
                    proxyId: treeNode.proxymeta.id
                }).finally(() => {
                    this.productDecide = null;
                    this.load();
                });
            },
            switchRoot(newRoot) {
                this.$store.dispatch('marketingPlanning/setRootId', newRoot);
            },

            readExpandedStates(rows) {
                let states = {};
                for (const key in rows) {
                    const node = this.getTreeNode(rows[key]);
                    // filter for SymAclFolders in tree
                    if (node && !('proxymeta' in node)) {
                        const stateKey = key.split('$').at(-1);

                        if (!(stateKey in states)) {
                            states[stateKey] = rows[key].Expanded;
                        }
                        if (states[key]) {
                            states = Object.assign(states, this.readExpandedStates(rows[key].Rows));
                        }
                    }
                }

                return states;
            }
        }
    };
</script>
