<template>
    <div class="dataItemEditor-wrapper" v-if="item">
        <NdxInput
            v-if="['text', 'email', 'input', 'imageUrl'].indexOf(formType) > -1"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />
        <NdxTextarea
            v-else-if="formType === 'textarea'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />
        <NdxSelect
            v-else-if="formType === 'select'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :options="selectOptions"
            :invalid="!isValueValid"
            :text-wrap="true"
            @update:modelValue="updateValue"
        />
        <NdxMultiSelect
            v-else-if="formType === 'selectMulti'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :options="selectOptions"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />
        <NdxImgUpload
            v-else-if="formType === 'image'"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />
        <NdxDatePicker
            v-else-if="formType === 'date'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />
        <NdxRadio
            v-else-if="formType === 'choose'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :options="radioOptions"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />
        <NdxColorPicker
            v-else-if="formType === 'colorWeb'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            @update:modelValue="updateValue"
            null-color="#ffffff"
            :invalid="!isValueValid"
            :clear-button="!isAttributeDefaultValue"
        />
        <NdxColorPickerPrint
            v-else-if="formType === 'colorPrint'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            disable-rgb
            @update:modelValue="updateValue"
            :invalid="!isValueValid"
            :clear-button="!isAttributeDefaultValue"
        />
        <NdxCheckbox
            v-else-if="formType === 'checkbox'"
            variant="secondary"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            @update:modelValue="updateValue"
        />
        <NdxFileUpload
            v-else-if="formType === 'file'"
            variant="minimal"
            :label="label"
            :readonly="isReadOnly"
            :model-value="value"
            :invalid="!isValueValid"
            @update:modelValue="updateValue"
        />

        <div v-else class="ndx-form-element">
            Unknown type: {{ formType }}
        </div>

        <div
            v-if="errorMsg && errorMsg.length > 0"
            class="errorMessage"
        >{{ errorMsg }}</div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxInput from "../../library/formElements/NdxInput";
    import NdxTextarea from "../../library/formElements/NdxTextarea";
    import NdxSelect from "../../library/formElements/NdxSelect";
    import NdxImgUpload from "../../library/formElements/NdxImgUpload";
    import DataItemMixin from "./DataItemMixin";
    import NdxMultiSelect from "../../library/formElements/NdxMultiSelect";
    import NdxDatePicker from "../../library/formElements/NdxDatePicker";
    import NdxRadio from "../../library/formElements/NdxRadio";
    import NdxColorPicker from "../../library/formElements/NdxColorPicker";
    import NdxColorPickerPrint from "../../library/formElements/NdxColorPickerPrint";
    import NdxCheckbox from "../../library/formElements/NdxCheckbox";
    import NdxFileUpload from "../../library/formElements/NdxFileUpload";
    import { urlWithProtocol } from "@utilities/ndxValidationRules";

    export default {
        name: 'DataItemEditor',
        components: {
            NdxFileUpload,
            NdxCheckbox, NdxRadio, NdxDatePicker, NdxMultiSelect, NdxImgUpload, NdxSelect, NdxTextarea, NdxInput,
            NdxColorPicker, NdxColorPickerPrint
        },
        mixins: [DataItemMixin],
        emits: ['update:modelValue', 'update:isValid'],
        props: {
            modelValue: {
                type: Object
            },
            item: {
                type: Object
            },
            type: {
                type: String,
                validator(value) {
                    return ['user', 'userAttribute', 'client', 'clientAttribute', 'checkoutAttribute'].includes(value);
                }
            },
            showLabel: {
                type: Boolean
            },
            isValid: {
                type: Boolean
            }
        },
        computed: {
            ...mapState({
                isoLanguageCodes: state => state.country.isoLanguageCodes
            }),
            ...mapGetters('session', [
                'getLanguageOptions'
            ]),
            localModel: {
                get() {
                    return this.modelValue;
                },
                set(newVal) {
                    this.$emit('update:modelValue', newVal);
                }
            },
            radioOptions() {
                if (this.item.uiElement === 'gender') {
                    return this.genderOptions;
                }
                return [];
            },
            selectOptions() {
                if (this.isAttribute) {
                    return this.item.attributeDef.displayOptions.map((item) => {
                        return {value: item.key, text: this.keyToUserText(item.key)};
                    });
                }

                if ('uiElement' in this.item && this.item.uiElement === 'locale') {
                    let options = [];
                    for (let lang of this.getLanguageOptions) {
                        options.push({
                            value: lang.value,
                            text: this.isoLanguageCodes ? this.isoLanguageCodes[lang.code] : lang.code
                        });
                    }
                    return options;
                }

                return [];
            },
            isReadOnly() {
                return (['userAttribute', 'clientAttribute'].includes(this.type) &&
                        this.item.attributeDef.visibleInStoreFront === 1) ||
                    (this.type === 'checkoutAttribute' && this.item.readonly) ||
                    ('editState' in this.item && this.item.editState === 1);
            },
            isAttributeDefaultValue() {
                return this.isAttribute && this.value == this.item.attributeDef.defaultValue;
            },
            formType() {
                if (this.isAttribute) {
                    if (this.item.attributeDef.valueFormatType === 'text') {
                        return 'textarea';
                    }
                    return this.item.attributeDef.valueFormatType;
                }
                return this.item.type;
            },
            label() {
                if (this.formType === 'checkbox') {
                    const attrDef = this.item.attributeDef;
                    return attrDef.displayOptions.length && attrDef.displayOptions[0].value.length
                        ? attrDef.displayOptions[0].value
                        : attrDef.displayName;
                }

                if (!this.showLabel) {
                    return '';
                }
                return this.item.userDefinedName && this.item.userDefinedName.length > 0
                    ? this.item.userDefinedName
                    : this.$t('contact.' + this.item.uiElement);
            },
            isValueValid() {
                if (!this.isReadOnly && this.formType === 'imageUrl' && this.value && this.value.length > 0) {
                    return urlWithProtocol(this.value);
                }

                if (!('editState' in this.item) || this.item.editState < 3) {
                    return true;
                }

                if (this.formType === 'checkbox') {
                    return this.value !== null;
                }
                if (this.formType === 'image') {
                    return this.value && this.value.id && +this.value.id > 0;
                }
                return this.value && this.value.length > 0;
            },
            errorMsg() {
                if (!this.isValueValid && this.formType === 'imageUrl') {
                    return this.$t('message.urlWithProtocolError');
                }
                return '';
            }
        },
        watch: {
            isValueValid: {
                immediate: true,
                handler: 'emitValidation'
            }
        },
        mounted() {
            if ('uiElement' in this.item && this.item.uiElement === 'locale') {
                this.$store.dispatch('country/getIsoLanguageCodes');
            }
        },
        methods: {
            emitValidation() {
                this.$emit('update:isValid', this.isValueValid);
            },
            updateValue(value) {
                if (this.formType === 'file') {
                    if (value === null && this.value && 'fileId' in this.value) {
                        value = {
                            deleteFile: true
                        };
                    }
                }
                if (this.formType === 'checkbox') {
                    value = value ? 1 : 0;
                }

                if (this.formType === 'imageUrl' && value.length === 0) {
                    value = null;
                }

                if (['user', 'client'].indexOf(this.type) > -1) {
                    let path = this.getPath(this.item.uiElement);
                    let obj = this.localModel;
                    if (path.split('.').length > 1) {
                        let _path = path.split('.');
                        path = _path.pop();
                        obj = this.resolve(_path.join('.'), obj);
                    }
                    obj[path] = value;
                } else if (['userAttribute', 'clientAttribute'].includes(this.type)) {
                    if (this.item.key in this.localModel.attributes) {
                        if(this.item.attributeDef.valueFormatType === 'image') {
                            this.localModel.attributes[this.item.key].image = value;
                        }
                        this.localModel.attributes[this.item.key].value = value;
                    }
                } else if (this.type === 'checkoutAttribute') {
                    this.localModel.value = value;

                    this.$emit('update:modelValue', this.localModel);
                }
            }
        }
    };
</script>
