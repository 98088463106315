<template>
    <img v-if="variant === 'fo'" :src="url" />
    <iframe v-if="variant === 'html'" :src="url" :title="$t('label.preview')"/>
</template>

<script>

export default {
    name: 'ContentObjectPreview',
    props: {
        item: {
            type: Object,
            required: true
        },
        template: {
            type: String,
            required: true
        },
        variant: {
            type: String,
            required: true,
            validator: function (value) {
                return ['fo', 'html'].indexOf(value) !== -1;
            }
        }
    },
    computed: {
        url() {
            const tpl = encodeURI(this.template);
            return '/cos/' + this.item.id + '/' + tpl + (this.variant === 'fo' ? '/print' : '/web');
        }
    }
};
</script>

<style scoped>
iframe {
    width: 100%;
    height: 240px;
}
</style>
