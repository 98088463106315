<template>
    <div
        class="bundleTile card variant-card ndx-product-card"
        :class="{
            'highlighted': active
        }"
    >
        <div class="image-wrapper">
            <NdxImage
                class="card-img-top text-center"
                :src="bundleProduct.image"
                :style="'background-color: ' + (bundleProduct.imageBgColor || defaultProductImageBgColor || '#f2f2f2')"
                size="xxl"
            />
        </div>
        <div class="flex-grow-0 card-body">
            <div class="card-title">{{ bundleProduct.name }}</div>
            <div
                v-if="type !== 'order'"
                class="card-text"
                v-html="stripHtmlTags(bundleProduct.listingDescription)">
            </div>
            <div class="price">{{ formatPrice(bundleProduct.priceNet) }}</div>
        </div>
    </div>
</template>

<script>
    import NdxImage from "../../../library/NdxImage";
    import { PriceFormatter } from "../../../../plugins/formatter";
    import { mapGetters } from "vuex";
    import { stripHtmlTags } from "@utilities/ndxText";

    export default {
        name: 'BundleTile',
        components: {NdxImage},
        props: {
            bundle: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                validator: function (value) {
                    return ['order', 'basket'].indexOf(value) !== -1;
                }
            },
            active: Boolean
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            bundleProduct() {
                return this.bundle.bundleProduct ? this.bundle.bundleProduct : {
                    name: this.bundle.bundleId,
                    image: '',
                    imageBgColor: null,
                    listingDescription: '',
                    priceNet: 0
                };
            }
        },
        methods: {
            stripHtmlTags,
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            }
        }
    };
</script>
