<template>
    <NdxListing
        store-action="workflowInstanceSnapshot/filterBy"
        :filter-criteria="filterCriteria"
        :order-by="{sortBy: 'instanceEndedAt', sortAsc: false}"
        @items-changed="updateItems"
    >
        <template #emptyList>
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noSearchHits')"
            />
        </template>

        <template #default>
            <div class="workflowlist-item-container">
                <WorkflowInstanceItem
                    v-for="item in list"
                    :key="item.id"
                    :item="item"
                />
            </div>
        </template>
    </NdxListing>
</template>

<script>
    import { ndxDateFormatYmd } from "@utilities/ndxDate";
    import NdxNotification from "../library/NdxNotification.vue";
    import NdxListing from "../library/NdxListing.vue";
    import WorkflowInstanceItem from "./WorkflowInstanceItem.vue";

    export default {
        name: 'WorkflowInstancesCompleted',
        components: {NdxListing, WorkflowInstanceItem, NdxNotification},
        props: {
            filterData: {
                type: Object
            }
        },
        data() {
            return {
                list: [],
            };
        },
        computed: {
            filterCriteria() {
                const nowDate = new Date();
                const nowString = ndxDateFormatYmd(nowDate, '-', true);

                return {
                    __noCount : true,
                    instanceCreatedBy: this.$store.getters['user/currentUserId'],
                    dateStart: this.filterData.createdAfterCamundaFormat,
                    dateFinish: nowString,
                    searchText: this.filterData.searchText,
                };
            },
        },
        methods: {
            updateItems(items) {
                this.list = items;
            }
        }
    };
</script>
