<template>
    <NdxPageHeader
        :active-route-callback="breadcrumbs.length > 0 ? goToProductMainPage : null"
        :breadcrumbs="breadcrumbs"
        :nav-name="navName"
        v-model:view-mode="viewMode"
        view-mode-storage-key="productsViewMode"
        hide-filter
    />

    <template v-if="categories.length">
        <div v-if="showProductHeader" class="product-header">
            <div class="category-name">{{ currentCategory.name }}</div>
            <div
                class="category-description"
                v-html="stripHtmlTags(currentCategory.description, ['br'])"
            ></div>
        </div>

        <div
            v-if="showCategories"
            class="card-container"
            :class="{['viewmode-' + viewMode]: true}"
        >
            <NdxCategoryCard
                v-for="category in categories"
                :key="category.id"
                :card-img="category.image"
                :title="category.name"
                :description="category.description"
                :variant="viewMode"
                :background-color="category.backgroundColor"
                :foreground-color="category.iconColor"
                @card-click="() => gotoCategoryPage(category)"
            />
        </div>
    </template>

    <NdxListing
        v-if="categoryId"
        store-action="products/getProducts"
        :filter-criteria="filterCriteria"
        :has-additional-content="showCategories"
        @items-changed="updateItems"
    >
        <div class="card-container" :class="{['viewmode-' + viewMode]: true}">
            <NdxProductCard
                v-for="product in products"
                :key="product.id"
                :card-img="product.image"
                :card-img-background-color="product.imageBgColor"
                :title="product.name"
                :tags="getTags(product.id)"
                :description="product.listingDescription"
                :price="product.minBasePrice"
                :price-tag="product.priceTag"
                :list-price="product.listPrice"
                :currency="currency"
                :quantity="product.displayQuantity || 1"
                :quantityUnit="product.quantityUnit"
                :label="product.label"
                :actions="getCardActions(product)"
                :variant="viewMode"
                :bundleParent="product.bundleParent"
                @card-click="() => gotoDetailPage(product)"
            />
        </div>
    </NdxListing>
    <div class="notificationWrapper">
        <NdxNotification
            variant="info"
            :duration="5"
            v-model="addToCartSucceeded"
            :message="$t('message.addToCartSucceeded', {url: getBasketUrl()})"
        />
        <NdxNotification
            variant="success"
            :duration="5"
            v-model="addToWatchlistSucceeded"
            :message="$t('message.addToWatchlistSucceeded', {url: getWatchlistUrl()})"
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { stripHtmlTags } from '@utilities/ndxText';
    import NdxCategoryCard from '../library/NdxCategoryCard';
    import NdxProductCard from '../library/NdxProductCard';
    import NdxNotification from "../library/NdxNotification";
    import TagsForProduct from "../../mixins/TagsForProduct";
    import NdxListing from "../library/NdxListing.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "ProductList",
        components: {
            NdxPageHeader, NdxListing, NdxNotification, NdxProductCard, NdxCategoryCard
        },
        mixins: [TagsForProduct],
        data() {
            return {
                products: [],
                currentCategory: {},
                categories: [],
                categoryId: null,
                viewMode: 'card',
                addToCartSucceeded: false,
                addToWatchlistSucceeded: false
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            ...mapGetters('productCategories', [
                'getParentList'
            ]),
            showProductHeader() {
                return this.currentRealCategory &&
                    this.currentRealCategory.description &&
                    this.currentRealCategory.description.length > 0;
            },
            showCategories() {
                let showCatSettings = false;
                if (this.currentWgc) {
                    showCatSettings = this.currentWgc.config.productListShowCategoryTiles;
                }
                const productsEmpty = this.products.length === 0;
                return (showCatSettings || productsEmpty) && this.categories.length > 0;
            },
            filterCriteria() {
                return {
                    categoryId: this.categoryId,
                };
            },
            currentWgc() {
                return this.$store.getters['shop/getWgc'](+this.$route.params.id);
            },
            navName() {
                if (this.currentRealCategory) {
                    return this.currentCategory.name;
                }
                return this.currentWgc ? this.currentWgc.label : '';
            },
            breadcrumbs() {
                let list = [];

                const parentList = this.getParentList(this.categoryId, this.rootCategoryId);
                if (parentList.length > 2) {
                    list.push({
                       label: '…'
                    });
                }
                for (let item of parentList.slice(-2)) {
                    list.push({
                        label: item.name,
                        click: item.id === this.categoryId
                            ? null
                            : () => {
                                this.$router.push({
                                    name: this.currentWgc.route.name,
                                    params: {
                                        ...this.currentWgc.route.params,
                                        categoryId: item.id
                                    }
                                });
                            }
                    });
                }

                return list;
            },
            currentRealCategory() {
                if (this.currentCategory && this.currentCategory.id !== this.rootCategoryId) {
                    return this.currentCategory;
                }
                return null;
            },
            rootCategoryId() {
                return this.currentWgc?.config?.productCategoryRootId ||
                    this.$store.getters['shop/productRootFolderId'];
            }
        },
        watch: {
            $route(to) {
                if (to.path.includes('product')) {
                    this.categoryId = +to.params.categoryId || this.rootCategoryId;
                    this.products = [];
                    this.getCatgoriesList();
                }
            },
            viewMode() {
                this.loadTags();
            },
            categoryId() {
                this.$store.dispatch('productCategories/getParentList', {
                    categoryId: this.categoryId,
                    rootCategoryId: this.rootCategoryId
                });
            }
        },
        mounted() {
            this.categoryId = +this.$route.params.categoryId || this.rootCategoryId;
            this.getCatgoriesList();
        },
        methods: {
            stripHtmlTags,
            getBasketUrl() {
                const data = this.$store.getters['shop/getRouteByType']('basket');
                return data ? this.$router.resolve(data).href : '';
            },
            getWatchlistUrl() {
                const data = this.$store.getters['shop/getRouteByType']('watchlist');
                return data ? this.$router.resolve(data).href : '';
            },
            updateItems(items) {
                this.products = items;
                this.loadTags();
            },
            loadTags() {
                if (this.viewMode === 'list') {
                    this.updateTags(this.products);
                }
            },
            getCardActions(product) {
                return this.$store.getters['products/cardActions'](
                    product,
                    this.favorizeProduct,
                    this.addToBasket,
                    this.$t,
                    this.$d,
                    this.$router
                );
            },
            favorizeProduct(product, newFavoriteState) {
                this.$store.dispatch('products/toggleFavorite', {
                    pciId: product.id,
                    favorize: newFavoriteState
                }).then(() => this.products.forEach(prod => {
                    if (product.id === prod.id) {
                        prod.isFavorite = !prod.isFavorite;
                    }
                }));
            },
            gotoDetailPage(product) {
                let routeName = 'Product';
                let params = {
                    pid: product.id
                };
                if (this.$route.params && 'id' in this.$route.params) {
                    params.id = this.$route.params.id;
                    routeName = 'Products';
                }
                if (this.$route.params && 'categoryId' in this.$route.params) {
                    params.categoryId = this.$route.params.categoryId;
                    routeName = 'ProductsInCategory';
                }
                this.$router.push({name: routeName, params: params});
            },
            getCatgoriesList() {
                this.$store.dispatch('productCategories/getSubtree', {
                    rootId: this.categoryId,
                    depth: 1
                }).then((subtree) => {
                    if (Array.isArray(subtree) && !subtree.length) {
                        this.categories = [];
                        this.currentCategory = {};
                    } else {
                        this.currentCategory = JSON.parse(JSON.stringify(subtree));
                        delete this.currentCategory.children;
                        this.categories = subtree.children;
                    }
                });
            },
            gotoCategoryPage(category) {
                this.$router.push({
                    name: 'ProductsInCategory',
                    params: {
                        id: this.$route.params.id,
                        categoryId: category.id,
                        pid: ''
                    }
                });
            },
            addToBasket(product) {
                this.$store.dispatch('basket/addToBasket', {
                    productId: product.id,
                    quantity: 1
                }).then((result) => {
                    if (result.watchItems.find(item => item.id === result.orderItemId)) {
                        this.addToWatchlistSucceeded = true;
                    } else {
                        this.addToCartSucceeded = true;
                    }
                });
            },
            goToProductMainPage() {
                this.$router.push(this.currentWgc.route);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }

    .product-header {
        margin-bottom: 32px;
        padding-top: 15px;

        .category-name {
            @extend %font-h2;
        }

        .category-description {
            @extend %font-body2;

            margin-top: 8px;

            &:empty {
                display: none;
            }
        }
    }

    .card-container + .card-container {
        margin-top: 32px;
    }
</style>
