import { loadLanguageAsync } from '../../plugins/i18n';
import userApi from '../../api/user';
import sessionApi from '../../api/session';
import shopApi from '../../api/shop';
import { throbberRules } from '@utilities/throbberRules';
import { pSBC } from "@utilities/pSBC";

let refreshTimer = null;
// time between response of srefresh call and next srefresh call in milliseconds
const refreshInterval = 5 * 60 * 1000; // 5 minutes

function setBrandColor (brandColor, fallbackColor = '#000') {
    let primary = fallbackColor;
    const s = new Option().style;
    s.color = brandColor;

    if (s.color !== '') {
        primary = brandColor;
    }
    const primaryLight = pSBC(0.86, primary, '#FFFFFF');
    document.querySelector(':root').style.setProperty('--bs-primary', primary);
    document.querySelector(':root').style.setProperty('--bs-primary-light', primaryLight);
}
function setWindowTitle(title) {
    if (title?.length) {
        document.title = title;
    }
}
function setFavicon(favicon) {
    if (favicon?.length) {
        let link = document.querySelector("link[rel='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        let link2 = document.querySelector("link[rel='shortcut icon']");

        if (!link2) {
            link2 = document.createElement('link');
            link2.rel = 'shortcut icon';
            document.getElementsByTagName('head')[0].appendChild(link2);
        }

        link.href = favicon;
        link2.href = favicon;
    }
}
function setHomescreenIcon(homescreenIcon) {
    if (homescreenIcon?.length) {
        let link = document.querySelector("link[rel='apple-touch-icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'apple-touch-icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        let link2 = document.querySelector("link[rel='apple-touch-startup-image']");

        if (!link2) {
            link2 = document.createElement('link');
            link2.rel = 'apple-touch-startup-image';
            document.getElementsByTagName('head')[0].appendChild(link2);
        }

        link.href = homescreenIcon;
        link2.href = homescreenIcon;
    }
}
function setBodyOverlay(counter) {
    if (counter > 0) {
        document.body.style.setProperty('overflow', 'hidden');
    } else {
        document.body.style.removeProperty('overflow');
    }
}

// initial state
const state = () => ({
    maintenance: false,
    loginError: null,
    passwordRecoverError: null,
    passwordChangeRequired: false,
    secret: null,
    initialized: false,
    subdomain: null,
    requestCounter: {},
    websocketData: null,
    shopId: null,
    authConfigs: [],
    redirects: null,
    showOptinPage: false,
    activeLanguage: null,
    languages: [],
    countryCode: null,
    authId: null,
    overlayCounter: 0
});

// getters
const getters = {
    authIdIsNdx: function (state) {
        return state.authId === 'ndx';
    },
    isLoggedIn: function (state, getters, rootState) {
        const user = rootState.user.user;
        return !!(user?.username && state.secret);
    },
    getShopId: function (state) {
        return state.shopId;
    },
    getSecret: function (state) {
        return state.secret;
    },
    isInitialized: function (state) {
        return state.initialized;
    },
    getSubdomain: function (state) {
        return state.subdomain;
    },
    getRedirects: function (state) {
        return state.redirects;
    },
    isPasswordChangeRequired: function (state) {
        return state.passwordChangeRequired;
    },
    showOptinPage: function (state) {
        return state.showOptinPage;
    },
    isRequestRunning: function (state) {
        return function (throbberLevel = throbberRules.FULLSCREEN) {
            let counter = 0;
            for (const rule in state.requestCounter) {
                if (rule === throbberLevel) {
                    return counter ? false : state.requestCounter[rule] > 0;
                }
                counter += state.requestCounter[rule];
            }

            return false;
        };
    },
    getWebSocketUrl: (state) => {
        if (!state.websocketData) {
            return null;
        }

        let url = (state.websocketData.ip) ? state.websocketData.ip : '/';
        if (url.slice(-1) !== '/') {
            url += '/';
        }

        return url;
    },
    getLanguageOptions: (state) => {
        let ret = [];
        for (let lang of state.languages) {
            ret.push({
                value: lang + '_' + state.countryCode,
                code: lang
            });
        }
        return ret;
    },
    getActiveLanguage: (state) => {
        return state.activeLanguage;
    },
    getLoginErrorMsg(state) {
        /**
         * @param {function} $t  Callable to translate message in user language
         * @param {function} $te Callable to test that message could be translated into user language
         */
        return ($t, $te) => {
            if (state.loginError !== null && 'data' in state.loginError && 'class' in state.loginError.data
                && $te('startMsg.' + state.loginError.data['class']) > -1
            ) {
                const errorCode = state.loginError.code;
                return $t('startMsg.' + state.loginError.data['class']) +
                    (errorCode ? ` (Code: ${errorCode})` : '');
            } else if (state.loginError !== null && 'message' in state.loginError
                && state.loginError.message.length > 0
            ) {
                return state.loginError.message;
            }
            return '';
        };
    },
    isMaintenanceActive(state) {
        return state.maintenance;
    }
};

// actions
const actions = {
    login ({ commit }, data) {
        commit('SET_LOGIN_ERROR', null);

        return new Promise((resolve, reject) => {
            userApi.login(
                [data.username, data.password]
            ).then(() => {
                this.dispatch('session/refreshState').then((hasClientSwitch) => {
                    commit('SET_INITIALIZATION', true);
                    resolve(hasClientSwitch);
                });
            }).catch((error) => {
                commit('SET_LOGIN_ERROR', error);
                reject(error);
            });
        });
    },
    async logout () {
        const optionalRedirectUrl = await sessionApi.getLogoutUrl();
        return new Promise((resolve, reject) => {
            userApi.logout()
                .then(() => {
                    this.dispatch('resetAll').then(() => {
                        if (optionalRedirectUrl) {
                            window.location.href = optionalRedirectUrl;
                        } else {
                            resolve();
                        }
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    forcedLogout () {
        this.dispatch('resetAll').then(async () => {
            const redirectUrl = await sessionApi.getLogoutUrl();

            if (redirectUrl) {
                window.location.href = redirectUrl;
            }
            const ev = new Event('forceLogin');
            window.dispatchEvent(ev);
        });
    },
    recoverPassword ({ commit, state }, data) {
        commit('SET_PASSWORD_RECOVER_ERROR', null);
        return new Promise((resolve, reject) => {
            userApi.recoverPassword(data.username, state.shopId)
                .then(() => {
                    commit('SET_PASSWORD_CHANGE_REQUIRED', true);

                    resolve();
                })
                .catch((error) => {
                    commit('SET_PASSWORD_RECOVER_ERROR', error.message);

                    reject(error);
                });
        });
    },
    changePassword ({ commit }, data) {
        return new Promise((resolve, reject) => {
            userApi.changePassword(data)
                .then(() => {
                    commit('SET_PASSWORD_CHANGE_REQUIRED', false);
                    resolve();
                })
                .catch((error) => {
                    commit('SET_PASSWORD_CHANGE_REQUIRED', true);
                    reject(error);
                });
        });
    },
    refreshState ({ commit, state }) {
        this.dispatch('clearAll');

        return new Promise((resolve, reject) => {
            // load pre-auth data
            sessionApi.initPortal()
                .then((data) => {
                    commit('SET_APP_LANGUAGE', data.agencyLanguage);
                    commit('SET_SUBDOMAIN', data.subdomain);
                    commit('SET_SHOP_ID', +data.shopId);
                    commit('SET_AUTH_CONFIGS', data.authConfigs);

                    this.dispatch('shop/setLoginData', {
                        fragment: data.loginFragment,
                        logo: data.loginLogo
                    });
                    this.dispatch('shop/setHeadlineLinks', {
                        links: data.headlineLinks
                    });

                    if (data.message) {
                        commit('SET_LOGIN_ERROR', {
                            code: data.message.code,
                            data: {class: data.message.class},
                            message: data.message.class
                        });
                    }

                    // load post-auth data
                    sessionApi.init()
                        .then(async (data) => {
                            this.dispatch('session/updatePortalCustomization', data.shopData);

                            commit('SET_LOGIN_ERROR', null);
                            commit('SET_SECRET', data.secret);
                            commit('SET_APP_LANGUAGE', data.user.preferredLocale);
                            commit('SET_REDIRECTS', data.redirects);
                            commit('SET_OPTIN_PAGE', data.shopData.showOptinPage);
                            commit('SET_LANGUAGES', data.languages);
                            commit('SET_COUNTRY_CODE', data.countryCode);
                            commit('SET_AUTH_ID', data.user.auth.authId);

                            // only show passwordChange if firstLogin (users.changePassword)
                            // AND authentication method was 'ndx'
                            commit(
                                'SET_PASSWORD_CHANGE_REQUIRED',
                                data.user.firstLogin && data.user.auth.authId === 'ndx'
                            );

                            commit('SET_WEBSOCKET_DATA', data.websockets);

                            this.dispatch('shop/setShopData', data.shopData);
                            this.dispatch('basket/setBasketItemCount', data.shopData?.basket?.basketItemCount || 0);
                            this.dispatch('basket/setWatchlistItemCount', data.shopData?.basket?.watchItemCount || 0);
                            this.dispatch(
                                'basket/setAllData', {
                                    basket: {
                                        basketItemCount: data.shopData?.basket?.basketItemCount ?? 0,
                                        groups: [{items: data.shopData?.basket?.basketItems || []}],
                                        bundleTrees: data.shopData?.basket?.bundleTrees || []
                                    },
                                    watchlist: data.shopData?.basket?.watchItems || []
                                }
                            );
                            this.dispatch('news/setUnreadCount', data.shopData?.unreadNewsCount || 0);
                            this.dispatch('news/setNewsSettings', data.user.newsSettings);
                            this.dispatch('search/setSearchables', data.shopData.searchables);
                            this.dispatch('user/setHasClientSwitch', data.user.hasClientSwitch);
                            this.dispatch('user/setAdminUrl', data.user.adminUrl);

                            await Promise.all([
                                this.dispatch('user/getUserAndClient'),
                                this.dispatch('shop/loadMenu')
                            ]);

                            this.dispatch('workflowTask/getOpenTaskCountForUser');

                            resolve(data.user.hasClientSwitch);
                        })
                        .catch((error) => {
                            // set values in error case to avoid flickering during login process
                            this.dispatch('session/updatePortalCustomization', data);

                            const err = state.loginError;
                            this.dispatch('resetAll').then(() => commit('SET_LOGIN_ERROR', err));

                            reject(error);
                        }).finally(() => {
                            commit('SET_INITIALIZATION', true);
                            commit('SET_MAINTENANCE', data.maintenance);
                        });
                })
                .catch((error) => {
                    this.dispatch('resetAll');

                    reject(error);
                }).finally(() => {
                    commit('SET_INITIALIZATION', true);
                });
        });
    },
    switchLanguage ({ commit }, language) {
        commit('SET_APP_LANGUAGE', language);
    },

    incrementRequestCounter ({ commit }, throbberRule) {
        commit('CHANGE_REQUESTCOUNTER', { rule: throbberRule, diff: 1 });
    },
    decrementRequestCounter ({ commit }, throbberRule) {
        commit('CHANGE_REQUESTCOUNTER', { rule: throbberRule, diff: -1 });
    },
    refreshSession () {
        window.clearTimeout(refreshTimer);
        sessionApi.checkVersion()
            .then(res => {
                const serverVersion = res;
                const browserVersion = document.head.querySelector('meta[name="LM Version"]').content;
                if (serverVersion != browserVersion) {
                    const ev = new CustomEvent('NEW_APP_VERSION_AVAILABLE', {
                        detail: { serverVersion, browserVersion }
                    });
                    window.dispatchEvent(ev);
                }
            })
            .finally(() => {
                refreshTimer = window.setTimeout(actions.refreshSession, refreshInterval);
            });
    },

    reloadPortalCustomization({dispatch}) {
        return new Promise((resolve, reject) => {
            shopApi.getPortalCustomizationData(throbberRules.FULLSCREEN).then(
                (data) => {
                    dispatch('updatePortalCustomization', data);
                    resolve();
                }
            ).catch((e) => {
                console.log(e);
                reject(e);
            });
        });
    },

    updatePortalCustomization({dispatch}, data) {
        const guessUrlOrSorage = (str, width, height) => {
            if (typeof str === 'string' && str.indexOf('storage:') === 0) {
                return `/ndx/image/resize/${width}/${height}/` + str;
            }
            return str;
        };
        if ('brandColorFallback' in data) {
            setBrandColor(data.brandColor, data.brandColorFallback);
        } else {
            setBrandColor(data.brandColor);
        }
        setWindowTitle(data.windowTitle);
        setFavicon(guessUrlOrSorage(data.favicon, 100, 100));
        setHomescreenIcon(guessUrlOrSorage(data.homescreenIcon, 100, 100));

        if ('logo' in data) {
            dispatch('shop/setShopLogo', guessUrlOrSorage(data.logo, 300, 45), {root: true});
        }
    },

    setMaintenance ({ commit }, isMaintenanceActive) {
        commit('SET_MAINTENANCE', isMaintenanceActive);
    },

    clearLoginError ({ commit }) {
        commit('SET_LOGIN_ERROR', null);
    },
    clearPasswordRecoverError ({ commit }) {
        commit('SET_PASSWORD_RECOVER_ERROR', null);
    },

    addOverlay ({commit}) {
        commit('INCREASE_OVERLAY_COUNTER');
    },
    removeOverlay ({commit}) {
        commit('DECREASE_OVERLAY_COUNTER');
    }
};

// mutations
const mutations = {
    RESET: (state) => {
        state.maintenance = false;
        state.loginError = null;
        state.passwordRecoverError = null;
        state.passwordChangeRequired = false;
        state.secret = null;
        state.initialized = false;
        state.subdomain = null;
        state.requestCounter = {};
        state.websocketData = null;
        state.redirects = null;
        state.showOptinPage = false;
        state.activeLanguage = null;
        state.languages = [];
        state.countryCode = null;
        state.authId = null;
        state.overlayCounter = 0;
    },
    SET_LOGIN_ERROR: (state, newLoginError) => {
        state.loginError = newLoginError;
    },
    SET_PASSWORD_RECOVER_ERROR: (state, newError) => {
        state.passwordRecoverError = newError;
    },
    SET_PASSWORD_CHANGE_REQUIRED: (state, passwordChangeRequired) => {
        state.passwordChangeRequired = passwordChangeRequired;
    },
    SET_SECRET: (state, newSecret) => {
        state.secret = newSecret;
    },
    SET_INITIALIZATION: (state, appState) => {
        state.initialized = appState;
        actions.refreshSession();
    },
    SET_APP_LANGUAGE: (state, language) => {
        // language code may have two or three letters
        if (language && language.length > 3) {
            language = language.split('_')[0];
        }
        state.activeLanguage = language;
        loadLanguageAsync(language);
    },
    SET_SUBDOMAIN: (state, subdomain) => {
        state.subdomain = subdomain;
    },
    CHANGE_REQUESTCOUNTER: (state, data) => {
        if (!(data.rule in state.requestCounter)) {
            state.requestCounter[data.rule] = 0;
        }

        state.requestCounter[data.rule] += data.diff;

        state.requestCounter = { ...state.requestCounter };
    },
    SET_MAINTENANCE: (state, isMaintenanceActive) => {
        state.maintenance = isMaintenanceActive;
    },
    SET_WEBSOCKET_DATA: (state, data) => {
        state.websocketData = data;
    },
    SET_SHOP_ID: (state, shopId) => {
        state.shopId = shopId;
    },
    SET_AUTH_CONFIGS: (state, authConfigs) => {
        state.authConfigs = authConfigs;
    },
    SET_REDIRECTS: (state, redirects) => {
        state.redirects = redirects;
    },
    SET_OPTIN_PAGE: (state, isUsed) => {
        state.showOptinPage = isUsed;
    },
    SET_LANGUAGES: (state, languages) => {
        state.languages = languages;
    },
    SET_COUNTRY_CODE: (state, countryCode) => {
        state.countryCode = countryCode;
    },
    SET_AUTH_ID: (state, authId) => {
        state.authId = authId;
    },
    INCREASE_OVERLAY_COUNTER: (state) => {
        state.overlayCounter = state.overlayCounter + 1;
        setBodyOverlay(state.overlayCounter);
    },
    DECREASE_OVERLAY_COUNTER: (state) => {
        if (state.overlayCounter < 2) {
            state.overlayCounter = 0;
        } else {
            state.overlayCounter = state.overlayCounter - 1;
        }
        setBodyOverlay(state.overlayCounter);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
