<template>
    <div
        v-window-resize="testForAspectRatio"
        class="card ndx-category-card"
        :class="{['variant-' + variant]: true, 'active': active}"
        @click="$emit('card-click')"
    >
        <div class="image-wrapper d-flex align-items-center justify-content-center flex-grow-0 flex-shrink-0">
            <div
                v-if="cardIcon && cardIcon.length > 0"
                class="icon-img-top img-circle d-flex align-items-center justify-content-center"
            >
                <NdxIcon :icon="cardIcon" size="xl"/>
            </div>
            <NdxSvg
                v-else-if="isSvgCardImg"
                :src="cardImg.filename"
                class="card-img-top text-center img-circle"
                :style="customStyle"
            />
            <NdxImage
                v-else
                :src="cardImg && cardImg.filename ? cardImg.filename : ''"
                alt=""
                class="card-img-top text-center img-circle"
                size="l"
                :style="customStyle"
            />
        </div>
        <div class="card-body">
            <div
                class="card-title"
                :class="{'mb-0': variant === 'card' || (['list', 'list-narrow'].includes(variant) && !description)}"
            >
                {{ title }}
            </div>
            <div
                v-if="['list', 'list-narrow'].includes(variant) && description && description.length > 0"
                class="card-text"
                v-html="stripHtmlTags(description, ['br'])"
            ></div>
        </div>
    </div>
</template>

<script>
    import NdxImage from "./NdxImage";
    import { stripHtmlTags } from "@utilities/ndxText";
    import NdxIcon from "./NdxIcon";
    import NdxSvg from "./NdxSvg";

    export default {
        name: "NdxCategoryCard",
        components: {NdxSvg, NdxIcon, NdxImage},
        emits: ['card-click'],
        props: {
            cardImg: {
                type: Object
            },
            cardIcon: {
                type: String
            },
            title: {
                type: String
            },
            description: {
                type: String
            },
            foregroundColor: {
                type: String
            },
            backgroundColor: {
                type: String
            },
            /**
             * @values card, list, list-narrow
             */
            variant: {
                type: String,
                default: 'card',
                validator: function (value) {
                    return ['card', 'list', 'list-narrow'].includes(value);
                }
            },
            active: {
                type: Boolean
            }
        },
        data() {
            return {
                customStyle: ''
            };
        },
        computed: {
            isSvgCardImg() {
                let filename = null;
                if (this.cardImg && this.cardImg.filename && this.cardImg.filename.length) {
                    filename = this.cardImg.filename;
                }
                return !!filename?.endsWith('.svg');
            }
        },
        watch: {
            variant() {
                this.testForAspectRatio();
            }
        },
        mounted() {
            const _foregroundColor = this.foregroundColor ? this.foregroundColor : '#FFFFFF';
            const _backgroundColor = this.backgroundColor ? this.backgroundColor : 'var(--bs-primary)';

            this.customStyle = 'fill: ' + _foregroundColor + '; background-color: ' + _backgroundColor + ';';

            this.testForAspectRatio();
        },
        methods: {
            stripHtmlTags,
            testForAspectRatio() {
                if (this.variant === 'card' && !('aspectRatio' in this.$el.style)) {
                    this.$nextTick(
                        () => this.$el.querySelector('.image-wrapper').style.height = this.$el.clientWidth + 'px'
                    );
                } else if (['list', 'list-narrow'].includes(this.variant) && !('aspectRatio' in this.$el.style)) {
                    this.$el.querySelector('.image-wrapper').style.height = 'auto';
                }
            }
        }
    };
</script>
